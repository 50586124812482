import React from 'react';
import styled from '@emotion/styled';
import CheckIcon from '../../assets/images/check-icon.svg';
import CloseIcon from '../../assets/images/close-modal-icon.svg';
import SvgIcon from '../../assets/styledComponents/SvgIcon';
import theme from '../../assets/styles/theme';

const NotificationModal = ({
    openNotification,
    setOpenNotification,
    notificationType,
    isMobile,
}) => {
    const renderMessage = () => {
        if (notificationType === 'avatar') {
            return (
                <p className="header_cabin_sm">
                    New profile photo successfully uploaded.
                </p>
            );
        }
        return (
            <p className="header_cabin_sm">
                New username successfully updated.
            </p>
        );
    };
    const handleCloseModal = (event) => {
        event.preventDefault();
        setOpenNotification(!openNotification);
    };
    return (
        <ModalContent isMobile={isMobile}>
            <CloseButton onClick={handleCloseModal}>
                <SvgIcon
                    icon={CloseIcon}
                    bgColor={theme.colors.white}
                    width={`1.4rem`}
                    height={`1.4rem`}
                    alt="close modal"
                />
            </CloseButton>
            <SvgIcon
                icon={CheckIcon}
                width={`2.4rem`}
                height={`2.4rem`}
                marginRight={`0.8rem`}
                bgColor={theme.colors.white}
                alt="check mark"
            />
            {renderMessage()}
        </ModalContent>
    );
};

const CloseButton = styled.button`
    padding: 0 !important;
    margin: 0;
    border: none;
    background: none;
    position: absolute;
    cursor: pointer;
    right: 1.6rem;
`;

const ModalContent = styled.div`
    width: ${({ isMobile }) => (isMobile ? `80vw` : `32vw`)};
    height: ${({ isMobile }) => (isMobile ? `fit-content` : `5.6rem`)};
    padding: 1.6rem;
    color: ${(props) => props.theme.colors.white};
    background: rgba(0, 0, 0, 0.7);
    z-index: ${(props) => props.theme.zIndex.modal};
    display: flex;
    align-items: center;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: ${(props) => props.theme.spaces.navbarHeight};

    button {
        padding: 1rem;
    }
`;

export default NotificationModal;
