const theme = {
    colors: {
        primary: '#1E3A71',
        secondary: '#4574D0',
        tertiary: '#FDD264',
        tertiary_dark: '#FCBE22',
        extra: '#DB334F',
        extra_dark: '#BF223C',
        white: '#FFFFFF',
        light_grey: '#F0F3F7',
        grey: '#CCD6DF',
        dark_grey: '#8897A5',
        darker_grey: `#4F555B`,
        black: '#041321',
    },
    spaces: {
        landingNavbarHeight: '11.2rem',
        navbarHeight: `9.6rem`,
        marginBottom: '3rem',
    },
    zIndex: {
        advancedSearch: `10`,
        advancedFiltersDropdown: `20`,
        navbar: `60`,
        userSpecificDropdown: `30`,
        modalOverlay: `40`,
        resourceBlade: `50`,
        modal: `60`,
        secondLayerModalOverlay: `70`,
        secondLayerModal: `80`,
    },
    letterSpacing: {
        xs: `0.1rem`,
        sm: `0.2rem`,
        md: `0.3rem`,
    },
    breakPoints: {
        default: `144rem`,
        minimum: `105.6rem`,
        maximum: `256rem`,
        mobile_max: `76.7rem`,
    },
};

export default theme;
