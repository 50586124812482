import styled from '@emotion/styled';
import Header_Md from 'assets/styledComponents/Typography/Header_Md';
import React from 'react';
import Section from 'assets/styledComponents/Section';
import SquareButton from 'assets/styledComponents/SquareButton';
import categoryImage from 'assets/images/landing-category-image.png';
import assessmentIcon from 'assets/images/assessment-icon.svg';
import choicesIcon from 'assets/images/choices-icon.svg';
import getInvolvedIcon from 'assets/images/get-involved-icon.svg';
import servicesIcon from 'assets/images/services-icon.svg';
import toolsIcon from 'assets/images/tools-icon.svg';
import arrowIcon from 'assets/images/topic-arrow-icon.svg';
import { useGlobalContext } from 'globalStore/globalContext';
import { useHistory } from 'react-router-dom';

const BrowseByTopics = () => {
    const { globalState } = useGlobalContext();
    const history = useHistory();

    const handleClick = (category) => {
        history.push({
            pathname: '/search',
            state: {
                hierarchicalCategory: category,
            },
        });
    };

    return (
        <Container>
            <CategoryContainer>
                <Header_Md>Browse by Topics</Header_Md>
                <TopicButton
                    onClick={() =>
                        handleClick(
                            'Services, Supports, and Community Resources'
                        )
                    }
                >
                    <div>
                        <img src={servicesIcon} alt="services" />
                        <p>Services, Supports, and Community Resources</p>
                    </div>
                    <img src={arrowIcon} alt="arrow" />
                </TopicButton>
                <TopicButton
                    onClick={() =>
                        handleClick(
                            'Tools to Find, Evaluate and Use Health Information'
                        )
                    }
                >
                    <div>
                        <img src={toolsIcon} alt="services" />
                        <p>Tools to Find, Evaluate and Use Evidence</p>
                    </div>
                    <img src={arrowIcon} alt="arrow" />
                </TopicButton>
                <TopicButton onClick={() => handleClick('Assessment')}>
                    <div>
                        <img src={assessmentIcon} alt="services" />
                        <p>Assessment</p>
                    </div>
                    <img src={arrowIcon} alt="arrow" />
                </TopicButton>
                <TopicButton
                    onClick={() => handleClick('Making Treatment Choices')}
                >
                    <div>
                        <img src={choicesIcon} alt="services" />
                        <p>Making Treatment Choices</p>
                    </div>
                    <img src={arrowIcon} alt="arrow" />
                </TopicButton>
                <TopicButton onClick={() => handleClick('Get Involved')}>
                    <div>
                        <img src={getInvolvedIcon} alt="services" />
                        <p>Get Involved</p>
                    </div>
                    <img src={arrowIcon} alt="arrow" />
                </TopicButton>
            </CategoryContainer>
            {!globalState.isMobile && (
                <ImageContainer id="image-container">
                    <BorderDiv />
                    <ImageDiv></ImageDiv>
                </ImageContainer>
            )}
        </Container>
    );
};

const TopicButton = styled(SquareButton)`
    display: flex;
    height: 6.4rem;
    padding: 1.6rem 2.4rem 1.6rem 3.2rem;
    margin: 0 4.8rem 1.6rem 0;
    &:last-of-type {
        margin-bottom: 0;
    }
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.white};
    border: none;
    div {
        display: flex;
    }
    p {
        margin-left: 4.8rem;
        margin-right: 1.6rem;
    }
    &:hover {
        color: ${(props) => props.theme.colors.black};
        box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.2);
    }
    @media only screen and (max-width: 1439px) {
        padding: 1.6rem;
        p {
            margin-left: 1.6rem;
        }
    }

    @media only screen and (max-width: 767px) {
        margin: 0 0 1.6rem 0;
        p {
            font-size: 1.6rem;
            white-space: normal;
            text-align: left;
        }
    }
`;

const ImageDiv = styled.div`
    background-image: url(${categoryImage});
    background-size: cover;
    box-sizing: border-box;
    border-radius: 75px 5px;
    height: 100%;
    width: 100%;
    position: absolute;
`;

const BorderDiv = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    border: 2px solid rgba(30, 58, 113, 0.5);
    box-sizing: border-box;
    border-radius: 75px 5px;
    top: 1.5rem;
    left: 1.5rem;
`;

const ImageContainer = styled.div`
    height: 44rem;
    width: 56rem;
    position: relative;
    flex: 1;
    max-width: 60rem;
`;

const Container = styled(Section)`
    padding: 7.2rem 12rem 8.8rem;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.light_grey};
    color: ${(props) => props.theme.colors.primary};
    margin: 0;
    @media only screen and (max-width: 1439px) {
        padding: 7.2rem 6.4rem 8rem;
    }
    @media only screen and (max-width: 767px) {
        flex-direction: column;
        padding: 4.8rem 2.4rem 4.8rem 2.4rem;
    }
    justify-content: center;
`;

const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 60rem;
    h2 {
        margin-bottom: 4rem;
    }
`;

export default BrowseByTopics;
