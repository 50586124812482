import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGlobalContext } from 'globalStore/globalContext';
import getCheckState from 'functions/getCheckState';
import IconButton from 'assets/styledComponents/IconButton';
import Add from 'assets/images/add-from-card-icon.svg';
import BlueAdd from 'assets/images/blue-add-to-board-icon.svg';
import Delete from 'assets/images/card-delete-icon.svg';
import CDR from 'assets/images/CDR_placeholder.svg';
import Header_Cabin_Sm from 'assets/styledComponents/Typography/Header_Cabin_Sm';
import resourcesSet from 'functions/resourcesSet';
import { createURL } from 'functions/algoliaSearchToUrl';

const Hit = ({
    resource,
    hit,
    handleClick,
    setCurrentResource,
    setOpenAddToBoardModal,
    setCheckBoxState,
    boards,
    resourceSet,
    setOpenDeleteModal,
    searchState,
}) => {
    const history = useHistory();
    const location = useLocation();
    const path = location.pathname;
    const id = hit ? hit.id.toString() : resource.id;
    const title = hit ? hit.title : resource.title;
    const slug = hit ? hit.slug : resource.slug;
    const image = hit
        ? hit.thumbnail
        : resource.image
        ? resource.image.url
            ? resource.image.url
            : resource.image
        : null;
    const author = hit ? hit.author : resource.author;
    const description = hit ? hit.description : resource.description;
    const type = hit
        ? hit['Resource Type']
        : resource.resource_type
        ? resource.resource_type
        : resource.type;
    const updatedAt = hit
        ? hit.date_of_last_update
        : resource.date_of_last_update;
    const view = hit
        ? hit['User Specific']
        : resource.user_specifics?.map((user) => user.user);
    const [isResourceAdded, setIsResourceAdded] = useState(false);
    const { globalState } = useGlobalContext();

    const handleDelete = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setCheckBoxState(getCheckState(id, boards));
        setCurrentResource({
            id: id.toString(),
            title,
            author,
            description,
            image,
            slug,
            updatedAt,
            type,
            view,
        });
        setOpenDeleteModal(true);
    };

    useEffect(() => {
        if (boards) {
            if (path === '/search') {
                setIsResourceAdded(resourceSet.has(Number(id)));
            } else {
                setIsResourceAdded(resourcesSet(boards).has(Number(id)));
            }
        }
        //eslint-disable-next-line
    }, [boards]);

    const handleAddToBoard = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (globalState.registeredUserId) {
            setOpenAddToBoardModal(true);
            setCheckBoxState(getCheckState(id, boards));
            setCurrentResource({
                id: id.toString(),
                title,
                author,
                description,
                image,
                slug,
                updatedAt,
                type,
                view,
            });
        } else {
            history.push({
                search: searchState ? createURL(searchState) : '',
                state: { requireLogin: true },
            });

            const isMobile = globalState.isMobile;

            if (isMobile) {
                await document.getElementById('mobile-navbar').click();
                document.getElementById('login-button').click();
            } else {
                // redirect to sign in modal if user's not logged in
                document.getElementById('login-button').click();
            }
        }
    };

    return (
        <ListContainer onClick={(event) => handleClick(event, slug)}>
            <ImageWrapper hasImage={image}>
                <Image hasImage={image} src={image ?? CDR} alt={title} />
            </ImageWrapper>

            <CardContent>
                <ContentGridLayout>
                    <div>
                        <h3 className="header_xs">{title}</h3>
                        <ByLineHeader>{author}</ByLineHeader>
                        <Description
                            className="body_cabin_sm"
                            hasImage={image}
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </div>
                    <div />
                </ContentGridLayout>

                <Footer>
                    <Type className="header_cabin_sm">{type}</Type>
                    <ButtonsWrap>
                        <AddButton
                            icon={isResourceAdded ? BlueAdd : Add}
                            onClick={handleAddToBoard}
                        />
                        {path.includes('myboard') && (
                            <Button icon={Delete} onClick={handleDelete} />
                        )}
                    </ButtonsWrap>
                </Footer>
            </CardContent>
        </ListContainer>
    );
};

const ListContainer = styled.div`
    width: 100%;
    min-height: 17rem;
    max-height: 37rem;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    cursor: pointer;
    border-radius: 1.5rem;
    margin-bottom: 3.2rem;

    &:hover {
        box-shadow: 0 0.2rem 1.5rem 0 rgba(78, 78, 78, 0.2);
    }
`;

const ByLineHeader = styled(Header_Cabin_Sm)`
    letter-spacing: 0.03rem;
    color: ${(props) => props.theme.colors.secondary};
    margin-bottom: 0.8rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const ImageWrapper = styled.div`
    border-radius: 1.5rem 0 0 1.5rem;
    width: 21.67vw;
    max-height: 19.2rem;
    background: ${(props) =>
        props.hasImage ? 'unset' : props.theme.colors.primary};
`;

const Image = styled.img`
    border-radius: 1.5rem 0 0 1.5rem;
    width: 21.67vw;
    height: 100%;
    object-fit: contain;
`;

const CardContent = styled.div`
    padding: 1.6rem 0 1.6rem 2.4rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    & h3 {
        display: -webkit-box;
        -webkit-line-clamp: 1; /* max 1 lines */
        text-overflow: ellipsis;
        text-align: left;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 0.8rem;
    }
`;

const ContentGridLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 17.6rem;
`;

const Type = styled.div`
    color: ${(props) => props.theme.colors.secondary};
`;

const Description = styled.div`
    color: ${(props) => props.theme.colors.darker_grey};
    margin-bottom: 2.4rem;
    all: unset;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-bottom: 1.6rem;
`;

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    overflow: hidden;
`;

const ButtonsWrap = styled.div`
    display: flex;
    align-items: center;
    padding-right: 2.4rem;
`;

const Button = styled(IconButton)`
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
    margin: 0 0 0 1rem;
    svg {
        width: 100%;
        height: 100%;
    }
    &:hover {
        transition: background-image 0.2s ease-in-out;
        transform: scale(1.1);
    }
`;

const AddButton = styled(Button)`
    &:hover {
        background-image: url(${BlueAdd});
    }
`;
export default Hit;
