import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { CurrentRefinements, InstantSearch } from 'react-instantsearch-dom';
import {
    SEARCH_CLIENT,
    INDEX_NAME,
    DEFAULT_REFINEMENT,
    HIERARCHICAL_ATTRIBUTES,
    HIERARCHICAL_LVLALL,
    HIERARCHICAL_LVL1,
    HIERARCHICAL_LVL2,
    HIERARCHICAL_LVL3,
    HIERARCHICAL_LVL4,
} from '../algoliaConfigs';
import { UPDATE_BOARDS, UPDATE_SAVED_SEARCHES } from '../graphql/mutation';
import { useGlobalContext } from '../globalStore/globalContext';
import { updateSavedSearches, updateBoards } from 'globalStore/globalReducer';
import getCheckState from '../functions/getCheckState';
import getBoardsFromCheckbox from '../functions/getBoardsFromCheckbox';
import {
    capitalizeEachWord,
    deleteExtraSpaces,
} from '../functions/formatString';
import resourcesSet from '../functions/resourcesSet';
import {
    DEBOUNCE_TIME,
    createURL,
    searchStateToUrl,
    urlToSearchState,
} from 'functions/algoliaSearchToUrl';
import CustomHierarchicalMenu from 'components/SearchPage/CustomHierarchicalMenu';
import SearchBarSection from 'components/SearchPage/SearchBarSection';
import SearchResultsInfo from 'components/SearchPage/SearchResultsInfo';
import NameSavedSearchModal from 'components/SearchPage/NameSavedSearchModal';
import ResourceBlade from 'components/ResourceBlade';
import InfiniteScrollSearchResult from 'components/SearchPage/SearchResult/InfiniteScrollSearchResult';
import CustomBreadcrumb from 'components/SearchPage/CustomBreadcrumb';
import AddToBoardModal from 'components/BoardModals/AddToBoardModal';
import CreateBoardModal from 'components/BoardModals/CreateBoardModal';
import PageContainer from '../assets/styledComponents/PageContainer';
import CustomClearRefinements from '../components/SearchPage/CustomClearRefinements';
import formatCategoryLabel from 'functions/formatCategoryLabel';

const AlgoliaSearch = () => {
    const location = useLocation();
    const history = useHistory();
    const timer = useRef();
    const { globalState, dispatch } = useGlobalContext();
    const debouncedSetStateRef = useRef(null);
    const [searchState, setSearchState] = useState(urlToSearchState(location));
    const [showDropdown, setShowDropdown] = useState(false);
    const [cardView, setCardView] = useState(true);
    const [savedSearches, setSavedSearches] = useState({});
    const [savedSearchTitle, setSavedSearchTitle] = useState('');
    const [openNameSavedSearchModal, setOpenNameSavedSearchModal] =
        useState(false);
    const [openResourceBlade, setOpenResourceBlade] = useState(false);
    const [resourceSlug, setResourceSlug] = useState(null);
    const [slugFromURL, setSlugFromURL] = useState(null);
    const [isHoverOnModal, setIsHoverOnModal] = useState(false);
    // For Adding and Creating boards
    const { registeredUserId, boards: oldBoards, isMobile } = globalState;
    const [boards, setBoards] = useState(oldBoards ?? {});
    const [boardName, setBoardName] = useState(null);
    const [openAddToBoardModal, setOpenAddToBoardModal] = useState(false);
    const [openCreateBoardModal, setOpenCreateBoardModal] = useState(false);
    const [checkBoxState, setCheckBoxState] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [currentResource, setCurrentResource] = useState(null);
    const [resourceSet, setResourceSet] = useState(resourcesSet(oldBoards));
    const [currentHierarchicalCategory, setCurrentHierarchicalCategory] =
        useState('');
    let categories = document.querySelectorAll('button.category-item');

    useEffect(() => {
        if (searchState == null || searchState.hierarchicalMenu == null) return;

        const hierarchicalCategoriesArray =
            searchState.hierarchicalMenu['hierarchicalCategories.lvlAll'].split(
                ' > '
            );

        if (hierarchicalCategoriesArray.length > 1) {
            setCurrentHierarchicalCategory(
                hierarchicalCategoriesArray[
                    hierarchicalCategoriesArray.length - 1
                ]
            );
        }
    }, [searchState]);

    const [addBoard] = useMutation(UPDATE_BOARDS, {
        onCompleted: () => {
            setBoardName(null);
        },
        onError: (error) => console.error('Create board error: ', error),
    });
    const [addResource] = useMutation(UPDATE_BOARDS);
    useEffect(() => {
        if (globalState.boards) {
            setBoards(globalState.boards);
            setResourceSet(resourcesSet(globalState.boards));
        }
        if (globalState.savedSearches) {
            setSavedSearches(globalState.savedSearches);
        }
        // eslint-disable-next-line
    }, [globalState]);

    useEffect(() => {
        if (currentResource) {
            const id = currentResource.id;
            const checkState = getCheckState(id, boards);
            setCheckBoxState(checkState);
        }
        // eslint-disable-next-line
    }, [currentResource]);

    useEffect(() => {
        if (searchState.resource && !openResourceBlade && !resourceSlug) {
            setSlugFromURL(searchState.resource);
            setOpenResourceBlade(true);
        }
        return () => {
            setSlugFromURL(null);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (location.state) {
            if (location.state.query) {
                onSearchStateChange({
                    query: history.location.state.query,
                    menu: {
                        'User Specific': history.location.state.currentView,
                    },
                });
            } else if (location.state.hierarchicalCategory) {
                onSearchStateChange({
                    hierarchicalMenu: {
                        'hierarchicalCategories.lvlAll': `Home > ${history.location.state.hierarchicalCategory}`,
                    },
                });
            }
        }
        return () => {
            location.state = null;
        };
        //eslint-disable-next-line
    }, []);

    // Prevent background content scrolling when hover on blade modal
    useEffect(() => {
        if (openResourceBlade && isHoverOnModal) {
            document.body.style.overflow = 'hidden';
        } else if (!isHoverOnModal || !openResourceBlade) {
            document.body.style.overflow = 'unset';
        }
        // eslint-disable-next-line
    }, [openResourceBlade, isHoverOnModal]);

    // show filters as search query in the url
    const onSearchStateChange = (updatedSearchState) => {
        clearTimeout(debouncedSetStateRef.current);
        /*
         history.push(path, state)
         this shows algolia query string as URL in the browser
         */
        debouncedSetStateRef.current = setTimeout(() => {
            history.replace(searchStateToUrl(location, updatedSearchState));
        }, DEBOUNCE_TIME);

        setSearchState(updatedSearchState);
    };

    useEffect(() => {
        const updatedSearchState = urlToSearchState(location);
        // show search param when user update filters
        if (
            JSON.stringify(searchState) !== JSON.stringify(updatedSearchState)
        ) {
            setSearchState(updatedSearchState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const [updateSavedSearch] = useMutation(UPDATE_SAVED_SEARCHES, {
        onCompleted: () => {
            setSavedSearchTitle('');
            setErrorMessage(null);
        },
        onError: (error) =>
            console.error('Update user saved search error: ', error),
    });

    const openDropdown = () => setShowDropdown(!showDropdown);

    const handleSavedSearchTitleChange = (event) => {
        if (errorMessage) {
            setErrorMessage(null);
        }
        setSavedSearchTitle(event.target.value);
    };

    const submitSavedSearch = (event) => {
        event.preventDefault();

        const trimmedTitle = deleteExtraSpaces(savedSearchTitle);

        if (!trimmedTitle) {
            return setErrorMessage('Please enter a valid name...');
        }
        const formattedTitle = capitalizeEachWord(trimmedTitle);

        if (savedSearches[formattedTitle]) {
            return setErrorMessage('This name is already existed...');
        }

        setOpenNameSavedSearchModal(false);
        updateSavedSearch({
            variables: {
                registeredUserId: registeredUserId,
                savedSearches: {
                    ...savedSearches,
                    [formattedTitle]: searchState.refinementList,
                },
            },
        });
        dispatch({
            type: updateSavedSearches,
            payload: {
                ...savedSearches,
                [formattedTitle]: searchState.refinementList,
            },
        });
    };

    const handleOpenResourceBlade = (event, slug) => {
        event.preventDefault();

        if (slugFromURL) {
            setSlugFromURL(null);
        }
        setResourceSlug(slug);

        const currentPath = history.location.pathname;
        const currentSearchParam = history.location.search;

        // open recommended resource blade in the current blade
        if (openResourceBlade) {
            // delete current &resource=${slug} string and replace with the new resource slug
            const params = new URLSearchParams(currentSearchParam);
            params.delete('resource');
            const newParam = params.toString();

            return history.push({
                pathname: `${currentPath}`,
                search: `${newParam}&resource=${slug}`,
                state: {
                    // passing name as location state through react router
                    name: boardName,
                },
            });
        }
        // open resource blade from resource card
        if (!openResourceBlade) {
            setOpenResourceBlade(true);
            return history.push({
                pathname: `${currentPath}`,
                search: `${currentSearchParam}&resource=${slug}`,
                state: {
                    // passing name as location state through react router
                    name: boardName,
                },
            });
        }
    };

    const handleBoardNameChange = (event) => {
        setBoardName(event.target.value);
        if (errorMessage) {
            setErrorMessage(null);
        }
    };

    const closeAddToBoardModal = () => {
        setOpenAddToBoardModal((prev) => !prev);
        setCheckBoxState(null);
        setCurrentResource(null);
    };

    const createBoard = (event) => {
        event.preventDefault();
        if (!boardName) {
            return setErrorMessage('Please enter a valid name...');
        }
        const formattedBoardName = capitalizeEachWord(boardName);
        if (boards && boards[formattedBoardName]) {
            return setErrorMessage('Board name already existed...');
        }
        const newBoards = {
            ...boards,
            [formattedBoardName]: [],
        };

        const checkState = getCheckState(currentResource.id, newBoards);
        setCheckBoxState(checkState);

        addBoard({
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: registeredUserId,
                        boards: newBoards,
                        __typename: 'RegisteredUser',
                    },
                    __typename: 'updateRegisteredUserPayload',
                },
            },
            variables: {
                registeredUserId: registeredUserId,
                boards: newBoards,
            },
        });

        setBoards(newBoards);
        setOpenCreateBoardModal(false);
        setOpenAddToBoardModal((prev) => !prev);
        dispatch({
            type: updateBoards,
            payload: newBoards,
        });
    };

    const addResourcesToBoards = (event) => {
        event.preventDefault();
        setOpenAddToBoardModal(false);
        const newBoards = getBoardsFromCheckbox(
            checkBoxState,
            boards,
            currentResource.id,
            currentResource
        );
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            addResource({
                optimisticResponse: {
                    updateRegisteredUser: {
                        registeredUser: {
                            id: registeredUserId,
                            boards: newBoards,
                            __typename: 'RegisteredUser',
                        },
                        __typename: 'updateRegisteredUserPayload',
                    },
                },
                variables: {
                    registeredUserId: registeredUserId,
                    boards: newBoards,
                },
            });
        }, 500);
        dispatch({
            type: updateBoards,
            payload: newBoards,
        });
    };

    const cancelCreateBoard = (event) => {
        event.preventDefault();
        if (errorMessage) {
            setErrorMessage(null);
        }
        setOpenCreateBoardModal((prev) => !prev);
        setOpenAddToBoardModal((prev) => !prev);
    };

    return (
        <SearchPageContainer>
            <InstantSearch
                indexName={INDEX_NAME}
                searchClient={SEARCH_CLIENT}
                searchState={searchState}
                onSearchStateChange={onSearchStateChange}
                createURL={createURL}
            >
                <TopSearchSection>
                    {openNameSavedSearchModal && (
                        <NameSavedSearchModal
                            openNameSavedSearchModal={openNameSavedSearchModal}
                            setOpenNameSavedSearchModal={
                                setOpenNameSavedSearchModal
                            }
                            submitSavedSearch={submitSavedSearch}
                            savedSearchTitle={savedSearchTitle}
                            setSavedSearchTitle={setSavedSearchTitle}
                            handleSavedSearchTitleChange={
                                handleSavedSearchTitleChange
                            }
                            errorMessage={errorMessage}
                            setErrorMessage={setErrorMessage}
                        />
                    )}
                    {/* Breadcrumb only existed with HierarchicalMenu*/}
                    <CustomBreadcrumb
                        attributes={HIERARCHICAL_ATTRIBUTES}
                        searchState={searchState}
                        setCurrentHierarchicalCategory={
                            setCurrentHierarchicalCategory
                        }
                        onSearchStateChange={onSearchStateChange}
                    />
                    <SearchBarSection
                        openDropdown={openDropdown}
                        showDropdown={showDropdown}
                        setShowDropdown={setShowDropdown}
                        openNameSavedSearchModal={openNameSavedSearchModal}
                        setOpenNameSavedSearchModal={
                            setOpenNameSavedSearchModal
                        }
                        searchState={searchState}
                        currentHierarchicalCategory={formatCategoryLabel(
                            currentHierarchicalCategory
                        )}
                    />
                    <RefinementsWrapper className="header_cabin_sm">
                        <CurrentRefinements
                            transformItems={(items) => {
                                return items.filter(
                                    ({ attribute }) =>
                                        attribute !== HIERARCHICAL_LVLALL &&
                                        attribute !== HIERARCHICAL_LVL1 &&
                                        attribute !== HIERARCHICAL_LVL2 &&
                                        attribute !== HIERARCHICAL_LVL3 &&
                                        attribute !== HIERARCHICAL_LVL4 &&
                                        attribute !== 'User Specific'
                                );
                            }}
                        />
                        <CustomClearRefinements
                            clearsQuery={true}
                            transformItems={(items) =>
                                items.filter(
                                    ({ label }) =>
                                        label !== 'User Specific: Everyone' &&
                                        label !==
                                            'hierarchicalCategories.lvlAll: Home'
                                )
                            }
                            searchState={searchState}
                            setSearchState={setSearchState}
                            onSearchStateChange={onSearchStateChange}
                            setCurrentHierarchicalCategory={
                                setCurrentHierarchicalCategory
                            }
                        />
                    </RefinementsWrapper>
                    <HierarchicalMenuContainer
                        hasQuery={Boolean(searchState.query?.length)}
                    >
                        {!isMobile ? (
                            <Header className="header_sm">
                                {formatCategoryLabel(
                                    currentHierarchicalCategory
                                )}
                            </Header>
                        ) : null}
                        <MenuWrapper>
                            {/* Don't show "Sub-categories" when at home or no more categories */}
                            {location.search?.includes(
                                'hierarchicalCategories'
                            ) &&
                            categories.length > 0 &&
                            Object.keys(searchState)?.length !== 0 ? (
                                <MenuTitle className="body_cabin_sm">
                                    Sub-categories:
                                </MenuTitle>
                            ) : null}
                            <CustomHierarchicalMenu
                                showParentLevel={false}
                                attributes={HIERARCHICAL_ATTRIBUTES}
                                defaultRefinement={DEFAULT_REFINEMENT}
                            />
                        </MenuWrapper>
                    </HierarchicalMenuContainer>
                    <SearchResultsInfo
                        cardView={cardView}
                        setCardView={setCardView}
                        searchState={searchState}
                        currentHierarchicalCategory={formatCategoryLabel(
                            currentHierarchicalCategory
                        )}
                    />
                </TopSearchSection>
                <InfiniteScrollSearchResult
                    cardView={cardView}
                    handleClick={handleOpenResourceBlade}
                    setCurrentResource={setCurrentResource}
                    setOpenAddToBoardModal={setOpenAddToBoardModal}
                    setCheckBoxState={setCheckBoxState}
                    boards={boards}
                    resourceSet={resourceSet}
                    searchState={searchState}
                />
                {openResourceBlade && (
                    <ResourceBlade
                        openResourceBlade={openResourceBlade}
                        setOpenResourceBlade={setOpenResourceBlade}
                        setIsHoverOnModal={setIsHoverOnModal}
                        slugFromURL={slugFromURL}
                        setSlugFromURL={setSlugFromURL}
                        resourceSlug={resourceSlug}
                        setResourceSlug={setResourceSlug}
                        handleClick={handleOpenResourceBlade}
                    />
                )}
                <AddToBoardModal
                    openAddToBoardModal={openAddToBoardModal}
                    setOpenAddToBoardModal={setOpenAddToBoardModal}
                    setOpenCreateBoardModal={setOpenCreateBoardModal}
                    addResourcesToBoards={addResourcesToBoards}
                    checkBoxState={checkBoxState}
                    setCheckBoxState={setCheckBoxState}
                    createBoard={createBoard}
                    closeAddToBoardModal={closeAddToBoardModal}
                />
                <CreateBoardModal
                    openCreateBoardModal={openCreateBoardModal}
                    setOpenCreateBoardModal={setOpenCreateBoardModal}
                    handleBoardNameChange={handleBoardNameChange}
                    createBoard={createBoard}
                    firstLayer={false}
                    errorMessage={errorMessage}
                    handleCancel={cancelCreateBoard}
                />
            </InstantSearch>
        </SearchPageContainer>
    );
};

const SearchPageContainer = styled(PageContainer)`
    overflow-x: hidden;
`;

const TopSearchSection = styled.div`
    padding: 4.8rem 12rem 0 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};

    @media screen and (max-width: 767px) {
        padding: 1.6rem 2.4rem 0 2.4rem;
    }
`;

const RefinementsWrapper = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 4rem;
    margin-bottom: 4rem;
    border-bottom: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};

    @media screen and (max-width: 767px) {
        align-items: center;
        width: 100vw;
        padding-bottom: 2.4rem;
        margin-bottom: 1.2rem;

        margin-left: -2.4rem;
        margin-right: -2.4rem;
        padding-left: 3.2rem;
        overflow-x: scroll;
        /* hide scrollbar */
        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
`;

const HierarchicalMenuContainer = styled.div`
    margin: 0;
    width: 80%;
    display: ${({ hasQuery }) => (hasQuery ? `none` : `flex`)};
    flex-direction: column;
    margin-bottom: 6.4rem;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;

const Header = styled.h2`
    width: 100%;
    text-align: center;
    margin-bottom: 2.4rem;
`;

const MenuWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`;

const MenuTitle = styled.p`
    color: ${(props) => props.theme.colors.dark_grey};
    white-space: nowrap;
    margin-top: 1rem;
    margin-right: 1.6rem;

    @media screen and (max-width: 767px) {
        margin-bottom: 0.8rem;
    }
`;

export default AlgoliaSearch;
