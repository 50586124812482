import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import CancelButton from 'assets/styledComponents/Buttons/CancelButton';
import DeleteOrRemoveButton from 'assets/styledComponents/Buttons/DeleteOrRemoveButton';
import CenteredModal from 'assets/styledComponents/CenteredModal';
import CloseModalButton from 'assets/styledComponents/CloseModalButton';
import ModalOverlay from 'assets/styledComponents/ModalOverlay';
import { useGlobalContext } from 'globalStore/globalContext';
import { useMutation } from '@apollo/client';
import { UPDATE_BOARDS } from 'graphql/mutation';
import { updateBoards } from 'globalStore/globalReducer';

export default function DeleteResourceModal({
    openDeleteModal,
    setOpenDeleteModal,
    handleCancel,
    currentResource,
    boardName,
}) {
    const { globalState, dispatch } = useGlobalContext();
    const [addResource] = useMutation(UPDATE_BOARDS);

    const deleteResource = () => {
        const newBoard = globalState.boards[boardName].filter(
            (elem) => elem.id !== currentResource.id
        );

        dispatch({
            type: updateBoards,
            payload: { ...globalState.boards, [boardName]: newBoard },
        });
        addResource({
            variables: {
                registeredUserId: globalState.registeredUserId,
                boards: { ...globalState.boards, [boardName]: newBoard },
            },
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: globalState.registeredUserId,
                        boards: {
                            ...globalState.boards,
                            [boardName]: newBoard,
                        },
                        __typename: 'RegisteredUser',
                    },
                    __typename: 'updateRegisteredUserPayload',
                },
            },
        });
        setOpenDeleteModal(false);
    };

    return (
        openDeleteModal && (
            <Fragment>
                <ModalOverlay
                    openModal={openDeleteModal}
                    setOpenModal={setOpenDeleteModal}
                />
                <CenteredModal width={`37.7vw`}>
                    <CloseModalButton
                        openModal={openDeleteModal}
                        setOpenModal={setOpenDeleteModal}
                    />
                    <Header className="header_xs">
                        Remove Resource from Board?
                    </Header>
                    <Content>
                        <p className="body_cabin_sm">
                            Are you sure you want to remove{' '}
                            <span className="body_cabin_sm">
                                {currentResource.title}
                            </span>{' '}
                            from your {boardName} board?
                        </p>
                        <ButtonsContainer>
                            <CancelButton onClick={handleCancel} />
                            <DeleteOrRemoveButton
                                content="Delete"
                                onClick={deleteResource}
                            />
                        </ButtonsContainer>
                    </Content>
                </CenteredModal>
            </Fragment>
        )
    );
}

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
        font-style: italic;
    }
`;

const Header = styled.h3`
    font-size: 2.2rem;
    margin-bottom: 1.6rem;
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;

    @media only screen and (max-width: 767px) {
        justify-content: center;
    }

    button {
        margin: 0;

        :first-of-type {
            margin-right: 0.8rem;
        }
    }
`;
