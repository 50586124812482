import React from 'react';
import styled from '@emotion/styled';

const ModalOverlay = ({
    openModal,
    setOpenModal,
    closeModal,
    firstLayer = true,
    transparent = false,
}) => {
    const handleClick = (event) => {
        event.preventDefault();
        closeModal ? closeModal() : setOpenModal(!openModal);
    };
    return (
        openModal && (
            <GreyBackground
                onClick={handleClick}
                firstLayer={firstLayer}
                transparent={transparent}
            />
        )
    );
};

const GreyBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${({ transparent }) =>
        transparent ? `none` : `rgba(0, 0, 0, 0.5)`};
    z-index: ${(props) =>
        props.firstLayer
            ? props.theme.zIndex.modalOverlay
            : props.theme.zIndex.secondLayerModalOverlay};

    :hover {
        cursor: pointer;
    }
`;

export default ModalOverlay;
