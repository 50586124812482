import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Search from 'assets/images/blue-search-icon.svg';
import DropdownArrow from 'assets/images/search-chevron-icon.svg';
import SignUpSection from './SignUpSection';
import { useGlobalContext } from 'globalStore/globalContext';
import { useHistory } from 'react-router-dom';
import BCCHlogo from 'assets/images/logos/bcch-logo.png';

const HeroSection = ({ description, heroImage, setBothSignUp }) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [currentView, setCurrentView] = useState('Everyone');
    const dropdownOptions = ['Everyone', 'Family', 'Provider'];
    const { globalState } = useGlobalContext();
    const [search, setSearch] = useState('');
    const history = useHistory();
    const [heroImageNum, setHeroImageNum] = useState(0);
    const [bottomPadding, setBottomPadding] = useState(0);

    //moves the carousel to the next image
    useEffect(() => {
        const timer = setTimeout(() => {
            if (heroImageNum < heroImage.length - 1) {
                setHeroImageNum((count) => count + 1);
            } else {
                setHeroImageNum(() => 0);
            }
        }, 10000);

        return () => clearTimeout(timer);
    }, [heroImage.length, heroImageNum]);

    //loads all the images into memory so they don't have to load on the first render
    function preloadImage(src) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
                resolve(img);
            };
            img.onerror = img.onabort = function () {
                reject(src);
            };
            img.src = src;
        });
    }

    useEffect(() => {
        let heroImages = [];

        const callPreload = async () => {
            for (const i of heroImage) {
                heroImages.push(preloadImage(i.url));
            }
            await Promise.all(heroImages);
        };

        callPreload();
        //eslint-disable-next-line
    }, []);

    const dropdownList = () => {
        return (
            <DropdownList className="header_cabin_sm">
                {dropdownOptions.map((option, index) => {
                    return (
                        <DropdownItem
                            selected={currentView === option}
                            key={index}
                            onClick={() => {
                                setCurrentView(option);
                                setOpenDropdown(false);
                            }}
                        >
                            <p className="body_cabin_sm ">For {option}</p>
                        </DropdownItem>
                    );
                })}
            </DropdownList>
        );
    };
    const sendSearch = () => {
        history.push({
            pathname: '/search',
            state: {
                query: search,
                currentView: currentView,
            },
        });
    };

    useEffect(() => {
        //listen for enter and send search and currentview to search page via useHistory
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                sendSearch();
            }
        };
        document
            .getElementById('hero-search-input')
            .addEventListener('keydown', handleKeyDown);
        return () => {
            const input = document.getElementById('hero-search-input');
            if (input) {
                input.removeEventListener('keydown', handleKeyDown);
            }
        };
    });

    //SignUpSection became a child of this component to handle the 50% positioning between the Hero and Browse Section with as little fuss as possible.
    return (
        <HeroWrapper bottomPadding={bottomPadding}>
            <HeroBackground src={heroImage[heroImageNum].url} />
            <DivWrapper>
                <HeroDiv>
                    <HeroDescription
                        dangerouslySetInnerHTML={{
                            __html: description.replaceAll(
                                '</h2><h2>', // replace unnecessary closing/opening <h2> tags with line break tag (<br />)
                                '<br />'
                            ),
                        }}
                    />
                    <HeroImg>
                        <img alt="" className="hero-image" src={BCCHlogo}></img>
                    </HeroImg>
                </HeroDiv>
            </DivWrapper>
            <SearchDiv>
                <SearchBar id="search-bar">
                    <ViewDropdown
                        onClick={() => setOpenDropdown(!openDropdown)}
                    >
                        {!globalState.isMobile && (
                            <p className="header_cabin_sm">For {currentView}</p>
                        )}
                        <img src={DropdownArrow} alt="dropdown arrow" />
                        {openDropdown && dropdownList()}
                    </ViewDropdown>
                    <SearchInput
                        type="search"
                        id="hero-search-input"
                        name="hero-search-input"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={
                            globalState.isMobile
                                ? 'How can we help?'
                                : 'What can we help you find today?'
                        }
                    />
                    <SearchButton type="submit" onClick={sendSearch}>
                        <SearchIcon src={Search} alt="search icon" />
                    </SearchButton>
                </SearchBar>
            </SearchDiv>
            <SignUpSection
                setBothSignUp={setBothSignUp}
                bottomPadding={bottomPadding}
                setBottomPadding={setBottomPadding}
            />
        </HeroWrapper>
    );
};

const DivWrapper = styled.div`
    padding: 0 22.4rem;

    @media only screen and (max-width: 1439px) {
        padding: 0 14.5rem;
    }
    @media only screen and (max-width: 767px) {
        padding: 0 2.4rem;
    }
`;

const HeroDiv = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    max-width: 100rem;

    @media only screen and (max-width: 767px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;

const SearchDiv = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    padding: 0 22.4rem;

    @media only screen and (max-width: 1439px) {
        padding: 0 14.5rem;
    }
    @media only screen and (max-width: 767px) {
        padding: 0 2.4rem;
    }
`;

const HeroWrapper = styled.div`
    position: relative;
    margin: 0 0 ${(props) => `${props.bottomPadding}`}px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 767px) {
        margin: 0;
    }
`;

const DropdownList = styled.ul`
    padding: 1.2rem 0;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 16rem;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.white};
    box-sizing: border-box;
    box-shadow: 0px 0px 1rem 0.1rem rgba(149, 149, 149, 0.1);
    border-radius: 0.5rem;
    margin: 0.5rem 0 0 0;
    transform: translate(-3.2rem, 5.6rem);
    z-index: 10;

    @media only screen and (max-width: 767px) {
        margin-left: 2.4rem;
    }
`;

const DropdownItem = styled.li`
    padding: 0.4rem 3.2rem;
    color: ${(props) =>
        props.selected
            ? props.theme.colors.secondary
            : props.theme.colors.primary};

    &:hover {
        background-color: ${(props) => props.theme.colors.light_grey};
    }

    p {
        margin: 0;
        width: fit-content;
    }
`;

const ViewDropdown = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 1.6rem 1.6rem 3.2rem;
    background-color: ${(props) => props.theme.colors.white};
    border-bottom-left-radius: 5rem;
    border-top-left-radius: 5rem;
    position: relative;
    height: 5.6rem;
    width: 16rem;
    min-width: fit-content;
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    box-shadow: 0 0.2rem 1.8rem 0 rgba(0, 0, 0, 0.12);

    > img {
        width: 1.6rem;
    }

    @media only screen and (max-width: 767px) {
        padding: 0 0.8rem;
        width: fit-content;
        height: 4.8rem;

        img {
            height: 1.6rem;
            width: 1.6rem;
            align-self: center;
            margin-left: 1rem;
        }
    }
`;

const HeroBackground = styled.img`
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;

    @media only screen and (max-width: 767px) {
        height: 75%;
        top: 0;
    }
`;

const HeroImg = styled.div`
    z-index: 1;
    display: flex;
    align-self: flex-end;
    img {
        width: 100px;
        height: auto;
        @media only screen and (max-width: 767px) {
            height: 40px;
            width: auto;
        }
    }
    @media only screen and (max-width: 767px) {
        align-self: flex-start;
        margin-top: calc(56px + ${(props) => props.theme.spaces.navbarHeight});
        margin-bottom: 8px;
    }
`;

const HeroDescription = styled.div`
    z-index: 1;
    margin-top: calc(10.4rem + ${(props) => props.theme.spaces.navbarHeight});
    max-width: 100rem;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    h1,
    h2 {
        font-family: 'MontserratBold700', sans-serif;
        font-size: 4rem;
        line-height: 4.8rem;
        margin: 0;
        text-align: left;
        color: ${(props) => props.theme.colors.white};
    }
    span {
        font-size: 4rem;
        font-family: 'MontserratBold700', sans-serif;
    }
    @media only screen and (max-width: 1439px) {
        margin-top: calc(
            4.8rem + ${(props) => props.theme.spaces.navbarHeight}
        );
    }
    @media only screen and (min-width: 768px) and (max-width: 1439px) {
        h2 {
            font-size: 3.2rem;
            width: 95%;
            br {
                display: none;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        margin-top: 0;
        h1,
        h2,
        span {
            font-size: 2.2rem;
            line-height: 2.8rem;
        }
        h2 {
            br {
                display: none;
            }
        }
    }
`;

const SearchBar = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
    max-width: 100rem;
    height: 5.6rem;
    position: relative;
    align-items: center;
    margin-bottom: 9.6rem;

    @media only screen and (max-width: 1439px) {
        margin-bottom: 8rem;
    }
    @media only screen and (max-width: 767px) {
        margin-bottom: 2.4rem;
    }
`;

const SearchButton = styled.button`
    background-color: ${(props) => props.theme.colors.tertiary};
    border: none;
    width: 10.4rem;
    height: 5.6rem;
    border-radius: 5rem;
    position: absolute;
    right: 0;
    cursor: pointer;
    :hover {
        background-color: ${(props) => props.theme.colors.tertiary_dark};
    }

    @media only screen and (max-width: 767px) {
        width: 4.8rem;
        height: 4.8rem;
        padding: 0 1rem;
    }
`;

const SearchInput = styled.input`
    height: 5.6rem;
    border: 0;
    border-radius: 0 5rem 5rem 0;
    padding: 0.5rem 0.5rem 0.5rem 0;
    flex: 1;
    background-color: ${(props) => props.theme.white};
    padding: 1rem;
    box-shadow: inset 0 0.2rem 1.8rem 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadowbox-shadow: inset 0 0.2rem 1.8rem 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 0.2rem 1.8rem 0 rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: 767px) {
        margin-left: -2rem;
        padding: 0 3rem;
        height: 4.8rem;
    }
`;

const SearchIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
`;

export default HeroSection;
