import React from 'react';
import styled from '@emotion/styled';
import RecommendedResourcesList from './RecommendedResourcesList';
import BladeContentContainer from '../../../assets/styledComponents/BladeContentContainer';

const RecommendedForYouSection = ({
    recommendedResources,
    handleClick,
    openResourceBlade,
    openRecommendedResourceBlade,
}) => {
    if (!recommendedResources) return null;
    // calculate number of grid rows by number of recommended resources
    const numberOfResource = recommendedResources?.length;
    const numberOfGridRows = Math.ceil(numberOfResource / 2);

    return (
        <RecommendedForYou>
            <Header className="header_cabin_sm">Recommended for You</Header>
            <ResourcesContainer numberOfGridRows={numberOfGridRows}>
                <RecommendedResourcesList
                    recommendedResources={recommendedResources.resources}
                    handleClick={handleClick}
                    openResourceBlade={openResourceBlade}
                    openRecommendedResourceBlade={openRecommendedResourceBlade}
                />
            </ResourcesContainer>
        </RecommendedForYou>
    );
};

const RecommendedForYou = styled(BladeContentContainer)`
    border-top: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};
    text-align: left;
    padding-top: 2.4rem;
`;

const Header = styled.h2`
    width: 100%;
    margin-bottom: 0 !important;
`;

const ResourcesContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: ${({ numberOfGridRows }) =>
        `repeat(${numberOfGridRows}, 1fr)`};
    gap: 0 3.2rem;

    @media only screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
`;

export default RecommendedForYouSection;
