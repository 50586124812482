import styled from '@emotion/styled';
import React, { useEffect, Fragment, useRef } from 'react';
import CancelButton from '../../assets/styledComponents/Buttons/CancelButton';
import PrimaryButton from '../../assets/styledComponents/Buttons/PrimaryButton';
import CenteredModal from '../../assets/styledComponents/CenteredModal';
import CloseModalButton from '../../assets/styledComponents/CloseModalButton';
import ModalOverlay from '../../assets/styledComponents/ModalOverlay';
import { useGlobalContext } from '../../globalStore/globalContext';

const CreateBoardModal = ({
    openCreateBoardModal,
    setOpenCreateBoardModal,
    createBoard,
    handleBoardNameChange,
    handleCancel,
    firstLayer,
    errorMessage,
}) => {
    const inputRef = useRef();
    const { globalState } = useGlobalContext();

    useEffect(() => {
        if (openCreateBoardModal) {
            inputRef.current.focus();
        }
    }, [openCreateBoardModal]);

    return (
        openCreateBoardModal && (
            <Fragment>
                <ModalOverlay
                    openModal={openCreateBoardModal}
                    setOpenModal={setOpenCreateBoardModal}
                    firstLayer={firstLayer}
                />
                <CenteredModal
                    width={`37.7vw`}
                    isMobile={globalState.isMobile}
                    firstLayer={firstLayer}
                >
                    <CloseModalButton
                        openModal={openCreateBoardModal}
                        setOpenModal={setOpenCreateBoardModal}
                    />
                    <Header className="header_xs">Board Name</Header>
                    <StyledForm onSubmit={createBoard}>
                        <Input
                            ref={inputRef}
                            type="text"
                            name="createBoardName"
                            onChange={handleBoardNameChange}
                            defaultValue=""
                            className="body_cabin_sm"
                            onKeyPress={(event) =>
                                event.key === 'Enter' && createBoard(event)
                            }
                        />

                        <ErrorMessage
                            className="body_cabin_sm"
                            error={errorMessage}
                        >
                            {errorMessage}
                        </ErrorMessage>

                        <ButtonsContainer>
                            <PrimaryButton type="submit" content="Create" />
                            <CancelButton
                                type="button"
                                onClick={handleCancel}
                            />
                        </ButtonsContainer>
                    </StyledForm>
                </CenteredModal>
            </Fragment>
        )
    );
};

const StyledForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Header = styled.h3`
    font-size: 2.2rem;
`;

const Input = styled.input`
    width: 100%;
    height: 4rem;
    margin-top: 1.5rem;
    border: ${(props) => `1px solid ${props.theme.colors.grey}`};
    padding: 0.8rem 1.6rem;
`;

const ErrorMessage = styled.p`
    display: ${({ error }) => (error ? `block` : `none`)};
    color: red;
    margin: 1rem 0;
    height: 2rem;
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 3rem;

    @media only screen and (max-width: 767px) {
        justify-content: center;
    }

    button {
        margin: 0;

        :last-of-type {
            margin-right: 0.8rem;
        }
    }
`;

export default CreateBoardModal;
