import React from 'react';
import CreateBoardIcon from '../../assets/images/create-board-icon.svg';
import styled from '@emotion/styled';

const PrimaryCreateBoardButton = ({ content, onClick, width, isMobile }) => {
    return (
        <CreateBoardWrapper onClick={onClick} width={width} isMobile={isMobile}>
            <Icon src={CreateBoardIcon} alt="create board icon" />
            <span className="header_cabin_sm">{content}</span>
        </CreateBoardWrapper>
    );
};

const CreateBoardWrapper = styled.button`
    border: none;
    border-radius: 5rem;
    width: ${(props) => (props.width ? props.width : `auto`)};
    height: ${(props) => (props.large ? `4.8rem` : `4rem`)};
    color: ${(props) => props.theme.colors.primary};
    padding: 1.2rem 4rem;
    background-color: ${(props) => props.theme.colors.tertiary};
    display: flex;
    align-items: center;

    :hover {
        background-color: ${(props) => props.theme.colors.tertiary_dark};
    }
`;

const Icon = styled.img`
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1rem;
`;

export default PrimaryCreateBoardButton;
