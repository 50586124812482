import styled from '@emotion/styled';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useGlobalContext } from 'globalStore/globalContext';
import { updateBoards } from 'globalStore/globalReducer';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_BOARDS } from 'graphql/mutation';
import { GET_BOARDS } from 'graphql/queries';
import {
    capitalizeEachWord,
    deleteExtraSpaces,
} from 'functions/formatString.js';
import IconButton from 'assets/styledComponents/IconButton';
import Grid from 'assets/images/grid-icon.svg';
import List from 'assets/images/list-icon.svg';
import Dots from 'assets/images/3-dots.svg';
import PageContainer from 'assets/styledComponents/PageContainer';
import IndividualBoardContent from 'components/DashBoardPage/IndividualBoardPage/IndividualBoardContent';
import EditBoardModal from 'components/BoardModals/EditBoardModal';
import RenameBoardModal from 'components/BoardModals/RenameBoardModal';
import DeleteBoardModal from 'components/BoardModals/DeleteBoardModal';
import ResourceBlade from 'components/ResourceBlade';
import EmptyIndividualBoardContent from 'components/DashBoardPage/IndividualBoardPage/EmptyIndividualBoardContent';
import CreateBoardModal from 'components/BoardModals/CreateBoardModal';
import AddToBoardModal from 'components/BoardModals/AddToBoardModal';
import useAddOrCreateBoardModal from 'hooks/useAddOrCreateBoardModal';
import DeleteResourceModal from 'components/DashBoardPage/IndividualBoardPage/DeleteResourceModal';
import DropdownArrow from 'assets/images/search-chevron-icon.svg';

const IndividualBoardPage = () => {
    const viewDropdown = document.getElementById('view-dropdown');
    const history = useHistory();
    const location = useLocation();
    const { encodedName } = useParams();

    const { globalState, dispatch } = useGlobalContext();
    const { registeredUserId } = globalState;
    const { data: boardsData } = useQuery(GET_BOARDS, {
        variables: { id: registeredUserId },
    });
    const [boardName, setBoardName] = useState(null);
    const [resources, setResources] = useState(globalState.boards[boardName]);
    const [newBoardName, setNewBoardName] = useState(boardName);
    const [openEditBoardModal, setOpenEditBoardModal] = useState(false);
    const [openRenameBoardModal, setOpenRenameBoardModal] = useState(false);
    const [openDeleteBoardModal, setOpenDeleteBoardModal] = useState(false);
    const [openResourceBlade, setOpenResourceBlade] = useState(null);
    const [resourceSlug, setResourceSlug] = useState(null);
    const [isHoverOnModal, setIsHoverOnModal] = useState(false);
    const [slugFromURL, setSlugFromURL] = useState(null);
    const [layout, setLayout] = useState('grid');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [currentView, setCurrentView] = useState('Everyone');
    const [filteredResources, setFilteredResources] = useState(resources);
    const dropdownOptions = ['Everyone', 'Provider', 'Family'];
    const modalRef = useRef();
    // Close modal when click outside of the modal
    useEffect(() => {
        const closeModalClickOutside = (event) => {
            if (
                openDropdown &&
                !modalRef.current.contains(event.target) &&
                !viewDropdown.contains(event.target)
            ) {
                setOpenDropdown(!openDropdown);
            }
        };
        document.addEventListener('mousedown', closeModalClickOutside);
        return () => {
            document.removeEventListener('mousedown', closeModalClickOutside);
        };
        // eslint-disable-next-line
    }, [openDropdown]);
    // for add/create board modal functions in cards
    const {
        setCheckBoxState,
        boards,
        setBoards,
        setErrorMessage,
        setCurrentResource,
        setOpenAddToBoardModal,
        openAddToBoardModal,
        setOpenCreateBoardModal,
        addResourcesToBoards,
        checkBoxState,
        createBoard,
        closeAddToBoardModal,
        openCreateBoardModal,
        handleBoardNameChange,
        errorMessage,
        cancelCreateBoard,
        currentResource,
    } = useAddOrCreateBoardModal();

    useEffect(() => {
        const search = new URLSearchParams(location.search);
        const slug = search.get('resource');
        if (slug && !openResourceBlade && !resourceSlug) {
            setSlugFromURL(slug);
            setOpenResourceBlade(true);
        }
        return () => {
            setSlugFromURL(null);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setBoardName(decodeURIComponent(encodedName));
        setOpenEditBoardModal(false);
        // eslint-disable-next-line
    }, [encodedName]);

    useEffect(() => {
        if (
            boardsData != null &&
            boardsData.registeredUser?.boards &&
            boardName
        ) {
            setBoards(boardsData.registeredUser.boards);
        }
        // eslint-disable-next-line
    }, [boardsData]);

    useEffect(() => {
        if (boardName) {
            setResources(boards[boardName]);
            setCurrentResource(boards[boardName]);
        }
        // eslint-disable-next-line
    }, [boardName, boards]);

    // Prevent background content scrolling when hover on blade modal
    if (openResourceBlade && isHoverOnModal) {
        document.body.style.overflow = 'hidden';
    } else if (!isHoverOnModal || !openResourceBlade) {
        document.body.style.overflow = 'unset';
    }

    const toggleLayout = () => {
        layout === 'grid' ? setLayout('list') : setLayout('grid');
    };

    const [editBoard] = useMutation(UPDATE_BOARDS, {
        onCompleted: () => {
            // change url with new board name
            history.push({
                pathname: `/myboard/boards/${encodeURIComponent(boardName)}`,
                state: {
                    // passing name as location state through react router
                    name: boardName,
                },
            });
            setNewBoardName(null);
        },
        onError: (error) => console.error('Rename board error: ', error),
    });

    const [removeBoard] = useMutation(UPDATE_BOARDS, {
        onCompleted: () => {
            // redirect back to dashboard page after delete individual board
            history.push('/myboard/board');
            setBoardName(null);
        },
        onError: (error) => console.error('Delete board error: ', error),
    });

    const handleNewBoardNameChange = (event) => {
        if (errorMessage) {
            setErrorMessage(null);
        }
        setNewBoardName(event.target.value);
    };

    const handleOpenEditBoardModal = (event) => {
        event.preventDefault();
        setOpenEditBoardModal((prev) => !prev);
    };

    const renameBoard = (event) => {
        event.preventDefault();
        setNewBoardName(boardName);

        if (!newBoardName) {
            return setErrorMessage('Please enter a valid name...');
        }
        const trimmedBoardName = deleteExtraSpaces(newBoardName);
        const formattedBoardName = capitalizeEachWord(trimmedBoardName);

        if (boards && boards[formattedBoardName]) {
            return setErrorMessage('Board name already existed...');
        }

        setBoardName(formattedBoardName);

        // get the value from the old board name
        const oldBoardContent = boards[boardName];
        const newBoards = {
            ...boards,
            // reassign value to the new board name
            [formattedBoardName]: oldBoardContent,
        };
        // delete the old board name key value
        delete newBoards[boardName];

        setBoards(newBoards);
        setOpenRenameBoardModal(false);
        setOpenEditBoardModal(false);

        editBoard({
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: registeredUserId,
                        boards: newBoards,
                        __typename: 'RegisteredUser',
                    },
                    __typename: 'updateRegisteredUserPayload',
                },
            },
            variables: {
                registeredUserId: registeredUserId,
                boards: newBoards,
            },
        });
        dispatch({
            type: updateBoards,
            payload: newBoards,
        });
    };

    const deleteBoard = (event) => {
        event.preventDefault();

        let newBoards = {
            ...boards,
        };
        delete newBoards[boardName];

        setBoards(newBoards);
        setOpenDeleteBoardModal(false);
        setOpenEditBoardModal(false);

        removeBoard({
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: registeredUserId,
                        boards: newBoards,
                        __typename: 'RegisteredUser',
                    },
                    __typename: 'updateRegisteredUserPayload',
                },
            },
            variables: {
                registeredUserId: registeredUserId,
                boards: newBoards,
            },
        });
        dispatch({
            type: updateBoards,
            payload: newBoards,
        });
    };
    const handleCancelRenameBoard = () => {
        setOpenRenameBoardModal((prev) => !prev);
        if (errorMessage) {
            setErrorMessage(null);
        }
        setNewBoardName(null);
    };
    const handleCancelDeleteBoard = () => {
        setOpenDeleteBoardModal((prev) => !prev);
    };

    const handleOpenBlade = (event, slug) => {
        event.preventDefault();
        if (slugFromURL) {
            setSlugFromURL(null);
        }
        setResourceSlug(slug);

        if (openResourceBlade) {
            return history.push({
                pathname: `/myboard/boards/${encodeURIComponent(boardName)}`,
                search: `resource=${slug}`,
                state: {
                    // passing board name as location state through react router
                    name: boardName,
                },
            });
        }
        if (!openResourceBlade) {
            setOpenResourceBlade(true);

            // concat resource param to the current URL
            const currentSearchParam = history.location.search;

            return history.push({
                pathname: `/myboard/boards/${encodeURIComponent(boardName)}`,
                search: currentSearchParam
                    ? `${currentSearchParam}&resource=${slug}`
                    : `resource=${slug}`,
                state: {
                    // passing board name as location state through react router
                    name: boardName,
                },
            });
        }
    };

    const handleCancelDeleteModal = () => {
        setOpenDeleteModal((prev) => !prev);
    };

    const handleView = (option) => {
        switch (option) {
            case 'Everyone':
                setCurrentView('Everyone');
                setFilteredResources(resources);
                break;
            case 'Provider':
                setCurrentView('Provider');
                setFilteredResources(
                    resources.filter((resource) =>
                        resource.view?.includes('Provider')
                    )
                );
                break;
            case 'Family':
                setCurrentView('Family');
                setFilteredResources(
                    resources.filter((resource) =>
                        resource.view?.includes('Family')
                    )
                );
                break;
        }
    };

    useEffect(() => {
        handleView(currentView);
        //eslint-disable-next-line
    }, [resources]);

    const dropdownList = () => {
        return (
            <DropdownList ref={modalRef}>
                {dropdownOptions.map((option, index) => {
                    return (
                        <DropdownItem
                            selected={currentView === option}
                            key={index}
                            onClick={() => handleView(option)}
                        >
                            <p className="body_cabin_sm">For {option}</p>
                        </DropdownItem>
                    );
                })}
            </DropdownList>
        );
    };

    return (
        <DashboardPageContainer isMobile={globalState.isMobile}>
            <HeaderContainer>
                <HeaderLeft>
                    <h1 className="header_sm">{boardName}</h1>
                    <IconButton
                        icon={Dots}
                        onClick={handleOpenEditBoardModal}
                    />
                    <EditBoardModal
                        setOpenEditBoardModal={setOpenEditBoardModal}
                        openEditBoardModal={openEditBoardModal}
                        setOpenRenameBoardModal={setOpenRenameBoardModal}
                        setOpenDeleteBoardModal={setOpenDeleteBoardModal}
                        name={boardName}
                        setNewBoardName={setNewBoardName}
                    />
                </HeaderLeft>
                <HeaderRight>
                    <ViewDropdown
                        id="view-dropdown"
                        onClick={() => setOpenDropdown(!openDropdown)}
                        openDropdown={openDropdown}
                    >
                        <p>For {currentView}</p>
                        <img src={DropdownArrow} alt="dropdown arrow" />
                        {openDropdown && dropdownList()}
                    </ViewDropdown>
                    {!globalState.isMobile && (
                        <>
                            <LayoutButton
                                className={`layout-button ${
                                    layout === 'grid' ? 'active' : ''
                                }`}
                                onClick={toggleLayout}
                            >
                                <img src={Grid} alt="grid view" />
                            </LayoutButton>
                            <LayoutButton
                                className={`layout-button ${
                                    layout === 'list' ? 'active' : ''
                                }`}
                                onClick={toggleLayout}
                            >
                                <img src={List} alt="list view" />
                            </LayoutButton>
                        </>
                    )}
                </HeaderRight>
            </HeaderContainer>

            {filteredResources?.length ? (
                <IndividualBoardContent
                    resources={filteredResources}
                    handleClick={handleOpenBlade}
                    layout={layout}
                    setCheckBoxState={setCheckBoxState}
                    boards={boards}
                    setCurrentResource={setCurrentResource}
                    setOpenAddToBoardModal={setOpenAddToBoardModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                />
            ) : (
                <EmptyIndividualBoardContent />
            )}
            {openResourceBlade && (
                <ResourceBlade
                    openResourceBlade={openResourceBlade}
                    setOpenResourceBlade={setOpenResourceBlade}
                    setIsHoverOnModal={setIsHoverOnModal}
                    slugFromURL={slugFromURL}
                    setSlugFromURL={setSlugFromURL}
                    resourceSlug={resourceSlug}
                    setResourceSlug={setResourceSlug}
                    handleClick={handleOpenBlade}
                    boardName={boardName}
                />
            )}
            <RenameBoardModal
                openRenameBoardModal={openRenameBoardModal}
                setOpenRenameBoardModal={setOpenRenameBoardModal}
                renameBoard={renameBoard}
                newBoardName={newBoardName}
                handleNewBoardNameChange={handleNewBoardNameChange}
                handleCancel={handleCancelRenameBoard}
                firstLayer={true}
                errorMessage={errorMessage}
            />
            <DeleteBoardModal
                openDeleteBoardModal={openDeleteBoardModal}
                setOpenDeleteBoardModal={setOpenDeleteBoardModal}
                deleteBoard={deleteBoard}
                handleCancel={handleCancelDeleteBoard}
                firstLayer={true}
                boardName={boardName}
            />
            <AddToBoardModal
                openAddToBoardModal={openAddToBoardModal}
                setOpenAddToBoardModal={setOpenAddToBoardModal}
                setOpenCreateBoardModal={setOpenCreateBoardModal}
                addResourcesToBoards={addResourcesToBoards}
                checkBoxState={checkBoxState}
                setCheckBoxState={setCheckBoxState}
                createBoard={createBoard}
                closeAddToBoardModal={closeAddToBoardModal}
            />
            <CreateBoardModal
                openCreateBoardModal={openCreateBoardModal}
                setOpenCreateBoardModal={setOpenCreateBoardModal}
                handleBoardNameChange={handleBoardNameChange}
                createBoard={createBoard}
                firstLayer={false}
                errorMessage={errorMessage}
                handleCancel={cancelCreateBoard}
            />
            <DeleteResourceModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                handleCancel={handleCancelDeleteModal}
                currentResource={currentResource}
                boardName={boardName}
            />
        </DashboardPageContainer>
    );
};

const DropdownList = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    list-style: none;
    background-color: ${(props) => props.theme.colors.white};
    width: 100%;
    color: ${(props) => props.theme.colors.primary};
    margin: 0.5rem 0 0 0;
    box-sizing: border-box;
    box-shadow: 0px 0px 1rem 0.1rem rgba(149, 149, 149, 0.1);
    border-radius: 0.5rem;
    padding: 1rem 0;
    transform: translate(-1.6rem, 3.4rem);
    z-index: 10;
    @media only screen and (max-width: 767px) {
        margin-left: 2.4rem;
        transform: translate(-3.2rem, 3.4rem);
    }
`;

const DropdownItem = styled.li`
    padding: 0.4rem 1.6rem;
    color: ${(props) =>
        props.selected
            ? props.theme.colors.secondary
            : props.theme.colors.primary};
    :hover {
        background-color: ${(props) => props.theme.colors.light_grey};
    }
`;

const ViewDropdown = styled.div`
    position: relative;
    cursor: pointer;
    height: 3.2rem;
    width: 16rem;
    min-width: fit-content;
    display: flex;
    justify-content: space-between;
    padding: 0 1.6rem;
    background-color: transparent;
    border: ${(props) =>
        props.openDropdown
            ? `1px solid ${props.theme.colors.secondary}`
            : `1px solid ${props.theme.colors.dark_grey}`};
    box-shadow: ${({ openDropdown }) =>
        openDropdown ? `0px 0px 3px 1px rgba(69, 116, 208, 0.8)` : `none`};
    border-radius: 0.5rem;
    color: ${(props) => props.theme.colors.primary};

    img {
        margin-left: 1.5rem;
        width: 1.6rem;
    }
    > p {
        align-self: center;
        font-family: 'CabinRegular400', sans-serif;
        letter-spacing: 0.03rem;
    }
    @media only screen and (max-width: 767px) {
        padding: 0 0.8rem;
        width: 100%;
        height: 3.2rem;
        img {
            height: 1.6rem;
            width: 1.6rem;
            align-self: center;
            margin-left: 1rem;
        }
        p {
            white-space: nowrap;
            overflow: hidden;
        }
    }
`;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.4rem 0;
    border-bottom: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};

    h1 {
        margin: 0;
        margin-right: 1.6rem;
    }
    @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
`;

const HeaderLeft = styled.div`
    display: flex;
    align-self: flex-end;
    align-items: center;
    position: relative;
    h1 {
        color: ${(props) => props.theme.colors.primary};
    }
    > button {
        margin-left: 0.8rem;
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 1.6rem;
    }
`;

const HeaderRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
        padding: 0;
    }

    .layout-button:first-of-type {
        margin-left: 4rem;
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
    }
`;

const LayoutButton = styled.button`
    cursor: pointer;
    background-color: transparent;
    border: ${(props) => `1px solid ${props.theme.colors.dark_grey}`};
    border-radius: 0.5rem;
    margin-left: 0.4rem;

    img {
        width: 3.1rem;
        height: 3.1rem;
        object-fit: cover;
    }

    &.active {
        border: ${(props) => `1px solid ${props.theme.colors.tertiary}`};
        background-color: ${(props) => props.theme.colors.tertiary};
        cursor: pointer;
    }
`;

const DashboardPageContainer = styled(PageContainer)`
    width: 100%;
    overflow-y: scroll;
    min-height: 100vh;
    height: auto;
    margin-top: 0;
    padding: 0 3.2rem;
    padding-top: ${({ isMobile }) => (isMobile ? `0rem` : `4rem`)};
    background-color: ${(props) => props.theme.colors.light_grey};
    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }
`;

export default IndividualBoardPage;
