/* TURN ALGOLIA SEARCH STATE INTO QUERY STRING URL, see https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/*/

import qs from 'qs';

// for not synchronize the search state t the browser URL too often
export const DEBOUNCE_TIME = 400;

// turns the search state into a string
export const createURL = (searchState) => {
    // clean up url after click on <ClearRefinements/>
    const { page, hierarchicalMenu, refinementList, menu, query, sortBy } =
        searchState;
    let isEmptyAge = true;
    let isEmptyAreaOfFocus = true;
    let isEmptyHealthCondition = true;
    let isEmptyTargetAudience = true;

    if (refinementList != null) {
        isEmptyAge =
            !refinementList['Age'] || refinementList['Age']?.length === 0;
        isEmptyAreaOfFocus =
            !refinementList['Area of Focus'] ||
            refinementList['Area of Focus'] === '';
        isEmptyHealthCondition =
            !refinementList['Health Condition'] ||
            refinementList['Health Condition'] === '';
        isEmptyTargetAudience =
            !refinementList['Target Audience'] ||
            refinementList['Target Audience'] === '';
    }

    const isEmptyQuery = !query || query.length === 0;
    const isEmptyPage = !page || page == '1';
    const isEmptyHierarchicalMenu =
        !hierarchicalMenu ||
        (Object.keys(hierarchicalMenu).length &&
            hierarchicalMenu['hierarchicalCategories.lvlAll'].length === 0) ||
        (Object.keys(hierarchicalMenu).length &&
            hierarchicalMenu['hierarchicalCategories.lvlAll'] === 'Home');
    const isEmptyRefinementList =
        !refinementList ||
        Object.keys(refinementList).length === 0 ||
        (isEmptyAge &&
            isEmptyAreaOfFocus &&
            isEmptyHealthCondition &&
            isEmptyTargetAudience);

    const isEmptyMenu =
        !menu ||
        (Object.keys(menu).length &&
            (!menu['User Specific'] || menu['User Specific'] === 'Everyone'));
    const isSorted = sortBy === 'cdr_resources_viewCounts_desc';

    const isEmptyState =
        isEmptyQuery &&
        isEmptyPage &&
        isEmptyHierarchicalMenu &&
        isEmptyRefinementList &&
        isEmptyMenu &&
        isSorted;

    if (isEmptyState) {
        return '';
    }

    return `?${qs.stringify(searchState)}`;
};

// converts search state into a search query URL
export const searchStateToUrl = (location, searchState) =>
    searchState ? `${location.pathname}${createURL(searchState)}` : '';

// creates a search state from a search query URL
export const urlToSearchState = ({ search }) => qs.parse(search.slice(1));
