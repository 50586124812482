import React from 'react';
import styled from '@emotion/styled';
import BladeContentContainer from '../../../assets/styledComponents/BladeContentContainer';
import ViewSourceButton from './ViewSourceButton';

const ExternalLinks = ({ externalLinks }) => {
    if (!externalLinks.length) return null;

    const ExternalLinkList = () =>
        externalLinks.map(({ id, externalLinkText, image, link }) => (
            <ContentWrapper
                key={id}
                hasOnlyLink={Boolean(!image && !externalLinkText)}
            >
                {image != null ? (
                    <Image
                        src={image.url}
                        hasOnlyImage={Boolean(!externalLinkText)}
                    />
                ) : null}
                {/* Check if externalLinkText !== '<p><br></p>' because when delete rich text on Strapi, it will still remain '<p><br></p>' string */}
                {externalLinkText != null &&
                externalLinkText !== '<p><br></p>' ? (
                    <TextContent
                        className="body_cabin_sm"
                        dangerouslySetInnerHTML={{
                            __html: externalLinkText,
                        }}
                    />
                ) : null}
                <ViewSourceButton url={link} />
            </ContentWrapper>
        ));

    return (
        <BladeContentContainer>
            <ExternalLinkList />
        </BladeContentContainer>
    );
};

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6.4rem;

    :last-of-type {
        margin-bottom: 0;
    }

    a {
        margin-top: ${({ hasOnlyLink }) => (hasOnlyLink ? `0` : `4.8rem`)};
    }
`;

const Image = styled.img`
    max-width: 100%;
    object-fit: scale-down;
    margin-bottom: 1.6rem;
`;

const TextContent = styled.div`
    color: ${(props) => props.theme.colors.black};

    p {
        all: unset;
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
`;

export default ExternalLinks;
