import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import ModalOverlay from '../../assets/styledComponents/ModalOverlay';
import CancelButton from '../../assets/styledComponents/Buttons/CancelButton';
import DeleteOrRemoveButton from '../../assets/styledComponents/Buttons/DeleteOrRemoveButton';
import CloseModalButton from '../../assets/styledComponents/CloseModalButton';

const RemoveAvatarModal = ({ openModal, setOpenModal, handleRemoveAvatar }) => {
    return (
        openModal && (
            <Fragment>
                <ModalOverlay
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
                <Modal width={`37.7vw`}>
                    <CloseModalButton
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                    />
                    <Header className="header_xs">Remove Profile Photo?</Header>
                    <p className="body_cabin_sm">
                        Are you sure you want to remove the current photo?
                    </p>
                    <ButtonContainer>
                        <CancelButton
                            onClick={() => setOpenModal(!openModal)}
                        />
                        <DeleteOrRemoveButton
                            content="Remove"
                            onClick={handleRemoveAvatar}
                        />
                    </ButtonContainer>
                </Modal>
            </Fragment>
        )
    );
};

const Modal = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    width: ${({ width }) => (width ? width : 'auto')};
    height: ${({ height }) => (height ? height : 'auto')};
    min-width: 31.5rem;
    max-height: 52rem;
    /*  center modal with fixed position: */
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translateY(-30%) translateX(-50%);

    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 32px 40px;
    z-index: ${(props) =>
        props.firstLayer
            ? props.theme.zIndex.modal
            : props.theme.zIndex.secondLayerModal};

    color: ${(props) => props.theme.colors.primary};
`;

const Header = styled.h3`
    font-size: 2.2rem;
    margin-bottom: 1.6rem;
`;

const ButtonContainer = styled.div`
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;

    @media only screen and (max-width: 767px) {
        justify-content: center;
    }

    button {
        margin: 0;

        :first-of-type {
            margin-right: 0.8rem;
        }
    }
`;

export default RemoveAvatarModal;
