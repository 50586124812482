import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import styled from '@emotion/styled';

const ClearRefinements = ({
    searchState,
    onSearchStateChange,
    setCurrentHierarchicalCategory,
}) => {
    const [isEmptySearchState, setIsEmptySearchState] = useState(true);
    useEffect(() => {
        if (searchState) {
            const isEmpty = Object.keys(searchState)?.length === 0;

            setIsEmptySearchState(isEmpty);
        }
    }, [searchState]);

    const defaultSearchState = {
        menu: { 'User Specific': 'Everyone' },
        hierarchicalMenu: { 'hierarchicalCategories.lvlAll': 'Home' },
        sortBy: 'cdr_resources_viewCounts_desc',
    };

    const handleClear = () => {
        setCurrentHierarchicalCategory('');
        onSearchStateChange(defaultSearchState);
    };

    return (
        <div className="ais-ClearRefinements">
            <HomeLink to="/">
                <button
                    className="ais-ClearRefinements-button ais-ClearRefinements-home"
                    disabled={isEmptySearchState}
                >
                    {isEmptySearchState ? '' : 'Home'}
                </button>
            </HomeLink>
            <button
                className="ais-ClearRefinements-button"
                onClick={handleClear}
                disabled={isEmptySearchState}
            >
                {isEmptySearchState ? '' : 'Clear all'}
            </button>
        </div>
    );
};

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);
export default CustomClearRefinements;

const HomeLink = styled(Link)`
    text-decoration: none;
`;
