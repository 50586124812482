import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

const ProfileDropdown = ({ logout, setOpenDropdown, openDropdown }) => {
    return (
        openDropdown && (
            <MenuContainer>
                <DropdownMenu>
                    <li>
                        <NavLink to="/profile">
                            <DropdownButton
                                onClick={() => setOpenDropdown(false)}
                            >
                                Account Settings
                            </DropdownButton>
                        </NavLink>
                    </li>
                    <li>
                        <DropdownButton onClick={logout}>Logout</DropdownButton>
                    </li>
                </DropdownMenu>
            </MenuContainer>
        )
    );
};

const MenuContainer = styled.div`
    position: absolute;
    top: 6rem;
    right: 3rem;
    width: auto;
    background-color: transparent;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: none;
`;

const DropdownMenu = styled.ul`
    background-color: white;
    box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.25);
`;

const DropdownButton = styled.button`
    width: 100%;
    padding: 2rem;
    padding-right: 6rem;
    text-align: left;
    border: none;
    background-color: ${(props) => props.theme.colors.white};
    border-bottom: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};
    cursor: pointer;

    &:hover {
        font-weight: 900;
        background-color: ${(props) => props.theme.colors.grey};
        color: ${(props) => props.theme.colors.white};
    }
    & a {
        text-decoration: none;
        color: inherit;
    }
`;

export default ProfileDropdown;
