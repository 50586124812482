import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import MenuItem from './MenuItem';
import { useGlobalContext } from 'globalStore/globalContext';
import BoardsSubMenuList from './BoardsSubMenuList';
import { useQuery } from '@apollo/client';
import { GET_BOARDS } from '../../../graphql/queries';
import MyBoardIcon from '../../../assets/images/myBoard-icon.svg';
import SavedSearchesIcon from '../../../assets/images/saved-searches-icon.svg';

const SideBar = () => {
    const { globalState } = useGlobalContext();
    const { registeredUserId } = globalState;
    const [subMenu, setSubMenu] = useState(null);
    const [showSubMenuItem, setShowSubMenuItem] = useState(false);
    const { data: boardsData } = useQuery(GET_BOARDS, {
        variables: { id: registeredUserId },
    });
    useEffect(() => {
        if (
            boardsData &&
            boardsData.registeredUser &&
            boardsData.registeredUser.boards
        ) {
            const { boards } = boardsData.registeredUser;
            const boardNamesArray = Object.keys(boards);
            setSubMenu(boardNamesArray);
        }
        // eslint-disable-next-line
    }, [boardsData]);

    const showBoardSubMenu = () => {
        setShowSubMenuItem((prev) => !prev);
    };

    return (
        <MenuList className="body_montserrat">
            <MenuItem
                showBoardSubMenu={showBoardSubMenu}
                path="/myboard/boards"
                icon={MyBoardIcon}
                title="My Boards"
                subMenu={subMenu}
                showSubMenuItem={showSubMenuItem}
            />
            <BoardsSubMenuList
                showSubMenuItem={showSubMenuItem}
                subMenu={subMenu}
            />
            <MenuItem
                path="/myboard/saved-searches"
                icon={SavedSearchesIcon}
                title="Saved Searches"
            />
        </MenuList>
    );
};

const MenuList = styled.nav`
    padding-top: 6.4rem;
    padding-bottom: 1rem;
    min-width: 25.6rem;
    width: 17.7vw;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.white};
    overflow-y: auto;

    @media only screen and (max-width: 767px) {
        width: 100vw;
    }
`;

export default SideBar;
