import { useRef, useState, useEffect } from 'react';
import getCheckState from 'functions/getCheckState';
import { capitalizeEachWord, deleteExtraSpaces } from 'functions/formatString';
import getBoardsFromCheckbox from 'functions/getBoardsFromCheckbox';
import { UPDATE_BOARDS } from 'graphql/mutation';
import { updateBoards } from 'globalStore/globalReducer';
import { useGlobalContext } from 'globalStore/globalContext';
import { useMutation } from '@apollo/client';

export default function useAddOrCreateToBoardModal() {
    const timer = useRef();
    const { globalState, dispatch } = useGlobalContext();
    const { registeredUserId, boards: oldBoards } = globalState;
    const [boards, setBoards] = useState(oldBoards);
    const [boardName, setBoardName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [checkBoxState, setCheckBoxState] = useState(null);
    const [currentResource, setCurrentResource] = useState(null);
    const [openAddToBoardModal, setOpenAddToBoardModal] = useState(false);
    const [openCreateBoardModal, setOpenCreateBoardModal] = useState(false);
    const [addResource] = useMutation(UPDATE_BOARDS);
    const [addBoard] = useMutation(UPDATE_BOARDS, {
        onCompleted: () => {
            setBoardName(null);
        },
        onError: (error) => console.error('Create board error: ', error),
    });

    const createBoard = (event) => {
        event.preventDefault();
        if (!boardName) {
            return setErrorMessage('Please enter a valid name...');
        }
        const trimmedBoardName = deleteExtraSpaces(boardName);

        if (!trimmedBoardName) {
            return setErrorMessage('Please enter a valid name...');
        }
        const formattedBoardName = capitalizeEachWord(trimmedBoardName);
        if (boards && boards[formattedBoardName]) {
            return setErrorMessage('Board name already existed...');
        }
        const newBoards = {
            ...boards,
            [formattedBoardName]: [],
        };

        const checkState = getCheckState(currentResource.id, newBoards);
        setCheckBoxState(checkState);

        addBoard({
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: registeredUserId,
                        boards: newBoards,
                        __typename: 'RegisteredUser',
                    },
                    __typename: 'updateRegisteredUserPayload',
                },
            },
            variables: {
                registeredUserId: registeredUserId,
                boards: newBoards,
            },
        });

        setBoards(newBoards);
        setOpenCreateBoardModal(false);
        setOpenAddToBoardModal((prev) => !prev);

        dispatch({
            type: updateBoards,
            payload: newBoards,
        });

        return true;
    };

    const addResourcesToBoards = (event) => {
        event.preventDefault();
        setOpenAddToBoardModal(false);
        const newBoards = getBoardsFromCheckbox(
            checkBoxState,
            boards,
            currentResource.id,
            currentResource
        );

        dispatch({
            type: updateBoards,
            payload: newBoards,
        });
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            addResource({
                variables: {
                    registeredUserId: registeredUserId,
                    boards: newBoards,
                },
                optimisticResponse: {
                    updateRegisteredUser: {
                        registeredUser: {
                            id: registeredUserId,
                            boards: newBoards,
                            __typename: 'RegisteredUser',
                        },
                        __typename: 'updateRegisteredUserPayload',
                    },
                },
            });
        }, 500);
    };

    const closeAddToBoardModal = () => {
        setOpenAddToBoardModal((prev) => !prev);
        setCheckBoxState(null);
        setCurrentResource(null);
    };

    const handleBoardNameChange = (event) => {
        setBoardName(event.target.value);
        if (errorMessage) {
            setErrorMessage(null);
        }
    };

    const cancelCreateBoard = (event) => {
        event.preventDefault();
        if (errorMessage) {
            setErrorMessage(null);
        }
        setOpenCreateBoardModal((prev) => !prev);
        setOpenAddToBoardModal((prev) => !prev);
    };

    useEffect(() => {
        if (globalState.boards) {
            setBoards(globalState.boards);
        }
        // eslint-disable-next-line
    }, [globalState]);

    return {
        openAddToBoardModal,
        setOpenAddToBoardModal,
        closeAddToBoardModal,
        handleBoardNameChange,
        cancelCreateBoard,
        addResourcesToBoards,
        createBoard,
        openCreateBoardModal,
        setOpenCreateBoardModal,
        errorMessage,
        setErrorMessage,
        boardName,
        setBoardName,
        boards,
        setBoards,
        checkBoxState,
        setCheckBoxState,
        currentResource,
        setCurrentResource,
    };
}
