const containSpace = (string) => /\s/.test(string);

const capitalFirstChar = (string) => string[0].toUpperCase() + string.substr(1);

const deleteExtraSpaces = (string) => string.replace(/ +/g, ' ').trim();

const capitalizeEachWord = (string) => {
    if (string == null || !string.length) return;
    const trimmedString = deleteExtraSpaces(string);

    if (!containSpace(trimmedString)) {
        return string[0].toUpperCase() + string.substr(1);
    }

    const upperCasedWords = trimmedString
        .split(' ')
        .map((word) => capitalFirstChar(word));

    return upperCasedWords.join(' ');
};

export { capitalizeEachWord, deleteExtraSpaces, capitalFirstChar };
