import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import Modal from 'components/SignInSignUpModal/SignInSignUpModal';
import HeroSection from 'components/LandingPage/HeroSection';
import HelpfulSection from 'components/LandingPage/HelpfulSection';
import BrowseByTopics from 'components/LandingPage/BrowseByTopics';
import AddToBoardModal from 'components/BoardModals/AddToBoardModal';
import CreateBoardModal from 'components/BoardModals/CreateBoardModal';
import PopularResources from 'components/LandingPage/PopularResources';
import useUserData from 'hooks/useUserData';
import useAddOrCreateBoardModal from 'hooks/useAddOrCreateBoardModal';
import { GET_LANDING_PAGE } from 'graphql/queries';

const LandingPage = () => {
    const location = useLocation();
    const [openResourceBlade, setOpenResourceBlade] = useState(false);
    const [isHoverOnModal, setIsHoverOnModal] = useState(false);
    const [resourceSlug, setResourceSlug] = useState(null);
    const [slugFromURL, setSlugFromURL] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [signInTab, setSignInTab] = useState(false);
    const { loadUserData } = useUserData(null);

    useEffect(() => {
        const ShowSigninModal = async () => {
            await localStorage.clear();
            const loginButton = await document.getElementById('login-button');
            loginButton.click();
        };
        if (location.state?.resetPassword) {
            ShowSigninModal();
        }
        () => {
            location.state = null;
        };
        // eslint-disable-next-line
    }, []);

    //all this is support for the modals and adding to board features
    const {
        setCheckBoxState,
        boards,
        setCurrentResource,
        setOpenAddToBoardModal,
        openAddToBoardModal,
        setOpenCreateBoardModal,
        addResourcesToBoards,
        checkBoxState,
        createBoard,
        closeAddToBoardModal,
        openCreateBoardModal,
        handleBoardNameChange,
        errorMessage,
        cancelCreateBoard,
    } = useAddOrCreateBoardModal();
    //end of modal and board support

    useEffect(() => {
        const search = new URLSearchParams(location.search);
        const slug = search.get('resource');
        if (slug && !openResourceBlade && !resourceSlug) {
            setSlugFromURL(slug);
            setOpenResourceBlade(true);
        }
        return () => {
            setSlugFromURL(null);
        };
        // eslint-disable-next-line
    }, [location]);

    // Prevent background content scrolling when hover on blade modal
    useEffect(() => {
        if (openResourceBlade && isHoverOnModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [openResourceBlade, isHoverOnModal]);

    const {
        data: landingPageData,
        loading: landingPageLoading,
        error: landingPageError,
    } = useQuery(GET_LANDING_PAGE);

    if (landingPageLoading) {
        return <p>Loading...</p>;
    }
    if (landingPageError) {
        console.error(landingPageError);
        return <pre>Error...</pre>;
    }

    const handleChange = (modalValue, signInValue) => {
        setOpenModal(!modalValue);
        setSignInTab(!signInValue);
    };
    const setBothSignUp = () => {
        setOpenModal(!openModal);
        setSignInTab(false);
    };

    const { heroSection, helpfulSection } = landingPageData?.landingPage;

    return (
        <LandingPageWrapper>
            <HeroSection {...heroSection} setBothSignUp={setBothSignUp} />
            <BrowseByTopics />
            <PopularResources
                openResourceBlade={openResourceBlade}
                setOpenResourceBlade={setOpenResourceBlade}
                setIsHoverOnModal={setIsHoverOnModal}
                slugFromURL={slugFromURL}
                setSlugFromURL={setSlugFromURL}
                resourceSlug={resourceSlug}
                setResourceSlug={setResourceSlug}
                setCheckBoxState={setCheckBoxState}
                boards={boards}
                setCurrentResource={setCurrentResource}
                setOpenAddToBoardModal={setOpenAddToBoardModal}
            />
            <HelpfulSection
                {...helpfulSection}
                openResourceBlade={openResourceBlade}
                setOpenResourceBlade={setOpenResourceBlade}
                setIsHoverOnModal={setIsHoverOnModal}
                slugFromURL={slugFromURL}
                setSlugFromURL={setSlugFromURL}
                resourceSlug={resourceSlug}
                setResourceSlug={setResourceSlug}
                setCheckBoxState={setCheckBoxState}
                boards={boards}
                setCurrentResource={setCurrentResource}
                setOpenAddToBoardModal={setOpenAddToBoardModal}
            />
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
                signInTab={signInTab}
                onChange={handleChange}
                loadUserData={loadUserData}
            />
            <AddToBoardModal
                openAddToBoardModal={openAddToBoardModal}
                setOpenAddToBoardModal={setOpenAddToBoardModal}
                setOpenCreateBoardModal={setOpenCreateBoardModal}
                addResourcesToBoards={addResourcesToBoards}
                checkBoxState={checkBoxState}
                setCheckBoxState={setCheckBoxState}
                createBoard={createBoard}
                closeAddToBoardModal={closeAddToBoardModal}
            />
            <CreateBoardModal
                openCreateBoardModal={openCreateBoardModal}
                setOpenCreateBoardModal={setOpenCreateBoardModal}
                handleBoardNameChange={handleBoardNameChange}
                createBoard={createBoard}
                firstLayer={false}
                errorMessage={errorMessage}
                handleCancel={cancelCreateBoard}
            />
        </LandingPageWrapper>
    );
};

const LandingPageWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.light_grey};
`;

export default LandingPage;
