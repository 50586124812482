import findResource from './findResource';

const getCheckState = (id, oldBoards) => {
    if (!oldBoards) return;
    const checkBoxState = {};

    for (const [key, value] of Object.entries(oldBoards)) {
        checkBoxState[key] = findResource(id, value);
    }

    return checkBoxState;
};

export default getCheckState;
