import React from 'react';
import styled from '@emotion/styled';
import SvgIcon from '../SvgIcon';
import { useTheme } from '@emotion/react';

const WithIconButton = ({ buttonText, onClick, width, icon }) => {
    const theme = useTheme();
    return (
        <ButtonWrapper onClick={onClick} width={width}>
            <SvgIcon
                icon={icon}
                bgColor={theme.colors.primary}
                marginRight={`0.4rem`}
                alt={buttonText}
            />
            <span className="header_cabin_sm">{buttonText}</span>
        </ButtonWrapper>
    );
};

const ButtonWrapper = styled.button`
    border: none;
    border-radius: 5rem;
    width: auto;
    height: 4rem;
    color: ${(props) => props.theme.colors.primary};
    padding: 0.7rem 4rem 0.9rem 4rem;
    background-color: ${(props) => props.theme.colors.tertiary};
    display: flex;
    align-items: center;

    :hover {
        background-color: ${(props) => props.theme.colors.tertiary_dark};
    }
`;

export default WithIconButton;
