import React from 'react';
import { connectStats } from 'react-instantsearch-core';

const Stats = ({ nbHits }) => {
    return <p className="header_cabin_sm">{nbHits} Results</p>;
};

const CustomStats = connectStats(Stats);

export default CustomStats;
