import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Section from '../../../assets/styledComponents/Section';
import AdvancedFiltersModal from './AdvancedFiltersModal';
import MobileAdvancedFilterModal from './MobileAdvancedFilterModal';
import SearchBarArea from './SearchBarArea';
import { useGlobalContext } from '../../../globalStore/globalContext';

const SearchBarSection = ({
    openDropdown,
    showDropdown,
    setShowDropdown,
    openNameSavedSearchModal,
    setOpenNameSavedSearchModal,
    searchState,
    currentHierarchicalCategory,
}) => {
    const [openMobileModal, setOpenMobileModal] = useState(false);
    const advancedButton = document.getElementById('advanced-button');
    const { globalState } = useGlobalContext();
    const { isMobile } = globalState;

    useEffect(() => {
        // Prevent background content scrolling when open filter modal on Mobile
        if (openMobileModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        // eslint-disable-next-line
    }, [openMobileModal]);

    return (
        <Container>
            <SearchBarArea
                openDropdown={openDropdown}
                showDropdown={showDropdown}
                searchState={searchState}
                currentHierarchicalCategory={currentHierarchicalCategory}
                setOpenMobileModal={setOpenMobileModal}
                openMobileModal={openMobileModal}
            />
            {!isMobile ? (
                <AdvancedFiltersModal
                    showDropdown={showDropdown}
                    setShowDropdown={setShowDropdown}
                    openNameSavedSearchModal={openNameSavedSearchModal}
                    setOpenNameSavedSearchModal={setOpenNameSavedSearchModal}
                    advancedButton={advancedButton}
                    searchState={searchState}
                />
            ) : (
                <MobileAdvancedFilterModal
                    openMobileModal={openMobileModal}
                    setOpenMobileModal={setOpenMobileModal}
                    openNameSavedSearchModal={openNameSavedSearchModal}
                    setOpenNameSavedSearchModal={setOpenNameSavedSearchModal}
                    searchState={searchState}
                />
            )}
        </Container>
    );
};

const Container = styled(Section)`
    margin: 0;
    margin-bottom: 2.4rem;
    position: relative;

    @media screen and (max-width: 767px) {
        margin-bottom: 1.2rem;
    }
`;

export default SearchBarSection;
