import React, { Fragment, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import CenteredModal from 'assets/styledComponents/CenteredModal';
import CloseModalButton from 'assets/styledComponents/CloseModalButton';
import ModalOverlay from 'assets/styledComponents/ModalOverlay';
import OvalButton from 'assets/styledComponents/OvalButton';
import CreateBoardButton from './CreateBoardButton';
import { useGlobalContext } from 'globalStore/globalContext';

const AddToBoardModal = ({
    openAddToBoardModal,
    setOpenAddToBoardModal,
    setOpenCreateBoardModal,
    addResourcesToBoards,
    checkBoxState,
    setCheckBoxState,
    closeAddToBoardModal,
}) => {
    const { globalState } = useGlobalContext();
    const { isMobile, boards } = globalState;
    const [hasBoards, setHasBoards] = useState(false);
    useEffect(() => {
        setHasBoards(boards && Object.keys(boards)?.length > 0);
    }, [boards]);
    const handleCreateBoard = (event) => {
        event.preventDefault();
        setOpenCreateBoardModal((prev) => !prev);
        setOpenAddToBoardModal((prev) => !prev);
    };

    const handleCheckBoxChange = (event) => {
        const { name, checked } = event.target;
        setCheckBoxState((prev) => ({ ...prev, [name]: checked }));
    };

    const CheckBoxList = () => {
        if (!hasBoards) {
            return <Paragraph>You don&apos;t have any boards yet</Paragraph>;
        }
        return Object.keys(boards).map((name) => (
            <CheckboxWrapper key={name}>
                <Label htmlFor={name} className="checkbox-label">
                    <CheckBox
                        type="checkbox"
                        id={name}
                        checked={checkBoxState[name]}
                        name={name}
                        onChange={handleCheckBoxChange}
                    />
                    {name}
                </Label>
            </CheckboxWrapper>
        ));
    };

    return (
        openAddToBoardModal && (
            <Fragment>
                <ModalOverlay
                    openModal={openAddToBoardModal}
                    setOpenModal={setOpenAddToBoardModal}
                    firstLayer={false}
                    closeModal={closeAddToBoardModal}
                />
                <CenteredModal
                    isMobile={isMobile}
                    width={isMobile ? `37.7vw` : `55rem`}
                    firstLayer={false}
                >
                    <CloseModalButton
                        openModal={openAddToBoardModal}
                        setOpenModal={setOpenAddToBoardModal}
                        closeModal={closeAddToBoardModal}
                    />
                    <Header className="header_xs">Add to Board</Header>
                    <Content>
                        <StyledForm
                            className="checkbox-form"
                            onSubmit={addResourcesToBoards}
                        >
                            {checkBoxState && (
                                <ListWrapper hasBoards={hasBoards}>
                                    <CheckBoxList />
                                </ListWrapper>
                            )}
                            <hr />
                            <CreateBoardButton
                                handleClick={handleCreateBoard}
                                width={`100%`}
                                justifyContent={`center`}
                                title="Create Board"
                                isMobile={isMobile}
                            />
                            <ButtonsContainer>
                                <Button onClick={closeAddToBoardModal}>
                                    Cancel
                                </Button>
                                <AddButton
                                    type="submit"
                                    disabled={!checkBoxState}
                                >
                                    Done
                                </AddButton>
                            </ButtonsContainer>
                        </StyledForm>
                    </Content>
                </CenteredModal>
            </Fragment>
        )
    );
};

const ListWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.light_grey};
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    padding-top: 1.6rem;
    max-height: ${(props) => (props.hasBoards ? `23.2rem` : `auto`)};
    overflow-y: auto;

    > div:last-of-type {
        margin-bottom: 0.8rem;
    }
`;

const Content = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Paragraph = styled.p`
    margin: 0;
    text-align: center;
    margin: 2.4rem 0 4rem 0;
    color: ${(props) => props.theme.colors.primary};
    font-family: 'CabinRegular400', sans-serif;
`;

const StyledForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    > hr {
        margin: 0;
        height: 1px;
        background-color: ${(props) => props.theme.colors.grey};
    }
`;

const Header = styled.h3`
    font-size: 2.2rem;
    margin-bottom: 2.4rem;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.8rem 0;
    :hover {
        background-color: ${(props) => props.theme.colors.grey};
    }
`;

const Label = styled.label`
    width: 100%;
    padding-left: 2.4rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
    font-family: 'CabinRegular400', sans-serif;
`;

const CheckBox = styled.input`
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: 0;
    margin-right: 1.5rem;
    border: 0.2rem solid #c0c0c0;
    cursor: pointer;
    border: ${(props) => `0.2rem solid ${props.theme.colors.grey}`};
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const Button = styled(OvalButton)`
    font-family: 'CabinSemibold600', sans-serif;
    width: 12rem;
    margin-left: 2rem;
`;

const AddButton = styled(Button)`
    border: none;
    background-color: ${(props) => props.theme.colors.tertiary};
    color: ${(props) => props.theme.colors.primary};

    &:disabled {
        color: ${(props) => props.theme.colors.darker_grey};
        background-color: ${(props) => props.theme.colors.light_grey};
    }
`;

export default AddToBoardModal;
