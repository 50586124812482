import styled from '@emotion/styled';

const SvgIcon = styled.svg`
    width: ${({ width }) => width ?? `2.4rem`};
    height: ${({ height }) => height ?? `2.4rem`};
    margin-right: ${({ marginRight }) => marginRight ?? `0`};
    margin-left: ${({ marginLeft }) => marginLeft ?? `0`};
    padding: 0;
    background-color: ${({ bgColor, theme }) =>
        bgColor ? bgColor : theme.colors.primary};
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-image: ${({ icon }) => `url(${icon})`};

    :hover {
        background-color: ${({ theme }) => theme.colors.secondary};
    }
`;

export default SvgIcon;
