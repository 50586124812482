import styled from '@emotion/styled';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoWhite from '../../assets/images/logos/cdr-logo-white.svg';
import Logo from '../../assets/images/logos/cdr-logo.svg';
import useUserData from '../../hooks/useUserData';
import NavbarHamburger from './MobileNavbar/HamburgerMenu';
import MobileNavbar from './MobileNavbar/MobileNavbar';
import NavLinks from './NavLinks';
import { useGlobalContext } from '../../globalStore/globalContext';

const NavBar = () => {
    const token = localStorage.getItem('token');
    const [isNavBarWhite, setIsNavBarWhite] = useState(false);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const [isBladeOpen, setIsBladeOpen] = useState(false);
    const { loadUserData } = useUserData(null);
    const { globalState } = useGlobalContext();

    const handleClick = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    useEffect(() => {
        if (token) {
            const { id } = jwtDecode(token);
            loadUserData(id);
        }
        // eslint-disable-next-line
    }, [token]);

    let location = useLocation();

    // scroll to the top on changing location
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsMobileNavOpen(false);
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname === '/' && location.search) {
            setIsBladeOpen(true);
        }

        if (location.pathname === '/' && !location.search) {
            setIsBladeOpen(false);
        }
    }, [location]);

    useEffect(() => {
        if (isBladeOpen || window.scrollY > 0) {
            setIsNavBarWhite(true);
        }
    }, [isBladeOpen]);

    // Navbar only changes background color on scroll when users on the landing page.
    useEffect(() => {
        if (location.pathname === '/') {
            window.addEventListener('scroll', changeNavBarBackgroundColor);
        }
        return () => {
            window.removeEventListener('scroll', changeNavBarBackgroundColor);
        };
    }, [location]);

    // change Navbar background color on scroll
    const changeNavBarBackgroundColor = () => {
        if (window.scrollY > 0) {
            setIsNavBarWhite(true);
        } else {
            setIsNavBarWhite(false);
        }
    };
    // Navbar background color white when user is not on the landing page
    useEffect(() => {
        if (
            location.pathname !== '/' ||
            location.search ||
            window.scrollY > 0
        ) {
            setIsNavBarWhite(true);
        } else {
            setIsNavBarWhite(false);
        }
    }, [location]);

    if (!globalState.isMobile) {
        return (
            <NavContainer
                isLandingPage={location.pathname === '/'}
                navBarColor={isNavBarWhite}
            >
                <LogoWrap
                    to={'/'}
                    // onClick={window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                    {isNavBarWhite ? (
                        <img src={Logo} alt="site logo" />
                    ) : (
                        <img src={LogoWhite} alt="white site logo" />
                    )}
                </LogoWrap>
                <NavLinks
                    token={token}
                    loadUserData={loadUserData}
                    isNavBarWhite={isNavBarWhite}
                />
            </NavContainer>
        );
    } else {
        return (
            <MobileNavContainer navBarColor={isNavBarWhite}>
                <LogoWrap
                    to={'/'}
                    // onClick={window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                    {isNavBarWhite ? (
                        <img src={Logo} alt="site logo" />
                    ) : (
                        <img src={LogoWhite} alt="white site logo" />
                    )}
                </LogoWrap>
                <NavbarHamburger
                    handleClick={handleClick}
                    isMobileNavOpen={isMobileNavOpen}
                    isNavBarWhite={isNavBarWhite}
                />
                {isMobileNavOpen && (
                    <MobileNavbar
                        isMobileNavOpen={isMobileNavOpen}
                        token={token}
                        loadUserData={loadUserData}
                        isNavBarWhite={isNavBarWhite}
                    />
                )}
            </MobileNavContainer>
        );
    }
};

const NavContainer = styled.nav`
    color: ${(props) => props.theme.colors.secondary};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: ${(props) => props.theme.spaces.navbarHeight};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8rem;
    z-index: ${(props) => props.theme.zIndex.navbar};
    background-color: ${(props) =>
        props.navBarColor ? props.theme.colors.white : 'transparent'};
    box-shadow: ${(props) =>
        props.navBarColor ? '0rem .4rem .6rem rgba(0, 0, 0, 0.1)' : 'none'};

    > a {
        height: min-content;
    }
`;

const MobileNavContainer = styled(NavContainer)`
    padding: 1rem;

    img {
        width: 80%;
    }
    > a {
        height: min-content;
    }
    > div {
        align-self: center;
    }
`;

const LogoWrap = styled(Link)`
    position: relative;
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
`;

export default NavBar;
