// Quick fix to hide the string "(e.g. Family Support Resource Centre)" or
// "(e.g. Healthlink BC, Decision Support Tools)" from the currentHierarchicalCategory string, if applicable.
// These category labels were set when indexing data on Algolia
// by importing the resources CSV file using the backend script file (import-cli.ts).
// Thought updating all the resources on Algolia again is challenging.
const formatCategoryLabel = (currentHierarchicalCategory) => {
    let categoryLabel = currentHierarchicalCategory;
    const textToHide1 = '(e.g. Family Support Resource Centre)';
    const textToHide2 = '(e.g. Healthlink BC, decision Support Tools)';

    if (currentHierarchicalCategory.includes(textToHide1)) {
        categoryLabel = currentHierarchicalCategory.replace(textToHide1, '');
    } else if (currentHierarchicalCategory.includes(textToHide2)) {
        categoryLabel = currentHierarchicalCategory.replace(textToHide2, '');
    }

    return categoryLabel;
};

export default formatCategoryLabel;
