import styled from '@emotion/styled';
import Section from './Section';

const BladeContentContainer = styled(Section)`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6.4rem;
    :last-of-type {
        margin-bottom: 0;
    }
`;

export default BladeContentContainer;
