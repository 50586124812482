import algoliasearch from 'algoliasearch';

// algolia settings
const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
export const apiKey = process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY;
export const SEARCH_CLIENT = algoliasearch(appId, apiKey);
export const INDEX_NAME = 'cdr_resources';

// For: SortBy(replica indices names from algolia)
export const LAST_UPDATE_DESC = 'cdr_resource_last_update_desc';
export const VIEW_COUNTS_DESC = 'cdr_resources_viewCounts_desc';

// For: FilterByTagsArea
export const HIERARCHICAL_LVLALL = 'hierarchicalCategories.lvlAll';
export const HIERARCHICAL_LVL1 = 'hierarchicalCategories.lvl1';
export const HIERARCHICAL_LVL2 = 'hierarchicalCategories.lvl2';
export const HIERARCHICAL_LVL3 = 'hierarchicalCategories.lvl3';
export const HIERARCHICAL_LVL4 = 'hierarchicalCategories.lvl4';

// For: CustomHierarchicalMenu, Breadcrumb
export const DEFAULT_REFINEMENT = 'Home';

export const HIERARCHICAL_ATTRIBUTES = [
    HIERARCHICAL_LVLALL,
    HIERARCHICAL_LVL1,
    HIERARCHICAL_LVL2,
    HIERARCHICAL_LVL3,
    HIERARCHICAL_LVL4,
];

// Advanced filters
export const REFINEMENTS_LIST_ATTRIBUTE_TA = 'Target Audience';
export const REFINEMENTS_LIST_ATTRIBUTE_HC = 'Health Condition';
export const REFINEMENTS_LIST_ATTRIBUTE_AOF = 'Area of Focus';
export const REFINEMENTS_LIST_ATTRIBUTE_AGE = 'Age';
export const LIMIT_TA = 15;
export const LIMIT_HC = 14;
export const LIMIT_AOF = 18;
export const LIMIT_AGE = 5;
export const INITIAL_FACETS_TA = [];
export const INITIAL_FACETS_HC = [];
export const INITIAL_FACETS_AOF = [];
export const INITIAL_FACETS_AGE = [];

// Get all filter list items and cache it
SEARCH_CLIENT.searchForFacetValues([
    {
        indexName: INDEX_NAME,
        params: {
            facetName: REFINEMENTS_LIST_ATTRIBUTE_TA,
            facetQuery: '',
            maxFacetHits: LIMIT_TA,
        },
    },
    {
        indexName: INDEX_NAME,
        params: {
            facetName: REFINEMENTS_LIST_ATTRIBUTE_HC,
            facetQuery: '',
            maxFacetHits: LIMIT_HC,
        },
    },
    {
        indexName: INDEX_NAME,
        params: {
            facetName: REFINEMENTS_LIST_ATTRIBUTE_AOF,
            facetQuery: '',
            maxFacetHits: LIMIT_AOF,
        },
    },
    {
        indexName: INDEX_NAME,
        params: {
            facetName: REFINEMENTS_LIST_ATTRIBUTE_AGE,
            facetQuery: '',
            maxFacetHits: LIMIT_AGE,
        },
    },
]).then((res) => {
    const [TA, HC, AOF, AGE] = res;
    INITIAL_FACETS_TA.push(
        ...TA.facetHits.map((facet) => ({
            ...facet,
            label: facet.value,
            value: facet.value,
            isRefined: false,
            count: 0,
        }))
    );
    INITIAL_FACETS_HC.push(
        ...HC.facetHits.map((facet) => ({
            ...facet,
            label: facet.value,
            value: facet.value,
            isRefined: false,
            count: 0,
        }))
    );
    INITIAL_FACETS_AOF.push(
        ...AOF.facetHits.map((facet) => ({
            ...facet,
            label: facet.value,
            value: facet.value,
            isRefined: false,
            count: 0,
        }))
    );
    INITIAL_FACETS_AGE.push(
        ...AGE.facetHits.map((facet) => ({
            ...facet,
            label: facet.value,
            value: facet.value,
            isRefined: false,
            count: 0,
        }))
    );
});

// function for combining initial refinement list items and items return from algolia
export const uniqBy = (items, property) => {
    const seen = {};

    return items.filter((item) => {
        const val = item[property];

        if (seen[val]) {
            return false;
        } else {
            seen[val] = true;
            return true;
        }
    });
};
