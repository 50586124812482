import styled from '@emotion/styled';
import React from 'react';

const DeleteOrRemoveButton = ({ onClick, content, large, width, disabled }) => {
    return (
        <StyledDeleteOrRemoveButton
            onClick={onClick}
            large={large}
            width={width}
            disabled={disabled}
        >
            {content}
        </StyledDeleteOrRemoveButton>
    );
};

const StyledDeleteOrRemoveButton = styled.button`
    border: none;
    border-radius: 5rem;
    width: ${({ width }) => (width ? width : `auto`)};
    height: ${({ large }) => (large ? `4.8rem` : `4rem`)};
    color: ${(props) => props.theme.colors.white};
    padding: 1.2rem 4rem;
    background-color: ${(props) => props.theme.colors.extra};
    display: flex;
    align-items: center;

    :hover {
        background-color: ${(props) => props.theme.colors.extra_dark};
    }

    &:disabled {
        border: ${(props) => `0.1rem solid ${props.theme.colors.dark_grey}`};
        color: ${(props) => props.theme.colors.darker_grey};
        background-color: ${(props) => props.theme.colors.light_grey};
    }
`;

export default DeleteOrRemoveButton;
