import React from 'react';
import styled from '@emotion/styled';
import ExternalLinkIcon from 'assets/images/external-link-icon.svg';
import WithIconButton from '../../../assets/styledComponents/Buttons/WithIconButton';

const ViewSourceButton = ({ url }) => {
    return (
        <Link href={url} rel="noopener noreferrer" target="_blank">
            <WithIconButton
                buttonText="Go to Resource"
                icon={ExternalLinkIcon}
            />
        </Link>
    );
};
const Link = styled.a`
    text-decoration: none;
    margin-top: 4.8rem;
`;
export default ViewSourceButton;
