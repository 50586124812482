import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGlobalContext } from 'globalStore/globalContext';
import IconButton from 'assets/styledComponents/IconButton';
import getCheckState from 'functions/getCheckState';
import Add from 'assets/images/add-from-card-icon.svg';
import BlueAdd from 'assets/images/blue-add-to-board-icon.svg';
import CDR from 'assets/images/CDR_placeholder.svg';
import Delete from 'assets/images/card-delete-icon.svg';
import resourcesSet from 'functions/resourcesSet';
import { createURL } from 'functions/algoliaSearchToUrl';

const ResourceCard = ({
    resource,
    hit,
    handleClick,
    setCurrentResource,
    setOpenAddToBoardModal,
    setCheckBoxState,
    boards,
    resourceSet,
    setOpenDeleteModal,
    searchState,
}) => {
    const history = useHistory();
    const location = useLocation();
    const path = location.pathname;
    const id = hit ? hit.id.toString() : resource.id;
    const title = hit ? hit.title : resource.title;
    const slug = hit ? hit.slug : resource.slug;
    const image = hit
        ? hit.thumbnail
        : resource.image
        ? resource.image.url
            ? resource.image.url
            : resource.image
        : null;
    const author = hit ? hit.author : resource.author;
    const description = hit ? hit.description : resource.description;
    const type = hit
        ? hit['Resource Type']
        : resource.resource_type
        ? resource.resource_type
        : resource.type;
    const updatedAt = hit
        ? hit.date_of_last_update
        : resource.date_of_last_update;
    const view = hit
        ? hit['User Specific']
        : resource.user_specifics?.map((user) => user.user);
    const [isResourceAdded, setIsResourceAdded] = useState(false);
    const { globalState } = useGlobalContext();
    const handleDelete = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setCheckBoxState(getCheckState(id, boards));
        setCurrentResource({
            id: id.toString(),
            title,
            author,
            description,
            image,
            slug,
            updatedAt,
            type,
            view,
        });
        setOpenDeleteModal(true);
    };

    useEffect(() => {
        if (boards) {
            if (path === '/search') {
                setIsResourceAdded(resourceSet?.has(Number(id)));
            } else {
                setIsResourceAdded(resourcesSet(boards)?.has(Number(id)));
            }
        }
        //eslint-disable-next-line
    }, [boards]);

    const handleAddToBoard = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (globalState.registeredUserId) {
            setOpenAddToBoardModal(true);
            setCheckBoxState(getCheckState(id, boards));
            setCurrentResource({
                id: id.toString(),
                title,
                author,
                description,
                image,
                slug,
                updatedAt,
                type,
                view,
            });
        } else {
            // redirect to sign in modal if user's not logged in
            // const currentPath = history.location.pathname;
            history.push({
                search: searchState ? createURL(searchState) : '',
                state: { requireLogin: true },
            });

            const isMobile = globalState.isMobile;

            if (isMobile) {
                await document.getElementById('mobile-navbar').click();
                document.getElementById('login-button').click();
            } else {
                document.getElementById('login-button').click();
            }
        }
    };

    return (
        <CardContainer onClick={(event) => handleClick(event, slug)}>
            {image ? (
                <CoverImage src={image} alt={title} />
            ) : (
                <CoverPlaceholderImage src={CDR} alt={title} />
            )}

            <CardContent>
                <h3 className="header_xs">{title}</h3>
                <AuthorAndDate className="header_cabin_sm">
                    <AuthorSpan>{author}</AuthorSpan>
                </AuthorAndDate>
                <Description
                    className="body_cabin_sm"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                <Footer>
                    <Type className="header_cabin_sm">
                        {type?.split('_').join(' ')}
                    </Type>
                    <ButtonsWrap>
                        <AddButton
                            icon={isResourceAdded ? BlueAdd : Add}
                            onClick={handleAddToBoard}
                        />
                        {path.includes('myboard') && (
                            <Button icon={Delete} onClick={handleDelete} />
                        )}
                    </ButtonsWrap>
                </Footer>
            </CardContent>
        </CardContainer>
    );
};

const CardContainer = styled.div`
    width: 100%;
    height: auto;
    min-height: 47.2rem;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0.2rem 1.5rem 0 rgba(78, 78, 78, 0.2);
    }

    @media only screen and (max-width: 767px) {
        min-height: unset;
    }
`;

const CoverImage = styled.img`
    max-width: 100%;
    height: 11.2rem;
    border-radius: 1rem 1rem 0 0;
    object-fit: contain;
`;

const CoverPlaceholderImage = styled.img`
    max-width: 100%;
    height: 11.2rem;
    border-radius: 1rem 1rem 0 0;
    object-fit: cover;
`;

const CardContent = styled.div`
    padding: 1.6rem 2.4rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    & h3 {
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* max 3 lines */
        -webkit-box-orient: vertical;
        text-align: left;
        margin-bottom: 0.8rem;
    }
`;

const AuthorAndDate = styled.div`
    color: ${(props) => props.theme.colors.secondary};
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    margin-bottom: 0.8rem;
`;

const AuthorSpan = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 35rem;
`;

const Description = styled.div`
    color: ${(props) => props.theme.colors.darker_grey};
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 1.6rem;

    p {
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
    }
`;

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
`;

const Type = styled.div`
    color: ${(props) => props.theme.colors.secondary};
`;

const ButtonsWrap = styled.div`
    display: flex;
    align-items: center;
`;

const Button = styled(IconButton)`
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
    margin: 0 0 0 1rem;

    svg {
        width: 100%;
        height: 100%;
    }
    &:hover {
        transition: background-image 0.2s ease-in-out;
        transform: scale(1.1);
    }
`;

const AddButton = styled(Button)`
    &:hover {
        background-image: url(${BlueAdd});
    }
`;
export default ResourceCard;
