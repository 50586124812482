import React from 'react';
import { connectMenu } from 'react-instantsearch-dom';
import styled from '@emotion/styled';

const Menu = ({
    items,
    currentRefinement,
    refine,
    categoriesLabelFromStrapi,
    setOpenModal,
}) => {
    /*
    "alphabeticallySortedMenuItemsFromStrapi" is an array, used when there's no result.
    as the algolia's default behavior, it will show only categories
    which is relevant to the result.
    so, when there's no result, no menu items are displayed.
    (the issue mentioned in: https://discourse.algolia.com/t/keep-all-filters-even-if-no-results/493)
    to prevent that, we fetch the categories from strapi
    and store the data in this variable,
    while sorting the categories with the category name (alphabetical order)
    Possible alternative: https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/react/#display-facets-with-no-matches
  */

    const alphabeticallySortedMenuItemsFromStrapi =
        categoriesLabelFromStrapi.sort(function (a, b) {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
            return 0;
        });

    const handleDisabled = (item) => {
        if (items.some((i) => i.label === item && i.count > 0)) {
            return false;
        }
        return true;
    };

    return (
        <DropdownList className="ais-Menu-list">
            {alphabeticallySortedMenuItemsFromStrapi?.map((item) => {
                return (
                    <DropdownItem key={item.value} className="ais-Menu-item">
                        <StrippedButton
                            className={'ais-Menu-link header_cabin_sm'}
                            disabled={handleDisabled(item.value)}
                            onClick={(event) => {
                                event.preventDefault();
                                setOpenModal((prev) => !prev);
                                // show the result in the category clicked
                                refine(item.value);
                            }}
                        >
                            <ButtonText
                                className="ais-Menu-label header_cabin_sm"
                                selected={Boolean(
                                    currentRefinement === item.value
                                )}
                                disabled={handleDisabled(item.value)}
                            >
                                For {item.value}
                            </ButtonText>
                        </StrippedButton>
                    </DropdownItem>
                );
            })}
        </DropdownList>
    );
};

const CustomMenu = connectMenu(Menu);

export default CustomMenu;

const DropdownList = styled.ul`
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.black};
    z-index: 10;
`;

const DropdownItem = styled.li`
    width: 100%;
`;

const StrippedButton = styled.button`
    padding: 0.4rem 3.2rem;
    border: none;
    width: 100%;
    background: transparent;
    cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
    text-align: left;

    &:hover {
        background-color: ${(props) => props.theme.colors.light_grey};
        > span {
            color: ${(props) => props.theme.colors.secondary};
        }
    }

    @media only screen and (max-width: 767px) {
        padding: 0.4rem 2.4rem;
    }
`;

const ButtonText = styled.span`
    color: ${(props) =>
        props.disabled ? props.theme.colors.grey : props.theme.colors.primary};
    width: 100%;
    min-width: fit-content;
    color: ${(props) =>
        props.selected
            ? props.theme.colors.secondary
            : props.theme.colors.primary};
`;
