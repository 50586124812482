import { useApolloClient } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import theme from '../../assets/styles/theme';
import { useGlobalContext } from '../../globalStore/globalContext';
import { clearGlobalState } from '../../globalStore/globalReducer';
import displayUserAvatar from '../../functions/displayUserAvatar';
import ProfileDropdown from './ProfileDropdown';
import SignInSignUpModal from '../SignInSignUpModal/SignInSignUpModal';

const NavLinks = ({ token, loadUserData, isNavBarWhite }) => {
    const history = useHistory();
    const client = useApolloClient();
    const { globalState, dispatch } = useGlobalContext();
    const { userInfo } = globalState;
    const [openModal, setOpenModal] = useState(false);
    const [signInTab, setSignInTab] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);

    const logout = async () => {
        setOpenDropdown(false);
        localStorage.removeItem('token');
        // Clear global state
        dispatch({ type: clearGlobalState });
        // clearing apollo cache
        await client.resetStore();
    };

    const setBothLogin = () => {
        setOpenModal(!openModal);
        setSignInTab(true);
    };

    const setBothSignUp = () => {
        setOpenModal(!openModal);
        setSignInTab(false);
    };

    const handleChange = (modalValue, signInValue) => {
        setOpenModal(!modalValue);
        setSignInTab(!signInValue);
    };
    // open user profile dropdown menu
    const onMouseLeave = () => {
        setOpenDropdown(false);
    };

    return (
        <NavListContainer
            isLandingPage={history.location.pathname === '/'}
            isMobile={globalState.isMobile}
        >
            {!token && (
                <>
                    <LoginButton
                        id="login-button"
                        onClick={() => setBothLogin()}
                        loadUserData={loadUserData}
                        isNavBarWhite={isNavBarWhite}
                    >
                        Log in
                    </LoginButton>
                    <SignUpButton
                        id="signup-button"
                        onClick={() => setBothSignUp()}
                    >
                        Get Started
                    </SignUpButton>
                </>
            )}
            {token && !globalState.isMobile && (
                <StuffWrapper isLandingPage={history.location.pathname === '/'}>
                    {/*
                        Fix Error: React does not recognize the `isNavBarWhite` prop on a DOM element.
                            - props name should be lower cases, i.e. isnavbarwhite
                            - props value should be 'string', i.e. isNavBarWhite.toString()
                     */}
                    <NavLinkItem
                        to="/myboard"
                        isnavbarwhite={isNavBarWhite.toString()}
                    >
                        My Board
                    </NavLinkItem>
                    <li
                        onMouseLeave={onMouseLeave}
                        openDropdown={openDropdown}
                        setOpenDropdown={setOpenDropdown}
                    >
                        <Avatar
                            src={displayUserAvatar(userInfo)}
                            onClick={() => setOpenDropdown(!openDropdown)}
                            alt="user avatar"
                        />
                        <ProfileDropdown
                            logout={logout}
                            openDropdown={openDropdown}
                            setOpenDropdown={setOpenDropdown}
                        />
                    </li>
                </StuffWrapper>
            )}
            {token && globalState.isMobile && (
                <MobileWrapper>
                    <Avatar
                        src={displayUserAvatar(userInfo)}
                        alt="user avatar"
                    />
                    <NavLinkItem
                        to="/myboard"
                        isnavbarwhite={isNavBarWhite.toString()}
                    >
                        My Board
                    </NavLinkItem>
                    <NavLinkItem
                        to="/profile"
                        onClick={() => setOpenDropdown(false)}
                    >
                        Account Settings
                    </NavLinkItem>
                    <LoginButton onClick={logout}>Log out</LoginButton>
                </MobileWrapper>
            )}
            <SignInSignUpModal
                token={token}
                openModal={openModal}
                setOpenModal={setOpenModal}
                signInTab={signInTab}
                onChange={handleChange}
                loadUserData={loadUserData}
            />
        </NavListContainer>
    );
};

const StuffWrapper = styled.div`
    display: flex;
    align-items: center;

    > li {
        margin-left: 4rem;
    }
`;

const MobileWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 4.5rem;
        height: 4.5rem;
    }
    a {
        padding: 0;
        margin: 1rem 0;
    }
    button {
        margin: 1rem 0 0 0;
    }
`;

const NavListContainer = styled.ul`
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.15rem;
    margin: 0;

    > button {
        height: 4.8rem;
    }

    ${(props) =>
        props.isMobile &&
        `
    flex-direction: column;
    margin-top: 5rem;
    align-items: start;
    width: 100%;
    button,a {
        margin-left: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: 0rem;
    }
    `}
`;

const NavLinkItem = styled(NavLink)`
    font-family: 'CabinSemibold600';
    position: relative;
    text-decoration: none;
    color: ${(props) =>
        props.isnavbarwhite === 'true'
            ? theme.colors.secondary
            : theme.colors.white};
    transition: all 0.05s ease-in-out;
    height: min-content;
    &:hover {
        transform: scale(1.02);
        font-weight: 900;
    }
    &.active {
        border-bottom: 0.4rem solid
            ${(props) =>
                props.isnavbarwhite === 'true'
                    ? theme.colors.secondary
                    : theme.colors.white};
    }
`;

const LoginButton = styled.button`
    background: rgba(255, 255, 255, 0.3);
    border: 0.15rem solid
        ${(props) =>
            props.isNavBarWhite ? theme.colors.primary : theme.colors.white};
    border-radius: 5rem;
    padding: 1.2rem 4rem;
    color: ${(props) =>
        props.isNavBarWhite ? theme.colors.primary : theme.colors.white};
    cursor: pointer;
    font-family: 'CabinSemibold600';
    font-size: 1.6rem;
    &:hover {
        transform: scale(1.02);
        font-weight: 900;
    }
`;

const SignUpButton = styled.button`
    background: ${theme.colors.tertiary};
    border: none;
    padding: 1.2rem 4rem;
    border-radius: 5rem;
    margin-left: 1.6rem;
    color: ${theme.colors.black};
    cursor: pointer;
    font-family: 'CabinSemibold600';
    font-size: 1.6rem;
    &:hover {
        transform: scale(1.02);
        font-weight: 900;
    }
`;

const Avatar = styled.img`
    position: relative;
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    border: ${(props) => `0.1rem solid ${props.theme.colors.secondary}`};
`;

export default NavLinks;
