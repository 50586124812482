import React from 'react';
import styled from '@emotion/styled';
import PrimaryCreateBoardButton from '../../BoardModals/PrimaryCreateBoardButton';

const EmptyBoardsContent = ({ setOpenCreateBoardModal }) => {
    const openModal = () => setOpenCreateBoardModal(true);
    return (
        <EmptyContentContainer>
            <FirstLine className="header_xs">
                You have not created any boards yet.
            </FirstLine>
            <SecondLine className="header_extra_medium">
                Create one now and start adding resources into it!
            </SecondLine>
            <PrimaryCreateBoardButton
                onClick={openModal}
                content="Create Board"
            />
        </EmptyContentContainer>
    );
};

const EmptyContentContainer = styled.div`
    padding-top: 12rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FirstLine = styled.p`
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 0.8rem;
`;
const SecondLine = styled.p`
    color: ${(props) => props.theme.colors.dark_grey};
    margin-bottom: 3.2rem;
`;

export default EmptyBoardsContent;
