import React from 'react';
import styled from '@emotion/styled';

const RecommendedResourceCard = ({
    recommendedResource,
    openRecommendedResourceBlade,
}) => {
    const { title, slug, author, image, resource_type } = recommendedResource;
    const imageURL = image?.url;

    return (
        <CardContainer
            onClick={(event) => openRecommendedResourceBlade(event, slug)}
            image={image}
        >
            {image && <Thumbnail src={imageURL} alt={`${title} pic`} />}
            <Card image={image}>
                <Title className="header_cabin_sm">{title}</Title>
                <Subtitle className="body_cabin_sm">
                    {author} &bull; {resource_type}
                </Subtitle>
            </Card>
        </CardContainer>
    );
};

const CardContainer = styled.div`
    display: ${(props) => (props.image ? `flex` : `block`)};
    cursor: pointer;
    padding: 2.4rem 0;
    border-top: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};

    :first-of-type {
        border-top: none;
    }
    :nth-of-type(2) {
        border-top: none;
    }
`;

const Thumbnail = styled.img`
    width: 8rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 1rem;
`;

const Title = styled.h3`
    margin-bottom: 0.4rem;
    color: ${(props) => props.theme.colors.black};
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* max 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const Subtitle = styled.p`
    color: ${(props) => props.theme.colors.dark_grey};
`;

const Card = styled.div`
    width: ${(props) => (props.image ? `75%` : `100%`)};
    padding-left: ${(props) => (props.image ? `1.5rem` : `0`)};
`;

export default RecommendedResourceCard;
