import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import {
    INDEX_NAME,
    LAST_UPDATE_DESC,
    VIEW_COUNTS_DESC,
} from '../../algoliaConfigs';
import Stats from './Stats';
import cardViewIcon from '../../assets/images/card-view.svg';
import listViewIcon from '../..//assets/images/list-view.svg';
import Section from '../..//assets/styledComponents/Section';
import SvgIcon from '../../assets/styledComponents/SvgIcon';
import CustomSortBy from './CustomSortBy';
import { useGlobalContext } from '../../globalStore/globalContext';

const SearchResultsInfo = ({
    cardView,
    setCardView,
    searchState,
    currentHierarchicalCategory,
}) => {
    const { globalState } = useGlobalContext();
    const { isMobile } = globalState;
    let queryString;
    const { query } = searchState;
    if (query) {
        queryString = query;
    }

    return (
        <Container>
            {queryString && (
                <SearchResultString className="header_extra_medium">
                    Search Results{' '}
                    {queryString ? (
                        <span>
                            for <QueryString>{queryString}&nbsp;</QueryString>
                        </span>
                    ) : null}
                    {currentHierarchicalCategory ? (
                        <span>
                            {' '}
                            in&nbsp;
                            {currentHierarchicalCategory}
                        </span>
                    ) : null}
                </SearchResultString>
            )}
            <ResultsInfoContainer>
                <Stats />
                <ResultInfoRight>
                    <CustomSortBy
                        defaultRefinement={VIEW_COUNTS_DESC}
                        items={[
                            {
                                value: VIEW_COUNTS_DESC,
                                label: 'Sort by Most Popular',
                            },
                            {
                                value: LAST_UPDATE_DESC,
                                label: 'Sort by Newest',
                            },
                            {
                                value: INDEX_NAME,
                                label: 'Sort by Oldest',
                            },
                        ]}
                    />
                    {!isMobile && (
                        <Fragment>
                            <ViewButton
                                className={cardView ? 'active' : ''}
                                onClick={() => setCardView(true)}
                            >
                                <SvgIcon icon={cardViewIcon} alt="grid view" />
                            </ViewButton>
                            <ViewButton
                                className={cardView ? '' : 'active'}
                                onClick={() => setCardView(false)}
                            >
                                <SvgIcon icon={listViewIcon} alt="list view" />
                            </ViewButton>
                        </Fragment>
                    )}
                </ResultInfoRight>
            </ResultsInfoContainer>
        </Container>
    );
};

const Container = styled(Section)`
    margin: 0;
    margin-bottom: 2.4rem;
`;

const SearchResultString = styled.p`
    margin-bottom: 4rem;

    span {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        margin: inherit;
    }
`;

const QueryString = styled.span`
    font-weight: bolder;
    font-style: italic;
`;

const ResultsInfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 390px) {
        flex-direction: column;
    }
`;

const ResultInfoRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const ViewButton = styled.button`
    width: 3.2rem;
    height: 3.2rem;
    background-color: ${(props) => props.theme.colors.white};
    border: ${(props) => `0.1rem solid ${props.theme.colors.dark_grey}`};
    border-radius: 0.5rem;
    margin-left: 0.4rem;

    :first-of-type {
        margin-left: 0;
    }

    &.active {
        border: ${(props) => `0.1rem solid ${props.theme.colors.tertiary}`};
        background-color: ${(props) => props.theme.colors.tertiary};
    }
`;

export default SearchResultsInfo;
