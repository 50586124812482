import styled from '@emotion/styled';

const IconButton = styled.button`
    cursor: pointer;
    width: ${({ width }) => width ?? `3.2rem`};
    height: ${({ height }) => height ?? `3.2rem`};
    background-color: transparent;
    background-image: url(${({ icon }) => icon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    margin-right: ${({ marginRight }) => marginRight ?? `0`};
    margin-left: ${({ marginLeft }) => marginLeft ?? `0`};
    place-items: center;

    &.active {
        border: 0.1rem solid;
        cursor: pointer;
    }
`;

export default IconButton;
