import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import Section from '../../assets/styledComponents/Section';
import ResourceCard from '../ResourceCard';
import ResourceBlade from '../ResourceBlade';
import Header_Md from '../../assets/styledComponents/Typography/Header_Md';

const HelpfulSection = ({
    title,
    resources,
    setOpenResourceBlade,
    openResourceBlade,
    setIsHoverOnModal,
    slugFromURL,
    setSlugFromURL,
    resourceSlug,
    setResourceSlug,
    setCheckBoxState,
    boards,
    setCurrentResource,
    setOpenAddToBoardModal,
}) => {
    const history = useHistory();

    const handleClick = (event, slug) => {
        event.preventDefault();

        if (slugFromURL) {
            setSlugFromURL(null);
        }
        setResourceSlug(slug);

        const currentPath = history.location.pathname;

        if (openResourceBlade) {
            return history.push({
                pathname: `${currentPath}`,
                search: `resource=${slug}`,
            });
        }
        if (!openResourceBlade) {
            setOpenResourceBlade(true);

            // concat resource param to the current URL
            const currentSearchParam = history.location.search;

            return history.push({
                pathname: `${currentPath}`,
                search: currentSearchParam
                    ? `${currentSearchParam}&resource=${slug}`
                    : `resource=${slug}`,
            });
        }
    };

    return (
        <Container>
            <Header>{title}</Header>
            <CardWrapper>
                {resources.map(
                    (resource, index) =>
                        index < 3 && (
                            <ResourceCard
                                key={`${resource.id}-${index}`}
                                resource={resource}
                                handleClick={handleClick}
                                setCheckBoxState={setCheckBoxState}
                                boards={boards}
                                setCurrentResource={setCurrentResource}
                                setOpenAddToBoardModal={setOpenAddToBoardModal}
                            />
                        )
                )}
            </CardWrapper>
            {openResourceBlade && (
                <ResourceBlade
                    openResourceBlade={openResourceBlade}
                    setOpenResourceBlade={setOpenResourceBlade}
                    setIsHoverOnModal={setIsHoverOnModal}
                    slugFromURL={slugFromURL}
                    setSlugFromURL={setSlugFromURL}
                    resourceSlug={resourceSlug}
                    setResourceSlug={setResourceSlug}
                    handleClick={handleClick}
                />
            )}
        </Container>
    );
};

const Header = styled(Header_Md)`
    max-width: 120rem;
    width: 100%;
`;

const Container = styled(Section)`
    padding: 0 12rem 8rem 12rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 3.2rem;
    }
    @media only screen and (max-width: 1439px) {
        padding: 0 6.4rem 8rem 6.4rem;
    }
    @media only screen and (max-width: 767px) {
        padding: 0 2.4rem 4.8rem 2.4rem;
    }
`;

const CardWrapper = styled.div`
    max-width: 120rem;
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 9.6rem) / 3));
    gap: 0 4.8rem;
    padding: 0;
    margin: 0;

    .item {
        height: auto;
        align-self: start;
    }

    @media only screen and (max-width: 1439px) {
        grid-template-columns: repeat(3, calc((100% - 6.4rem) / 3));
        gap: 0 3.2rem;
    }
    @media only screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 1.6rem 0;
    }
`;

export default HelpfulSection;
