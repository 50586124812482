import styled from '@emotion/styled';
import React from 'react';

const EditBoardModal = ({
    setOpenEditBoardModal,
    openEditBoardModal,
    setOpenRenameBoardModal,
    setOpenDeleteBoardModal,
    setNewBoardName,
    name,
}) => {
    const handleOpenRenameModal = (event) => {
        event.preventDefault();
        setNewBoardName(name);
        setOpenEditBoardModal((prev) => !prev);
        setOpenRenameBoardModal((prev) => !prev);
    };
    const handleOpenDeleteModal = (event) => {
        event.preventDefault();
        setOpenEditBoardModal((prev) => !prev);
        setOpenDeleteBoardModal((prev) => !prev);
    };

    return (
        <Modal openEditBoardModal={openEditBoardModal}>
            <MenuContainer className="body_cabin_sm">
                <MenuItem onClick={handleOpenRenameModal}>Rename</MenuItem>
                <MenuItem delete={true} onClick={handleOpenDeleteModal}>
                    Delete
                </MenuItem>
            </MenuContainer>
        </Modal>
    );
};

const Modal = styled.div`
    position: absolute;
    display: ${(props) => (props.openEditBoardModal ? `block` : `none`)};
    top: 4.5rem;
    right: -4rem;
    z-index: ${(props) => props.theme.zIndex.modal};
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 1rem 0.1rem rgba(149, 149, 149, 0.1);
    white-space: nowrap;
    width: fit-content;
    height: fit-content;
`;

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.2rem 0;
`;

const MenuItem = styled.button`
    all: unset;
    margin-left: 0;
    align-text: left;
    background-color: transparent;
    padding: 0.4rem 2.4rem;
    color: ${(props) =>
        props.delete ? props.theme.colors.extra : props.theme.colors.black};
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.colors.light_grey};
    }
    &:first-of-type:active {
        color: ${(props) => props.theme.colors.secondary};
    }
`;

export default EditBoardModal;
