import styled from '@emotion/styled';

const OvalButton = styled.button`
    width: 100%;
    height: 4rem;
    margin: 0;
    font: inherit;
    text-decoration: none;
    place-items: center;
    text-align: center;
    white-space: nowrap;
    background-color: ${(props) => props.theme.colors.white};
    border: ${(props) => `0.1rem solid ${props.theme.colors.black}`};
    border-radius: 2.5rem;
    box-sizing: border-box;
    disabled: ${(props) => (props.disabled ? props.disabled : false)};
    cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};

    :hover {
        background-color: ${(props) => props.theme.colors.grey};
        border: none;
    }
`;

export default OvalButton;
