import styled from '@emotion/styled';
import React from 'react';

const CancelButton = ({ onClick, large, width }) => {
    return (
        <StyledCancel onClick={onClick} large={large} width={width}>
            Cancel
        </StyledCancel>
    );
};

const StyledCancel = styled.button`
    border: ${(props) => `0.15rem solid ${props.theme.colors.darker_grey}`};
    border-radius: 5rem;
    width: ${(props) => (props.width ? props.width : `auto`)};
    height: ${(props) => (props.large ? `4.8rem` : `4rem`)};
    color: ${(props) => props.theme.colors.darker_grey};
    padding: 1.2rem 4rem;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;

    :hover {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.darker_grey};
    }
`;

export default CancelButton;
