import { useMutation } from '@apollo/client';
import { UPDATE_BOARDS } from '../graphql/mutation';
import {
    capitalizeEachWord,
    deleteExtraSpaces,
} from '../functions/formatString';
import { updateBoards } from '../globalStore/globalReducer';
import { useGlobalContext } from '../globalStore/globalContext';

// Used in: MyBoardsPage and ResourceBlade
const useCreateBoard = (
    setBoardName,
    boardName,
    setErrorMessage,
    boards,
    setBoards,
    setOpenCreateBoardModal,
    registeredUserId
) => {
    const { dispatch } = useGlobalContext();

    const [addBoard] = useMutation(UPDATE_BOARDS, {
        onCompleted: () => {
            setBoardName(null);
        },
        onError: (error) => console.error('Create board error: ', error),
    });

    const createBoard = (event) => {
        event.preventDefault();
        if (!boardName) {
            return setErrorMessage('Please enter a valid name...');
        }
        const trimmedBoardName = deleteExtraSpaces(boardName);

        if (!trimmedBoardName) {
            return setErrorMessage('Please enter a valid name...');
        }
        const formattedBoardName = capitalizeEachWord(trimmedBoardName);
        if (boards && boards[formattedBoardName]) {
            return setErrorMessage('Board name already existed...');
        }
        const newBoards = {
            ...boards,
            [formattedBoardName]: [],
        };
        setBoards(newBoards);
        setOpenCreateBoardModal(false);
        addBoard({
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: registeredUserId,
                        boards: newBoards,
                        __typename: 'RegisteredUser',
                    },
                    __typename: 'updateRegisteredUserPayload',
                },
            },
            variables: {
                registeredUserId: registeredUserId,
                boards: newBoards,
            },
        });
        dispatch({
            type: updateBoards,
            payload: newBoards,
        });

        return true;
    };

    return { createBoard };
};

export default useCreateBoard;
