import { gql } from '@apollo/client';

export const GET_LANDING_PAGE = gql`
    query GetLandingPage {
        landingPage {
            id
            heroSection {
                id
                description
                heroImage {
                    id
                    url
                }
            }
            signUpSection {
                id
                title
                description
            }
            helpfulSection {
                id
                title
                resources(limit: 3) {
                    id
                    title
                    slug
                    author
                    location
                    viewCounts
                    resource_type
                    keywords
                    description
                    date_of_last_update
                    user_specifics {
                        id
                        user
                    }
                    image {
                        id
                        url
                    }
                }
            }
        }
    }
`;

export const GET_USER = gql`
    query GetUser($id: ID!) {
        user(id: $id) {
            id
            email
            registered_user {
                id
                firstName
                lastName
                avatar {
                    id
                    url
                }
                savedSearches
                boards
            }
        }
    }
`;

export const GET_BOARDS = gql`
    query GetBoard($id: ID!) {
        registeredUser(id: $id) {
            id
            boards
        }
    }
`;

export const GET_SAVED_SEARCHES = gql`
    query GetSavedSearches($id: ID!) {
        registeredUser(id: $id) {
            id
            savedSearches
        }
    }
`;

export const GET_RESOURCE_BY_ID = gql`
    query GetResources($id: ID!) {
        resources(where: { id: $id }) {
            id
            title
            slug
            author
            description
            location
            viewCounts
            keywords
            resource_type
            date_of_last_update
            user_specifics {
                id
                user
            }
            image {
                id
                url
            }
            media_type {
                ... on ComponentMediaZonePdf {
                    id
                    pdf_file {
                        id
                        url
                    }
                }
                ... on ComponentMediaZoneVideo {
                    id
                    videoLink
                }
                ... on ComponentMediaZoneImage {
                    id
                    images {
                        id
                        url
                    }
                }
                ... on ComponentMediaZoneExternalLink {
                    id
                    link
                    externalLinkText
                    image {
                        id
                        url
                    }
                }
                ... on ComponentMediaZoneText {
                    id
                    text
                }
            }
            recommended_for_you {
                ... on ComponentRecommendedResourceRecommendedForYou {
                    id
                    resources(limit: 6) {
                        id
                        title
                        slug
                        author
                        date_of_last_update
                        resource_type
                        image {
                            id
                            url
                        }
                    }
                }
            }
        }
    }
`;
export const GET_RESOURCE_BY_SLUG = gql`
    query GetResources($slug: String!) {
        resources(where: { slug: $slug }) {
            id
            title
            slug
            author
            description
            location
            viewCounts
            keywords
            resource_type
            date_of_last_update
            user_specifics {
                id
                user
            }
            audience_levels {
                id
                level
            }
            image {
                id
                url
            }
            media_type {
                ... on ComponentMediaZonePdf {
                    id
                    pdf_file {
                        id
                        url
                    }
                }
                ... on ComponentMediaZoneVideo {
                    id
                    videoLink
                }
                ... on ComponentMediaZoneImage {
                    id
                    images {
                        id
                        url
                    }
                }
                ... on ComponentMediaZoneExternalLink {
                    id
                    link
                    externalLinkText
                    image {
                        id
                        url
                    }
                }
                ... on ComponentMediaZoneText {
                    id
                    text
                }
            }
            recommended_for_you {
                ... on ComponentRecommendedResourceRecommendedForYou {
                    id
                    resources(limit: 6) {
                        id
                        title
                        slug
                        author
                        date_of_last_update
                        resource_type
                        image {
                            id
                            url
                        }
                    }
                }
            }
        }
    }
`;

export const GET_RESOURCES = gql`
    query GetResources {
        resources(sort: "created_at") {
            id
            title
            slug
            author
            viewCounts
            description
            location
            resource_type
            keywords
            date_of_last_update
            user_specifics {
                id
                user
            }
            image {
                id
                url
            }
        }
    }
`;

export const GET_POPULAR_RESOURCES = gql`
    query GetPopularResources($start: Int!) {
        resources(limit: 3, start: $start, sort: "viewCounts:DESC") {
            id
            title
            slug
            author
            viewCounts
            description
            author
            location
            viewCounts
            resource_type
            keywords
            date_of_last_update
            user_specifics {
                id
                user
            }
            image {
                id
                url
            }
        }
    }
`;

export const GET_RESOURCE = gql`
    query GetResource($id: ID!) {
        resource(id: $id) {
            id
            title
            slug
            description
            author
            location
            viewCounts
            keywords
            resource_type
            created_at
            date_of_last_update
            user_specifics {
                id
                user
            }
            image {
                id
                url
            }
            media_type {
                ... on ComponentMediaZonePdf {
                    id
                    pdf_file {
                        id
                        url
                    }
                }
                ... on ComponentMediaZoneVideo {
                    id
                    videoLink
                }
                ... on ComponentMediaZoneImage {
                    id
                    images {
                        id
                        url
                    }
                }
                ... on ComponentMediaZoneExternalLink {
                    id
                    link
                    externalLinkText
                    image {
                        id
                        url
                    }
                }
                ... on ComponentMediaZoneText {
                    id
                    text
                }
            }
            recommended_for_you {
                ... on ComponentRecommendedResourceRecommendedForYou {
                    id
                    resources(limit: 6) {
                        id
                        title
                        slug
                        author
                        date_of_last_update
                        resource_type
                        image {
                            id
                            url
                        }
                    }
                }
            }
        }
    }
`;
