import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import ModalOverlay from '../../../assets/styledComponents/ModalOverlay';
import SvgIcon from '../../../assets/styledComponents/SvgIcon';
import SaveSearch from 'assets/images/saved-searches-icon.svg';
import RefinementListFilters from './RefinementListFilters';
import CustomClearRefinements from '../CustomClearRefinements';

const MobileAdvancedFilterModal = ({
    openMobileModal,
    setOpenMobileModal,
    openNameSavedSearchModal,
    setOpenNameSavedSearchModal,
    searchState,
}) => {
    const token = localStorage.getItem('token');

    return (
        <Fragment>
            <ModalOverlay
                openModal={openMobileModal}
                setOpenModal={setOpenMobileModal}
                firstLayer={true}
            />
            <Modal openModal={openMobileModal}>
                <RefinementListFilters
                    searchState={searchState}
                    setShowDropdown={setOpenMobileModal}
                    showDropdown={openMobileModal}
                />
                <ButtonWrapper className="header_cabin_sm">
                    {token && (
                        <SaveSearchButton
                            onClick={() =>
                                setOpenNameSavedSearchModal(
                                    !openNameSavedSearchModal
                                )
                            }
                        >
                            <SvgIcon
                                width={`1.8rem`}
                                height={`1.8rem`}
                                marginRight={`0.8rem`}
                                icon={SaveSearch}
                                alt="save search"
                            />
                            Save Search
                        </SaveSearchButton>
                    )}
                    <ButtonRightWrapper>
                        <CustomClearRefinements
                            clearsQuery={true}
                            transformItems={(items) =>
                                items.filter(
                                    ({ label }) =>
                                        label !== 'User Specific: Everyone' &&
                                        label !==
                                            'hierarchicalCategories.lvlAll: Home'
                                )
                            }
                        />
                        <ApplyButton
                            onClick={() => setOpenMobileModal((prev) => !prev)}
                        >
                            Apply
                        </ApplyButton>
                    </ButtonRightWrapper>
                </ButtonWrapper>
            </Modal>
        </Fragment>
    );
};

const Modal = styled.div`
    display: ${({ openModal }) => (openModal ? `block` : `none`)};
    background-color: ${(props) => props.theme.colors.white};
    width: 100vw;
    height: 50vh;
    min-width: 31.5rem;
    max-height: 52rem;
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 3rem 3rem 0 0;
    z-index: ${(props) => props.theme.zIndex.modal};
    color: ${(props) => props.theme.colors.primary};
    overflow-y: scroll;
`;

const ButtonWrapper = styled.div`
    width: 100vw;
    height: 4.8rem;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 1.2rem 2.4rem;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.white};
    border: 0.1rem solid ${(props) => props.theme.colors.light_grey};
`;

const SaveSearchButton = styled.button`
    width: auto;
    color: inherit;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.white};
    flex-grow: 1;
`;

const ApplyButton = styled.button`
    width: auto;
    color: inherit;
    display: flex;
    align-items: center;
    margin-left: 2.4rem;
    background-color: ${(props) => props.theme.colors.white};
`;

const ButtonRightWrapper = styled.div`
    display: flex;
`;

export default MobileAdvancedFilterModal;
