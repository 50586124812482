import styled from '@emotion/styled';

const Header_Cabin_Sm = styled.h4`
    font-family: 'CabinSemibold600';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
    color: ${(props) => props.color};
`;

export default Header_Cabin_Sm;
