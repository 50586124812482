import styled from '@emotion/styled';
import React, { useState } from 'react';
import IconButton from '../../../assets/styledComponents/IconButton';
import StyledLink from '../../../assets/styledComponents/StyledLink';
import Dots from '../../../assets/images/3-dots.svg';
import EditBoardModal from '../../BoardModals/EditBoardModal';

const BoardCard = ({
    name,
    setBoardName,
    setNewBoardName,
    setOpenRenameBoardModal,
    setOpenDeleteBoardModal,
    boards,
}) => {
    const encodeName = encodeURIComponent(name);
    const [openEditBoardModal, setOpenEditBoardModal] = useState(false);

    const handleClick = (event) => {
        event.preventDefault();

        setOpenEditBoardModal((prev) => !prev);
        setBoardName(name);
    };

    return (
        <CardContainer
            to={{
                pathname: `/myboard/boards/${encodeName}`,
                state: {
                    name: name,
                } /** passing name as state through react router */,
            }}
        >
            <TitleContainer>
                <BoardTitle className="header_xxs">{name}</BoardTitle>
            </TitleContainer>
            <ButtonsContainer>
                <AmountOfResources className="body_cabin_xs">
                    {boards != null && boards[name] != null
                        ? boards[name].length
                        : `0`}{' '}
                    Resources
                </AmountOfResources>
                <IconButton icon={Dots} onClick={handleClick} />
                <EditBoardModal
                    openEditBoardModal={openEditBoardModal}
                    setOpenEditBoardModal={setOpenEditBoardModal}
                    setOpenRenameBoardModal={setOpenRenameBoardModal}
                    setOpenDeleteBoardModal={setOpenDeleteBoardModal}
                    name={name}
                    setNewBoardName={setNewBoardName}
                />
            </ButtonsContainer>
        </CardContainer>
    );
};

const CardContainer = styled(StyledLink)`
    width: 24.4vw;
    height: 16.1vw;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: 0;
    /* center card in the grid items */
    justify-self: center;

    :hover {
        box-shadow: 0px 2px 15px 0px #4e4e4e59;
    }

    @media only screen and (max-width: 1055px) {
        width: 33.3vw;
        height: 22vw;
    }
    @media only screen and (max-width: 767px) {
        max-width: 35.2rem;
        max-height: 23.2rem;
        width: 80vw;
        height: 60vw;
    }
`;

const TitleContainer = styled.div`
    color: ${(props) => props.theme.colors.secondary};
    flex: 76;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5.2rem;
    border-bottom: ${(props) =>
        `0.1rem solid ${props.theme.colors.light_grey}`};
`;

const BoardTitle = styled.h3`
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const ButtonsContainer = styled.div`
    color: ${(props) => props.theme.colors.darker_grey};
    position: relative;
    flex: 24;
    padding: 1.6rem 2.4rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const AmountOfResources = styled.span`
    color: ${(props) => props.theme.colors.darker_grey};
`;

export default BoardCard;
