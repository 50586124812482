import { useLazyQuery } from '@apollo/client';
import { useGlobalContext } from '../globalStore/globalContext';
import { GET_USER } from '../graphql/queries';
import {
    updateUserId,
    updateRegisteredUserId,
    updateUserInfo,
    updateSavedSearches,
    updateBoards,
} from '../globalStore/globalReducer';
import { useState } from 'react';

const useUserData = (id) => {
    const userId = id ? id : null;
    const [invalidToken, setInvalidToken] = useState(false);
    const { globalState, dispatch } = useGlobalContext();

    const [getUserData] = useLazyQuery(GET_USER, {
        onCompleted: (data) => {
            const user = data.user ? data.user : null;
            const registeredUser = data.user.registered_user
                ? data.user.registered_user
                : null;
            const avatar = registeredUser.avatar ? registeredUser.avatar : null;
            const userInfo = {
                firstName: registeredUser ? registeredUser.firstName : null,
                lastName: registeredUser ? registeredUser.lastName : null,
                email: user ? user.email : null,
                avatarId: avatar ? avatar.id : null,
                avatarUrl: avatar ? avatar.url : null,
            };
            const savedSearches = registeredUser
                ? registeredUser.savedSearches
                : null;
            const boards = registeredUser ? registeredUser.boards : null;

            // set user data
            dispatch({
                type: updateUserId,
                payload: user ? user.id : null,
            });
            dispatch({
                type: updateRegisteredUserId,
                payload: registeredUser ? registeredUser.id : null,
            });
            dispatch({
                type: updateUserInfo,
                payload: userInfo,
            });
            dispatch({
                type: updateSavedSearches,
                payload: savedSearches,
            });
            dispatch({
                type: updateBoards,
                payload: boards,
            });
        },
        onError: (error) => {
            console.log('UseUserData Hook Error: ', error.message);
            localStorage.clear();
            setInvalidToken(true);
        },
    });

    const loadUserData = (passedId = null) => {
        const queryId = passedId || userId;
        if (globalState.userId || !queryId) return;

        getUserData({
            variables: {
                id: queryId,
            },
        });
    };
    return { loadUserData, invalidToken };
};

export default useUserData;
