import React from 'react';
import styled from '@emotion/styled';

const FooterSectionHeader = ({ title }) => {
    return (
        <Container>
            <SectionHeader>{title}</SectionHeader>
        </Container>
    );
};

const Container = styled.div`
    margin-bottom: 1.5rem;
`;

const SectionHeader = styled.p`
    font-family: 'MontserratMedium500';
    color: ${(props) => props.theme.colors.white};
    font-size: 2.2rem;
    line-height: 2.5rem;
    margin: 0;
`;

export default FooterSectionHeader;
