import React, { Fragment, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { SearchBox } from 'react-instantsearch-dom';
import Search from 'assets/images/blue-search-icon.svg';
import DropdownArrow from 'assets/images/search-chevron-icon.svg';
import UserSpecificModal from './UserSpecificModal';
import { useGlobalContext } from '../../../globalStore/globalContext';

const SearchBarArea = ({
    openMobileModal,
    showDropdown,
    openDropdown,
    searchState,
    currentHierarchicalCategory,
    setOpenMobileModal,
}) => {
    const { globalState } = useGlobalContext();
    const { isMobile } = globalState;
    const [openFilterDropdownModal, setOpenFilterDropdownModal] =
        useState(false);
    const [userSpecific, setUserSpecific] = useState('Everyone');

    // set user specific dropdown value to 'Everyone' by default
    useEffect(() => {
        const { menu } = searchState;
        if (!menu) {
            setUserSpecific('Everyone');
        } else {
            const user_specific = menu['User Specific'];
            setUserSpecific(user_specific);
        }
        // eslint-disable-next-line
    }, [searchState]);

    return (
        <Fragment>
            <Container>
                <SearchAreaWrapper>
                    {!isMobile ? (
                        <DropdownWrapper>
                            <DropdownBox
                                onClick={() =>
                                    setOpenFilterDropdownModal((prev) => !prev)
                                }
                            >
                                <p className="header_cabin_sm">
                                    For {userSpecific}
                                </p>
                                <img
                                    src={DropdownArrow}
                                    alt="user specific dropdown"
                                />
                            </DropdownBox>
                            <UserSpecificModal
                                attribute="User Specific"
                                openModal={openFilterDropdownModal}
                                setOpenModal={setOpenFilterDropdownModal}
                            />
                        </DropdownWrapper>
                    ) : null}
                    <SearchBox
                        translations={{
                            placeholder: currentHierarchicalCategory
                                ? `Search in ${currentHierarchicalCategory}`
                                : `Search here...`,
                        }}
                        submit={<img src={Search} alt="search" />}
                    />
                </SearchAreaWrapper>
                {!isMobile ? (
                    <AdvancedButton
                        id="advanced-button"
                        className="header_cabin_sm"
                        onClick={openDropdown}
                        isActive={showDropdown}
                    >
                        Filter
                    </AdvancedButton>
                ) : null}
            </Container>
            {isMobile ? (
                <Fragment>
                    <MobileFilterContainer>
                        <p className="body_cabin_xs filter-tag">Filter:</p>
                        <FilterWrapper>
                            <DropdownWrapper
                                className="header_cabin_sm"
                                isMobile={isMobile}
                            >
                                <DropdownBox
                                    onClick={() =>
                                        setOpenFilterDropdownModal(
                                            (prev) => !prev
                                        )
                                    }
                                >
                                    <p className="header_cabin_sm">
                                        For {userSpecific}
                                    </p>
                                    <img
                                        src={DropdownArrow}
                                        alt="user specific dropdown"
                                    />
                                </DropdownBox>
                                <UserSpecificModal
                                    attribute="User Specific"
                                    openModal={openFilterDropdownModal}
                                    setOpenModal={setOpenFilterDropdownModal}
                                />
                            </DropdownWrapper>
                            <AdvancedButton
                                isMobile={isMobile}
                                className="header_cabin_sm"
                                onClick={() =>
                                    setOpenMobileModal((prev) => !prev)
                                }
                                isActive={openMobileModal}
                            >
                                Filter
                            </AdvancedButton>
                        </FilterWrapper>
                    </MobileFilterContainer>
                </Fragment>
            ) : null}
        </Fragment>
    );
};

const Container = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    border-radius: 5rem;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 1rem;
    }
`;

const SearchAreaWrapper = styled.div`
    height: 5.6rem;
    width: 100%;
    flex-grow: 1;
    display: flex;
    margin-right: 1.6rem;

    @media screen and (max-width: 767px) {
        margin-right: 0;
        max-width: 100%;
        width: 100%;
    }
`;

const DropdownWrapper = styled.div`
    position: relative;
    width: 16rem;
    height: 5.6rem;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};

    @media screen and (max-width: 767px) {
        margin-right: 2.5vw;
        width: 13.5rem;
        height: 2.8rem;
        border-radius: 5rem;
        color: ${(props) => props.theme.colors.primary};
        background-color: ${(props) => props.theme.colors.white};
    }
`;

const DropdownBox = styled.div`
    width: 16rem;
    height: 5.6rem;
    padding: 1.6rem 1.6rem 1.6rem 3.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.light_grey};
    border: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};
    border-radius: 5rem 0 0 5rem;
    white-space: nowrap;
    color: ${(props) => props.theme.colors.primary};

    @media screen and (max-width: 767px) {
        height: 2.8rem;
        width: 100%;
        padding: 1.6rem 1.2rem;
        gap: 0;
        border-radius: 5rem;
        background-color: ${(props) => props.theme.colors.white};
        p {
            /* margin-right: 1.2rem; */
        }
    }
`;

const AdvancedButton = styled.button`
    height: 5.6rem;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light_grey};
    border-radius: 5rem;
    border: ${(props) =>
        props.isActive
            ? `0.1rem solid ${props.theme.colors.secondary}`
            : `0.1rem solid ${props.theme.colors.primary}`};
    box-shadow: ${(props) =>
        props.isActive ? `0 0 0.3rem 0.1rem #4574d0` : `none`};
    padding: 1.5rem 3.8rem 1.7rem 3.8rem;

    @media screen and (max-width: 767px) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.8rem;
        padding: 1.6rem 1.2rem;
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.grey};
    }
`;

const MobileFilterContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .filter-tag {
        color: ${(props) => props.theme.colors.dark_grey};
        white-space: nowrap;
        margin-right: 1vw;
    }
`;

const FilterWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
`;

export default SearchBarArea;
