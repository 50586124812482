import styled from '@emotion/styled';
import React from 'react';

const CenteredModal = ({
    children,
    width,
    height,
    firstLayer = true,
    isMobile,
}) => {
    return (
        <Modal
            width={width}
            height={height}
            firstLayer={firstLayer}
            isMobile={isMobile}
            className="header_xs"
        >
            {children}
        </Modal>
    );
};

const Modal = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    width: ${(props) => (props.width ? props.width : 'auto')};
    height: ${(props) => (props.height ? props.height : 'auto')};
    min-width: 31.5rem;
    max-height: 52rem;
    /*  center modal with fixed position: */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 32px 40px;
    z-index: ${(props) =>
        props.firstLayer
            ? props.theme.zIndex.modal
            : props.theme.zIndex.secondLayerModal};

    color: ${(props) => props.theme.colors.primary};
`;

export default CenteredModal;
