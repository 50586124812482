import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useGlobalContext } from '../../globalStore/globalContext';
import { connectBreadcrumb } from 'react-instantsearch-dom';
import Section from '../../assets/styledComponents/Section';
import formatCategoryLabel from 'functions/formatCategoryLabel';

const Breadcrumb = ({
    items,
    refine,
    createURL,
    searchState,
    setCurrentHierarchicalCategory,
    onSearchStateChange,
}) => {
    const { query } = searchState;
    const [levels, setLevels] = useState([]);
    const { globalState } = useGlobalContext();
    const { isMobile } = globalState;
    const filteredItems = items.filter((item) => item.label !== 'Home');
    const handleClickHome = (event) => {
        event.preventDefault();
        const defaultSearchState = {
            menu: { 'User Specific': 'Everyone' },
            hierarchicalMenu: { 'hierarchicalCategories.lvlAll': 'Home' },
            sortBy: 'cdr_resources_viewCounts_desc',
        };
        setCurrentHierarchicalCategory('');
        onSearchStateChange(defaultSearchState);
    };

    useEffect(() => {
        // store breadcrumbs in levels variable before algolia delete it when no results by default
        if (items.length !== 0) {
            setLevels([...filteredItems]);
        }
        // clear levels if user clear the search
        if (Object.keys(searchState)?.length === 0) {
            setLevels([]);
        }
        // eslint-disable-next-line
    }, [items, searchState]);

    const BreadcrumbList = () => {
        if (query?.length !== 0 && Object.keys(searchState)?.length !== 0) {
            return levels?.map((item) => (
                <StyledLi key={item.value}>
                    <span>{`>`}</span>
                    <ATag
                        href={createURL(item.value)}
                        onClick={(event) => {
                            event.preventDefault();
                            refine(item.value);
                        }}
                    >
                        {`${formatCategoryLabel(item.label)}`}
                    </ATag>
                </StyledLi>
            ));
        } else {
            return filteredItems.map((item) => (
                <StyledLi key={item.value}>
                    <span>{`>`}</span>
                    <ATag
                        href={createURL(item.value)}
                        onClick={(event) => {
                            event.preventDefault();
                            refine(item.value);
                        }}
                    >
                        {`${formatCategoryLabel(item.label)}`}
                    </ATag>
                </StyledLi>
            ));
        }
    };

    return (
        <Container isMobile={isMobile}>
            <StyledUl className="header_cabin_sm">
                <StyledLi>
                    <ATag
                        className={filteredItems.length > 0 ? 'home' : 'hidden'}
                        href="/search"
                        onClick={(event) => handleClickHome(event)}
                    >
                        Start
                    </ATag>
                </StyledLi>
                <BreadcrumbList />
            </StyledUl>
        </Container>
    );
};

const CustomBreadcrumb = connectBreadcrumb(Breadcrumb);

const Container = styled(Section)`
    margin: 0;
    margin-bottom: ${({ isMobile }) => (isMobile ? `1.6rem` : `2.4rem`)};
    margin-top: ${({ isMobile }) => (isMobile ? `6.4rem` : `2rem`)};
`;

const StyledUl = styled.ul`
    all: unset;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;

    .home,
    span {
        color: ${(props) => props.theme.colors.dark_grey};
        visibility: visible;
    }

    .hidden {
        visibility: hidden;
    }

    @media screen and (max-width: 767px) {
        width: 100vw;
        margin-left: -2.4rem;
        padding-left: 2.4rem;
        padding-right: 4.8rem;
        overflow-x: scroll;
        flex-wrap: nowrap;
        box-sizing: border-box;
        /* hide scrollbar */
        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        white-space: nowrap;
        align-items: center;

        ::before {
            content: '';
            display: block;
            width: 2.4rem;
            height: 2rem;
            position: absolute;
            left: 0;
            background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0) 0.33%,
                rgba(255, 255, 255, 0.65) 30.62%,
                #ffffff 60%
            );
        }
    }
`;

const StyledLi = styled.li`
    display: flex;
    gap: 0.8rem;
`;

const ATag = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
`;

export default CustomBreadcrumb;
