import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import CancelButton from '../../assets/styledComponents/Buttons/CancelButton';
import PrimaryButton from '../../assets/styledComponents/Buttons/PrimaryButton';
import CenteredModal from '../../assets/styledComponents/CenteredModal';
import CloseModalButton from '../../assets/styledComponents/CloseModalButton';
import ModalOverlay from '../../assets/styledComponents/ModalOverlay';

const RenameBoardModal = ({
    openRenameBoardModal,
    setOpenRenameBoardModal,
    renameBoard,
    newBoardName,
    handleNewBoardNameChange,
    handleCancel,
    firstLayer,
    errorMessage,
}) => {
    return (
        openRenameBoardModal && (
            <Fragment>
                <ModalOverlay
                    openModal={openRenameBoardModal}
                    setOpenModal={setOpenRenameBoardModal}
                    firstLayer={firstLayer}
                />
                <CenteredModal width={`37.7vw`} firstLayer={firstLayer}>
                    <CloseModalButton
                        openModal={openRenameBoardModal}
                        setOpenModal={setOpenRenameBoardModal}
                    />
                    <Header className="header_xs">Rename Board</Header>
                    <StyledForm onSubmit={renameBoard}>
                        <Input
                            type="text"
                            value={newBoardName}
                            onChange={handleNewBoardNameChange}
                            onKeyPress={(event) =>
                                event.key === 'Enter' && renameBoard(event)
                            }
                            className="body_cabin_sm"
                        />
                        <ErrorMessage
                            className="body_cabin_sm"
                            error={errorMessage}
                        >
                            {errorMessage}
                        </ErrorMessage>
                        <ButtonsContainer>
                            <CancelButton onClick={handleCancel} />
                            <PrimaryButton type="submit" content="Save" />
                        </ButtonsContainer>
                    </StyledForm>
                </CenteredModal>
            </Fragment>
        )
    );
};

const StyledForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Header = styled.h3`
    margin-bottom: 2.4rem;
`;

const Input = styled.input`
    width: 100%;
    height: 4rem;
    margin-top: 1.5rem;
    border: ${(props) => `1px solid ${props.theme.colors.grey}`};
    padding: 0.8rem 1.6rem;
`;

const ErrorMessage = styled.p`
    display: ${({ error }) => (error ? `block` : `none`)};
    color: red;
    margin: 1rem 0;
    height: 2rem;
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;

    @media only screen and (max-width: 767px) {
        justify-content: center;
    }

    button {
        margin: 0;

        :first-of-type {
            margin-right: 0.8rem;
        }
    }
`;

export default RenameBoardModal;
