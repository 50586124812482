import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import BoardCard from './BoardCard';
import { useStaticDataPagination } from '../../../hooks/useStaticDataPagination';

const BoardsContent = ({
    boardNamesArray,
    setBoardName,
    setNewBoardName,
    openRenameBoardModal,
    setOpenRenameBoardModal,
    openDeleteBoardModal,
    setOpenDeleteBoardModal,
    boards,
}) => {
    const { displayData, sentinel } = useStaticDataPagination(
        boardNamesArray,
        12
    );

    return (
        <Fragment>
            <ContentContainer>
                {displayData &&
                    displayData.map((name) => (
                        <BoardCard
                            key={name}
                            name={name}
                            setNewBoardName={setNewBoardName}
                            setBoardName={setBoardName}
                            openRenameBoardModal={openRenameBoardModal}
                            setOpenRenameBoardModal={setOpenRenameBoardModal}
                            openDeleteBoardModal={openDeleteBoardModal}
                            setOpenDeleteBoardModal={setOpenDeleteBoardModal}
                            boards={boards}
                        />
                    ))}
            </ContentContainer>
            <Ref ref={sentinel} />
        </Fragment>
    );
};

const ContentContainer = styled.div`
    padding: 3.2rem 0;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    gap: 2.4rem 3.2rem;

    @media only screen and (max-width: 1439px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 1055px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media only screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    a:nth-of-type(5n + 1) {
        border-top: ${(props) => `0.8rem solid ${props.theme.colors.tertiary}`};
    }
    a:nth-of-type(5n + 2) {
        border-top: ${(props) =>
            `0.8rem solid ${props.theme.colors.secondary}`};
    }
    a:nth-of-type(5n + 3) {
        border-top: ${(props) => `0.8rem solid ${props.theme.colors.primary}`};
    }
    a:nth-of-type(5n + 4) {
        border-top: ${(props) => `0.8rem solid ${props.theme.colors.grey}`};
    }
    a:nth-of-type(5n + 5) {
        border-top: ${(props) =>
            `0.8rem solid ${props.theme.colors.dark_grey}`};
    }
`;

const Ref = styled.div`
    visibility: hidden;
    height: 1rem;
`;

export default BoardsContent;
