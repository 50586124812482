import findResource from './findResource';

const getBoardsFromCheckbox = (checkBoxState, boards, id, currentResource) => {
    const newBoards = { ...boards };
    for (const [name, isChecked] of Object.entries(checkBoxState)) {
        const resourceArr = [...newBoards[name]];
        const hasResource = findResource(id, resourceArr);
        if (isChecked && !hasResource) {
            resourceArr.push(currentResource);
            newBoards[name] = resourceArr;
        }
        if (!isChecked && hasResource) {
            const deleteResource = resourceArr.filter(
                (resource) => resource.id !== id
            );
            newBoards[name] = deleteResource;
        }
    }
    return newBoards;
};

export default getBoardsFromCheckbox;
