import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import scrollIcon from '../assets/images/scroll-to-top-icon.svg';

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
        // eslint-disable-next-line
    }, []);

    const toggleVisibility = () => {
        if (window.scrollY > 600) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Button onClick={scrollToTop} isVisible={isVisible}>
            <ScrollToTopIcon src={scrollIcon} alt="scroll to top" />
        </Button>
    );
};

const Button = styled.button`
    all: unset;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    z-index: 10;
    opacity: ${(props) => (props.isVisible ? `100` : `0`)};
    border-radius: 50%;
    box-shadow: drop-shadow(0px 5px 10px 5px rgba(0, 0, 0, 0.05));
    cursor: pointer;
`;

const ScrollToTopIcon = styled.img`
    width: 6.4rem;
    height: 6.4rem;
    object-fit: cover;
`;

export default ScrollToTop;
