import styled from '@emotion/styled';
import Header_Md from 'assets/styledComponents/Typography/Header_Md';
import Header_Sm from 'assets/styledComponents/Typography/Header_Sm';
import Header_Xs from 'assets/styledComponents/Typography/Header_Xs';
import React from 'react';

const TermsText = () => {
    return (
        <TermsDiv>
            {/* About Us */}
            <Header_Md>Content</Header_Md>
            <List>
                <ListItem>
                    <Link href="#about-us">About Us</Link>
                </ListItem>
                <ListItem>
                    <Link href="#contact-us">Contact Us</Link>
                </ListItem>
                <ListItem>
                    <Link href="#copyright-and-permissions">
                        Copyright and Permissions
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href="#terms">Terms of Use</Link>
                </ListItem>
                <ListItem>
                    <Link href="#privacy">Privacy</Link>
                </ListItem>
            </List>
            <br />

            {/* About Us */}
            <Header_Sm id="about-us">About Us</Header_Sm>
            <Paragraph>
                The Child Development and Rehabilitation InfoSource website was
                created by Child Development and Rehabilitation Evidence Centre,
                a virtual knowledge translation centre established at BC
                Children&apos; Hospital, a part of the Provincial Health
                Services Authority (“PHSA”) in partnership with BC
                Children&apos;s Hospital Digital Lab. We created the website in
                collaboration with families, youth, healthcare professionals and
                clinical experts to be a trusted source for development,
                rehabilitation and support information and resources for
                children and youth with neurodiversity and/or disability in
                British Columbia.
            </Paragraph>
            <br />
            <Header_Xs>Our website content process:</Header_Xs>
            <Paragraph>
                Our website content was vetted by clinical experts, and where
                appropriate, in consultation with our Family and Youth Advisory
                Group, Indigenous Health Advisors and partner organizations.
                Content is reviewed on a regular basis to ensure the information
                is accurate and up to date. If you would like more information
                about our process for developing or reviewing content, or have
                feedback you would like to provide to us, please don&apos;t
                hesitate to email us at{' '}
                <Link href="mailto:CDRInfoSource@cw.bc.ca.">
                    CDRInfoSource@cw.bc.ca.
                </Link>
            </Paragraph>
            <br />
            <Header_Xs>Our supporters:</Header_Xs>
            <Paragraph>
                The Child Development and Rehabilitation Evidence Centre and the
                development of this website is funded by the BC Children&apos;s
                Hospital Foundation. The website is maintained with support from
                the Ministry of Children and Family Development.
            </Paragraph>
            <br />
            <Header_Xs>Land acknowledgement:</Header_Xs>
            <Paragraph>
                BC Children&apos;s Hospital and BC Children&apos;s Hospital
                Digital Lab are located on the unceded traditional and ancestral
                territories of the xʷməθkwəy̓əm (Musqueam), Skwxwú7mesh
                (Squamish), and səlí\lwətaʔ (Tsleil-Waututh) Nations.
            </Paragraph>
            <br />
            {/* Contact */}
            <Header_Sm id="contact-us">Contact Us</Header_Sm>
            <Paragraph>
                You can email us at{' '}
                <Link href="mailto:CDRInfoSource@cw.bc.ca.">
                    CDRInfoSource@cw.bc.ca.
                </Link>
            </Paragraph>
            <br />
            {/* Copyright and Permissions */}
            <Header_Sm id="copyright-and-permissions">
                Copyright and Permissions
            </Header_Sm>
            <br />
            <Header_Xs>Reserved Rights and Grant of Limited License:</Header_Xs>
            <Paragraph>
                Copyright © 2023 Provincial Health Services Authority. All
                rights reserved.
            </Paragraph>
            <List>
                <ListItem>
                    PHSA either owns the intellectual property rights, including
                    copyright, in and to all information on this Site, or has
                    acquired the necessary licenses and permissions from the
                    applicable rights&apos; holders to display the material on
                    the Site.
                </ListItem>
                <ListItem>
                    As a user of this Site, you are granted a limited license to
                    use (display or print) the information for personal,
                    non-commercial use only, provided the information is not
                    modified and all proprietary notices are retained. Any other
                    use of the information is strictly prohibited without our
                    permission and the permission of the applicable rights&apos;
                    holders. None of the information may be otherwise
                    reproduced, republished or re-disseminated in any manner or
                    form without our prior written consent. Please contact{' '}
                    <Link href="mailto:CDRInfoSource@cw.bc.ca.">
                        CDRInfoSource@cw.bc.ca.
                    </Link>
                </ListItem>
                <ListItem>
                    Nothing contained herein shall be construed as conferring
                    any right in any copyright in any of the information
                    provided on the Site or imply that a licence has been
                    granted in respect of any trademarks, service marks or trade
                    names displayed on the Site.
                </ListItem>
            </List>
            <br />
            {/* Terms of Use */}
            <Header_Sm id="terms">Terms of Use</Header_Sm>
            <br />
            <Header_Xs>Information:</Header_Xs>
            <Paragraph>
                Information on the Site is provided for educational information
                purposes only and is intended to offer a general basis for
                individuals to discuss their medical condition with their
                physician. The information does not constitute medical advice
                and is not intended to be a substitute for advice given by your
                physician or other qualified health care professional.
            </Paragraph>
            <Paragraph>
                Always seek the advice of your physician or other qualified
                health care professional with questions you may have. Never
                disregard medical advice or delay seeking medical advice because
                of something on the Site. Information contained in the Site
                should be used only under the supervision of an appropriately
                qualified physician. Please consult with your physician before
                making any decision regarding treatment and/or medication. In
                case of medical emergency call your doctor or 911 immediately.
            </Paragraph>
            <br />
            <Header_Xs>Disclaimer and Limitation of Liability:</Header_Xs>
            <Paragraph>
                Any information created by us is believed to be reliable when
                posted. However we do not guarantee or warrant the quality,
                accuracy, completeness, timeliness, appropriateness or
                suitability of the information provided. Reliance on any
                information appearing on the Site is at the user&apos;s own
                risk. We expressly disclaim all warranties, representations and
                conditions regarding use of the Site or the information. We
                assume no obligation to update the information or advise on
                further developments concerning topics mentioned. The
                information is supplied “as is” and may contain errors. The
                information may be changed from time to time without notice.
                Certain portions of the information may have been contributed by
                third parties and should not be assumed to have been reviewed or
                endorsed by us. The mention of specific products or services on
                the Site does not constitute or imply a recommendation or
                endorsement by us, unless explicitly stated.
            </Paragraph>
            <Paragraph>
                PHSA and other content providers including without limitation
                physicians and pharmacists who contribute to the information on
                the Site will have no liability whether direct, indirect,
                consequential, contingent, special or incidental related to or
                arising out of or in connection with the use of the Site or a
                User&apos;s reliance on the information, whether in an action of
                contract, negligence or other tortious action. Anyone using the
                information on the Site does so at their own risk, and by using
                the information agrees to indemnify PHSA and its content
                providers from any and all liability, loss, injury, damages,
                costs and expenses (including legal fees and expenses) arising
                from the User&apos;s use of the information on the Site.
            </Paragraph>
            <br />
            <Header_Xs>Links:</Header_Xs>
            <Paragraph>
                Any links we provide to other websites are provided as a
                reference to help you identify and locate other internet
                resources that may be of interest. These other websites were
                independently developed by other parties and we do not assume
                responsibility for the accuracy or appropriateness of the
                information contained at, or endorse the viewpoints expressed at
                such other websites. Your use of any third party website is at
                your own risk and is subject to the terms of use of such
                website.
            </Paragraph>
            <br />
            <Header_Xs>Choice of Law:</Header_Xs>
            <Paragraph>
                The laws in effect in the Province of British Columbia shall
                govern this agreement and any dispute or claim based on your use
                of the website. You agree that any action or proceeding relating
                to this agreement or your use of the website shall only be
                brought by you in the courts of the Province of British
                Columbia. The website is intended for use by residents of
                British Columbia, Canada only.
            </Paragraph>
            <Paragraph>
                These terms of use set forth the entire understanding and
                agreement between us and you with respect to your use of the
                website.
            </Paragraph>
            <br />
            {/* Privacy */}
            <Header_Sm id="privacy">Privacy</Header_Sm>
            <Paragraph>
                We are committed to maintaining confidentiality and personal
                privacy. Collection, use, disclosure and retention of personal
                information is subject to, and must comply with, the provisions
                of the{' '}
                <Link
                    href="https://www.bclaws.gov.bc.ca/civix/document/id/complete/statreg/96165_01"
                    target="_blank"
                    rel="noreferrer"
                >
                    BC Freedom of Information and Protection of Privacy
                    Act(FIPPA)
                </Link>
                . The purpose of this privacy notice is to describe our
                practices regarding personal information use and collection
                through the Child Development & Rehabilitation InfoSource
                (CDRInfoSource) or “website”. Please note that we do not
                disclose, give, sell or transfer any personal information about
                our website visitors unless required for law enforcement or
                otherwise provided for by law.
            </Paragraph>
            <Paragraph>
                We reserve the right to update this policy at any time. Users
                are responsible for reviewing this document periodically for
                changes.
            </Paragraph>
            <br />
            <Header_Xs>What is personal information?</Header_Xs>
            <Paragraph>
                Personal information is recorded information about an
                identifiable individual other than business contact information
                and would include, but is not limited to, such things as an
                individual&apos;s name, address, birth date, email address, and
                phone number.
            </Paragraph>
            <br />
            <Header4>
                What kind of information is being collected and how is it used?
            </Header4>
            <Paragraph>
                PHSA is authorized to collect personal information via this
                website under section 26 (c) of FIPPA for the purposes described
                below. Information is collected from the website either directly
                from you or automatically.
            </Paragraph>
            <br />
            <Header4>Information collected directly from you</Header4>
            <Paragraph>
                Personal information is collected through our websites in
                certain circumstances, including creating a user account and
                updating your profile on the account settings page.
            </Paragraph>
            <Paragraph>
                We only use the personal information you provide to us through
                our websites for purposes consistent with its collection.
            </Paragraph>
            <Paragraph>
                You should also be aware that information provided by you
                becomes a record of the organization and is subject to the
                access and privacy protection requirements set out in the BC
                Freedom of Information and Protection of Privacy Act. This
                information may be further collected, used and disclosed in
                accordance with that Act or as otherwise required by law. Under
                this legislation you are also entitled to access and correct any
                of your personal information that is within our custody and
                control. You may make such requests by emailing us at{' '}
                <Link href="mailto:CDRInfoSource@cw.bc.ca.">
                    CDRInfoSource@cw.bc.ca.
                </Link>
                .
            </Paragraph>
            <br />
            <Header4>Information collected automatically</Header4>
            <Paragraph>
                Some of our web pages use &quot;cookies,&quot; which are small
                amounts of information stored by your web browser software on
                your work station.
            </Paragraph>
            <Paragraph>
                Cookies are designed to track browsing history on a website
                generally for the convenience of the user. They help websites
                &quot;remember&quot; what areas of a website a browser has
                accessed or used previously. Cookies can, however, be misused,
                and have become a privacy concern for some because they can be
                used to record and analyze an individual&apos;s broader browsing
                history.
            </Paragraph>
            <Paragraph>
                The cookies we use on our websites and what we use them for are
                described below.
            </Paragraph>
            <Paragraph>
                This site operates on &quot;implied consent,&quot; which means
                that by visiting and clicking through the site, we assume you
                agree with this usage. If you do not agree, then you should not
                use our sites, or you can delete the cookies after visiting our
                sites, or use your browser&apos;s anonymous usage setting
                (called &quot;Incognito&quot; in Chrome, &quot;In Private&quot;
                in Internet Explorer, &quot;Private Browsing&quot; in Firefox
                and Safari).
            </Paragraph>
            <Paragraph>
                More information on cookies, what they are used for and how to
                delete cookies after visiting this site can be found at{' '}
                <Link
                    href="http://www.allaboutcookies.org/cookies/session-cookies-usedfor.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    http://www.allaboutcookies.org/cookies/session-cookies-usedfor.html.
                </Link>
            </Paragraph>
            <Paragraph>
                It&apos;s important to know that cookies are plain text files
                and cannot execute files or make copies of themselves. They do
                not carry or deliver viruses and cannot scan or retrieve your
                personal information.
            </Paragraph>
            <Paragraph>
                There are two types of cookie &ndash; <Strong>Session</Strong>{' '}
                and <Strong>Persistent</Strong>.
            </Paragraph>
            <br />
            <Header4>Session cookies</Header4>
            <Paragraph>
                Session cookie information is deleted as soon as you leave our
                websites. The use of temporary session cookies is solely for the
                purpose of allowing the user to interact with our websites in an
                effective manner.
            </Paragraph>
            <br />
            <Header4>Persistent cookies</Header4>
            <Paragraph>
                In a few cases our website may use persistent cookies to store
                information for longer periods than the current session.
            </Paragraph>
            <br />
            <Header_Xs>Analytics & safeguards</Header_Xs>
            <br />
            <Header4>Analytics</Header4>
            <Paragraph>
                CDRInfoSource uses Google Analytics to analyze site usage, what
                visitors do on the site, which browser they use, etc. This
                information is obtained from Internet Information Server (IIS)
                log files and allows us to understand how our site is being used
                so that we can improve the user experience. We collect only the
                following information as you browse through our website:
            </Paragraph>
            <List>
                <ListItem>
                    Internet Protocol (IP) address, browser-type and operating
                    system (the IP address is a numerical identifier assigned
                    either to your internet service provider or directly to your
                    computer. This address can be translated to determine the
                    domain name of your service provider and may identify you.)
                </ListItem>
                <ListItem>The date and time of the visit.</ListItem>
                <ListItem>The pages visited within the site.</ListItem>
                <ListItem>The address of the referrer website.</ListItem>
                <ListItem>
                    The search term(s) used to find the site (if referred from a
                    search engine).
                </ListItem>
            </List>
            <br />
            <Paragraph>
                We collect this information for statistical purposes and for
                evaluating our website to make it more useful to visitors. We
                use IP addresses to generate geographic-related statistics, and
                to help administer the site, in general. The website uses IP
                masking within Google Analytics to anonymize personal
                information collected by Google. IP masking is a customization
                which removes the last octet of the visitor&apos;s IP address
                prior to its use and storage by Google. We make no attempt to
                link addresses with the identities of individuals visiting our
                site.
            </Paragraph>
            <Paragraph>
                Information collected by Google Analytics is retained for up to
                25 months and then deleted.
            </Paragraph>
            <Paragraph>
                You may refuse the use of cookies by selecting the appropriate
                settings on your browser - however please note that if you do
                this you may not be able to use the full functionality of this
                website.
            </Paragraph>
            <Paragraph>
                Website visitors who do not want to be tracked by Google
                Analytics can download the Google Analytics Opt-out Browser
                Add-on. The add-on communicates with the Google Analytics
                JavaScript (ga.js) to indicate that information about the
                website visit should not be sent to Google Analytics. The Google
                Analytics Opt-out Browser Add-on does not prevent information
                from being sent to the website itself or to other web analytics
                services.
            </Paragraph>
            <Paragraph>
                For more information visit the{' '}
                <Link
                    href="https://support.google.com/analytics/answer/12159447?hl=en "
                    target="_blank"
                    rel="noreferrer"
                >
                    Google Analytics Overview page
                </Link>
                .
            </Paragraph>
            <br />
            <Header4>Safeguards</Header4>
            <Paragraph>
                PHSA is obligated to protect your personal information by making
                reasonable security arrangements against such risks as
                unauthorized access, collection, use, disclosure or disposal.
                Security measures have been integrated into the design,
                implementation and day-to-day operating practices as part of
                PHSA’s continuing commitment to the protection of personal
                information it holds
            </Paragraph>
            <br />
            <Header4>Email</Header4>
            <Paragraph>
                Information sent to our websites over the Internet is not
                secure. This means that if the visitor uses the Contact Us page,
                or other email addresses published on our websites, to send an
                email, the information provided may be intercepted as it travels
                to our websites. We will not be responsible for any damages
                suffered by individuals as a result of the transmission and
                information that is sent to us or for any errors or changes made
                to transmitted information.
            </Paragraph>
            <br />
            <Header_Xs>Social media buttons</Header_Xs>
            <Paragraph>
                You might see &quot;social media buttons&quot; on some Child
                Development & Rehabilitation InfoSource websites.
            </Paragraph>
            <Paragraph>
                Social media buttons let visitors share or bookmark web pages,
                and are often identifiable by the + sign with Share beside it.
                There may be buttons for: Twitter, Facebook &apos;Like&apos;,
                LinkedIn &apos;Share&apos;, and others.
            </Paragraph>
            <Paragraph>
                In order to connect social buttons to the relevant external
                sites, there are scripts from those domains, which are outside
                of this site. Those sites could be collecting information about
                what you are doing all around the internet, including this site.
                To be certain what information those sites collect, and how to
                opt out, check the respective policies of each site.
            </Paragraph>
            <br />
            <Header4>Related Links</Header4>
            <Paragraph>
                Child Development & Rehabilitation Infosource website contains
                links to other websites PHSA is not responsible for the privacy
                practices of these websites or the accuracy or reliability of
                any content found on such sites. Please refer to our Terms of
                Use for more details
            </Paragraph>
            <br />
            <Header4>Data Retention Policy</Header4>
            <Paragraph>
                PHSA retains the information entered as a part of your profile,
                in our databases, which may include:
            </Paragraph>
            <List>
                <ListItem>First Name</ListItem>
                <ListItem>Last Name</ListItem>
                <ListItem>Email</ListItem>
                <ListItem>Profile image</ListItem>
                <ListItem>Saved content</ListItem>
            </List>
            <br />
            <Paragraph>
                The use of your real name, regularly used email, or a real image
                of you or your likeness, is not a necessity to use this site. If
                you are not comfortable sharing this information or would like
                to withhold this information but still have an account to save
                content, you are welcomed to use a pseudonym. We do not
                knowingly collect personal information from anyone under the age
                of 19 on this website. If you are under 19, you may ask your
                guardian to help you make decisions or to make decisions for you
                about your personal information through [the website]. Please
                contact us if you are the guardian a child and you are concerned
                that your child has (1) provided us with personal information
                without your consent and (2) that they do not have the capacity
                to make decisions about their personal information.”
            </Paragraph>
            <br />
        </TermsDiv>
    );
};

const TermsDiv = styled.div`
    padding: 6rem 12rem 12rem;
    background-color: ${(props) => props.theme.colors.lighterGrey};
    border-radius: 10rem 10rem 0 0;
`;

const Paragraph = styled.p`
    margin-top: 1rem;
    font-size: 1.6rem;
    line-height: 1.7;
    margin-bottom: 2rem;
    color: ${(props) => props.theme.colors.almostBlack};
`;

const Header4 = styled.h4`
    font-family: MontserratBold700;
    margin-bottom: 2rem;
    color: ${(props) => props.theme.colors.almostBlack};
`;

const Link = styled.a`
    font-family: 'MontserratMedium500';
    word-break: break-word;
    color: ${(props) => props.theme.colors.almostBlack};
    text-decoration: underline;
`;

const List = styled.ul`
    font-family: 'MontserratMedium500';
`;

const ListItem = styled.li`
    margin: 1rem 0;
`;

const Strong = styled.strong`
    font-family: MontserratBold700;
`;

export default TermsText;
