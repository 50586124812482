import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import DashBoardRouter from '../pages/DashBoardRouter';
import ProfilePage from '../pages/ProfilePage';
import ProtectedRoute from './ProtectedRoute';
import LandingPage from '../pages/LandingPage';
import ForgetPasswordPage from '../pages/ForgetPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import SearchPage from '../pages/SearchPage';
import NotFoundPage from 'pages/PageNotFound';
import NavBar from '../components/NavBar';
import useUserData from '../hooks/useUserData';
import { useGlobalContext } from '../globalStore/globalContext';
import Footer from '../components/Footer';
import BetaBadge from 'components/BetaBadge';
import TermsOfUsePage from '../pages/TermsOfUsePage';
import { updateIsMobile } from '../globalStore/globalReducer';
import ScrollToTop from '../components/ScrollToTop';

const Routes = () => {
    const token = localStorage.getItem('token');
    const id = token ? jwtDecode(token)['id'] : null;
    const { loadUserData, invalidToken } = useUserData(id);
    const { globalState, dispatch } = useGlobalContext();
    const history = useHistory();

    useEffect(() => {
        // prevent user info disappear after refresh the page
        if (id) {
            loadUserData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (invalidToken) {
            history.push('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invalidToken]);

    const handleMobile = () => {
        window.innerWidth < 768
            ? dispatch({ type: updateIsMobile, payload: true })
            : dispatch({ type: updateIsMobile, payload: false });
    };

    useEffect(() => {
        let isMounted = true;
        window.addEventListener('resize', () => isMounted && handleMobile());
        handleMobile();

        return () => {
            isMounted = false;
            window.removeEventListener('resize', () => handleMobile());
        };
        //eslint-disable-next-line
    }, []);

    return (
        ((id && globalState.registeredUserId) || !id || invalidToken) && (
            <div>
                <NavBar />
                <BetaBadge />
                <ScrollToTop />
                <Switch>
                    <Route exact path="/" component={LandingPage} />
                    <Route exact path="/search" component={SearchPage} />
                    {/* Registered users routes */}
                    <ProtectedRoute
                        path="/myboard"
                        component={DashBoardRouter}
                    />
                    <ProtectedRoute
                        exact
                        path="/profile"
                        component={ProfilePage}
                    />
                    <Route
                        exact
                        path="/request-new-password"
                        component={ForgetPasswordPage}
                    />
                    <Route
                        exact
                        path="/reset-password"
                        component={ResetPasswordPage}
                    />
                    <Route
                        exact
                        path="/terms-of-use"
                        component={TermsOfUsePage}
                    />
                    <Route component={NotFoundPage} />
                </Switch>
                <Footer />
            </div>
        )
    );
};

export default Routes;
