import React from 'react';
import styled from '@emotion/styled';
import Edit from '../../../assets/images/edit-icon.svg';
import Delete from '../../../assets/images/delete-icon.svg';
import { createURL } from '../../../functions/algoliaSearchToUrl';
import StyledLink from '../../../assets/styledComponents/StyledLink';
import SvgIcon from '../../../assets/styledComponents/SvgIcon';
import ExternalLinkIcon from '../../../assets/images/external-link-icon.svg';
import theme from '../../../assets/styles/theme';

const SavedSearchCard = ({
    title,
    content,
    openRenameModal,
    setOpenRenameModal,
    setCurrentSavedSearchTitle,
    setNewSavedSearchTitle,
    setOpenDeleteModal,
}) => {
    const handleEdit = (event) => {
        event.preventDefault();
        setCurrentSavedSearchTitle(title);
        setNewSavedSearchTitle(title);
        setOpenRenameModal(!openRenameModal);
    };
    const handleDeleteSavedSearches = (event) => {
        event.preventDefault();
        setOpenDeleteModal((prev) => !prev);
        setCurrentSavedSearchTitle(title);
    };

    const formattedSearchState = { refinementList: content };
    const searchQueryLink = createURL(formattedSearchState);

    const Content = () =>
        Object.keys(content).map((key) => {
            let value = content[key];
            if (value.length > 1) {
                value = value.join(' | ');
            }
            return (
                <FilterDetails key={key}>
                    <span className="header_cabin_sm">{key}: </span>
                    <span className="body_cabin_sm">{value}</span>
                </FilterDetails>
            );
        });

    return (
        <CardContainer className="header_cabin_sm">
            <HeaderWrapper>
                <HeaderLink to={`/search/${searchQueryLink}`}>
                    <Header>{title}</Header>
                    <SvgIcon
                        icon={ExternalLinkIcon}
                        width={`1.6rem`}
                        height={`1.6rem`}
                        bgColor={theme.colors.secondary}
                    />
                </HeaderLink>
                <IconsWrapper>
                    <SvgIcon
                        icon={Edit}
                        width={`2.4rem`}
                        height={`2.4rem`}
                        bgColor={theme.colors.grey}
                        marginRight={`0.8rem`}
                        alt="edit saved search"
                        onClick={handleEdit}
                    />
                    <SvgIcon
                        icon={Delete}
                        width={`2.4rem`}
                        height={`2.4rem`}
                        bgColor={theme.colors.grey}
                        alt="delete saved search"
                        onClick={handleDeleteSavedSearches}
                    />
                </IconsWrapper>
            </HeaderWrapper>
            <Content />
        </CardContainer>
    );
};

const CardContainer = styled.div`
    width: 100%;
    max-width: 188rem;
    height: auto;
    padding: 2.4rem;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 1rem;
`;

const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};
    padding-bottom: 1.6rem;
    margin-bottom: 1.6rem;
`;

const HeaderLink = styled(StyledLink)`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.secondary};
`;

const Header = styled.h3`
    margin: 0;
    margin-right: 0.4rem;
`;

const FilterDetails = styled.p`
    margin-bottom: 0.8rem;
`;

const IconsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export default SavedSearchCard;
