import generateAvatarPlaceholder from './generateAvatarPlaceholder';

const displayUserAvatar = (userInfo) => {
    const { firstName, lastName, avatarUrl } = userInfo;
    let userAvatar;

    if (!avatarUrl) {
        userAvatar = generateAvatarPlaceholder(firstName, lastName);
    } else {
        userAvatar = avatarUrl;
    }

    return userAvatar;
};

export default displayUserAvatar;
