import { gql } from '@apollo/client';

export const UPDATE_REGISTERED_USER_NAME = gql`
    mutation UpdateUserName(
        $registeredUserId: ID!
        $firstName: String
        $lastName: String
    ) {
        updateRegisteredUser(
            input: {
                where: { id: $registeredUserId }
                data: { firstName: $firstName, lastName: $lastName }
            }
        ) {
            registeredUser {
                id
                firstName
                lastName
            }
        }
    }
`;

export const UPDATE_USER_AVATAR = gql`
    mutation UpdateUserAvatar($registeredUserId: ID!, $avatarId: ID) {
        updateRegisteredUser(
            input: {
                where: { id: $registeredUserId }
                data: { avatar: $avatarId }
            }
        ) {
            registeredUser {
                id
                avatar {
                    id
                    url
                }
            }
        }
    }
`;

export const DELETE_AVATAR = gql`
    mutation DeleteAvatar($avatarId: ID!, $registeredUserId: ID!) {
        updateRegisteredUser(
            input: { where: { id: $registeredUserId }, data: { avatar: null } }
        ) {
            registeredUser {
                id
                avatar {
                    id
                    url
                }
            }
        }
        deleteFile(input: { where: { id: $avatarId } }) {
            file {
                id
            }
        }
    }
`;

export const UPDATE_SAVED_SEARCHES = gql`
    mutation UpdateSavedSearches(
        $registeredUserId: ID!
        $savedSearches: JSON!
    ) {
        updateRegisteredUser(
            input: {
                where: { id: $registeredUserId }
                data: { savedSearches: $savedSearches }
            }
        ) {
            registeredUser {
                id
                savedSearches
            }
        }
    }
`;

export const UPDATE_BOARDS = gql`
    mutation UpdateBoards($registeredUserId: ID!, $boards: JSON!) {
        updateRegisteredUser(
            input: {
                where: { id: $registeredUserId }
                data: { boards: $boards }
            }
        ) {
            registeredUser {
                id
                boards
            }
        }
    }
`;

export const UPDATE_VIEW_COUNTS = gql`
    mutation UpdateViewCounts($id: ID!, $viewCounts: Long) {
        updateResource(
            input: { where: { id: $id }, data: { viewCounts: $viewCounts } }
        ) {
            resource {
                id
                viewCounts
            }
        }
    }
`;
