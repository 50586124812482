import styled from '@emotion/styled';
import React, { useEffect, useState, useRef } from 'react';
import Section from 'assets/styledComponents/Section';
import Header_Md from 'assets/styledComponents/Typography/Header_Md';
import Body_Cabin_Sm from 'assets/styledComponents/Typography/Body_Cabin_Sm';
// import OvalButton from 'assets/styledComponents/OvalButton';
import { useGlobalContext } from 'globalStore/globalContext';

const SignUpSection = ({ bottomPadding, setBottomPadding }) => {
    const [leftPadding, setLeftPadding] = useState(0);
    const shapeRef = useRef(null);
    const { globalState } = useGlobalContext();

    //this is to keep the shape 50% split between the hero and browse section and padded correctly at sizess above 1440px
    const handleResize = () => {
        if (shapeRef.current) {
            const shape = shapeRef.current;
            const shapeHeight = shape.getBoundingClientRect().height;
            setBottomPadding(shapeHeight / 2);
        }
        const search = document.getElementById('search-bar');
        if (search && window.innerWidth > 1440) {
            setLeftPadding(search.getBoundingClientRect().left);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        //eslint-disable-next-line
    }, []);

    return (
        <Container
            ref={shapeRef}
            bottomPadding={bottomPadding}
            leftPadding={leftPadding}
            isMobile={globalState.isMobile}
        >
            <Wrapper>
                <BlurbDiv>
                    <Header_Md>New to CDR?</Header_Md>
                    <Body_Cabin_Sm>
                        Sign up today to follow your favourite topics, save
                        resources for quick access, and customize this site to
                        your needs!
                    </Body_Cabin_Sm>
                </BlurbDiv>
                <SignUpButton
                    href="https://docs.google.com/document/d/1hCOliZz07X5pnl2vGGcG4DLAqQ5epkJGCKV2jTR3YaA/edit"
                    target="_blank"
                    rel="noreferrer noopener"
                    // onClick={() => setBothSignUp()}
                >
                    Help Guide
                </SignUpButton>
            </Wrapper>
        </Container>
    );
};

const Wrapper = styled.div`
    display: flex;
    width: 102rem;
    align-items: center;
    @media only screen and (max-width: 767px) {
        width: auto;
        display: flex;
        flex-direction: column;
    }
`;

const BlurbDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
        margin-bottom: 0.8rem;
    }
    margin-right: 11.2rem;
    @media only screen and (max-width: 1439px) {
        margin-right: 8rem;
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 3.2rem;
    }
`;

const SignUpButton = styled.a`
    width: 100%;
    margin: 0;
    text-decoration: none;
    place-items: center;
    text-align: center;
    white-space: nowrap;
    border-radius: 2.5rem;
    box-sizing: border-box;
    disabled: ${(props) => (props.disabled ? props.disabled : false)};
    cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.tertiary};
    border: none;
    padding: 1.2rem 4rem;
    height: 4.8rem;
    width: min-content;
    display: flex;
    justify-content: center;
    font-family: 'CabinSemibold600', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03rem;
    :hover {
        background-color: ${(props) => props.theme.colors.tertiary_dark};
        border: none;
    }
`;

const Container = styled(Section)`
    padding: 4rem 0 4.8rem ${(props) => props.leftPadding / 10}rem;
    margin: 0;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    width: 108rem;
    border-radius: 20rem;
    align-self: center;

    z-index: 1;
    margin-bottom: -${(props) => props.bottomPadding}px;
    padding: 6.4rem 15.2rem 6.4rem 15.2rem;

    @media only screen and (max-width: 767px) {
        transform: none;
        border-radius: 0rem;
        width: 100%;
        flex-direction: column;
        padding: 4.8rem 2.4rem 2.4rem 2.4rem;
        margin-bottom: 0;
        align-items: center;
    }
`;
export default SignUpSection;
