import React, { useState } from 'react';
import styled from '@emotion/styled';
import PageContainer from '../assets/styledComponents/PageContainer';
import PrimaryButton from '../assets/styledComponents/Buttons/PrimaryButton';

const ForgetPasswordPage = () => {
    const [form, setForm] = useState({ email: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const updateForm = (e) => {
        setErrorMessage('');
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleForgetPassword = (e) => {
        e.preventDefault();

        const forgotPasswordURL = `${process.env.REACT_APP_API_URL}/auth/forgot-password`;

        const forgetPasswordFormData = {
            email: form.email,
            url: `${process.env.REACT_APP_API_URL}/admin/plugins/users-permissions/auth/forgot-password`,
        };

        const forgotPassword = async (url, data) => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            return response.json();
        };

        forgotPassword(forgotPasswordURL, forgetPasswordFormData).then(
            (res) => {
                if (!res.statusCode) {
                    setEmailSent(true);
                } else {
                    setErrorMessage(res.message[0].messages[0].message);
                }
            }
        );
    };

    const renderEmailForm = () => {
        return (
            <div>
                <Header className="header_xs">
                    Enter your email to receive a link to reset your password
                </Header>
                <Form className="body_cabin_sm">
                    <InputWrapper errorMessage={errorMessage}>
                        <Label htmlFor="Email">Email</Label>
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            onChange={updateForm}
                            required
                        />
                    </InputWrapper>
                    <ErrorMsg
                        className="body_cabin_xs"
                        errorMessage={errorMessage}
                    >
                        {errorMessage}
                    </ErrorMsg>
                    <PrimaryButton
                        width={`30rem`}
                        content="Send Reset Password Link"
                        onClick={handleForgetPassword}
                    />
                </Form>
            </div>
        );
    };

    const renderSuccessPage = () => {
        return (
            <Header className="header_xs">
                Success! You have received an email containing a link to reset
                your password
            </Header>
        );
    };

    return (
        <PageWrapper>
            {emailSent ? renderSuccessPage() : renderEmailForm()}
        </PageWrapper>
    );
};

const PageWrapper = styled(PageContainer)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.white};
    height: 100vh;
    margin: 0;
    padding-top: 6.4rem;
`;

const Header = styled.div`
    text-align: center;
    margin-bottom: 8rem;
`;

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ errorMessage }) => (errorMessage ? `0` : `6.4rem`)};
`;

const Label = styled.label`
    white-space: nowrap;
    margin-right: 2.9rem;
`;

const Input = styled.input`
    width: 100%;
    height: 4rem;
    border: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};
    border-radius: 0.5rem;
    padding: 0.8rem 1.6rem;
`;

const ErrorMsg = styled.div`
    width: 100%;
    color: ${(props) => props.theme.colors.extra};
    display: ${({ errorMessage }) => (errorMessage ? `inline-block` : `none`)};
    align-items: center;
    margin-top: 0.8rem;
    margin-bottom: 3.2rem;
`;

export default ForgetPasswordPage;
