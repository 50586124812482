import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import PrimaryButton from '../../assets/styledComponents/Buttons/PrimaryButton';
import SecondaryButton from '../../assets/styledComponents/Buttons/SecondaryButton';

const UserInfoSection = ({
    handleSubmit,
    formFields,
    handleFormFieldsChange,
    isUserInfoEdited,
}) => {
    const history = useHistory();
    const { firstName, lastName, email } = formFields;

    return (
        <UserInfoContainer onSubmit={handleSubmit}>
            <FieldContainer className="firstName">
                <Label className="header_cabin_sm" htmlFor="firstName">
                    First Name
                </Label>
                <Input
                    className="body_cabin_sm"
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    required
                    value={
                        firstName || ''
                    } /* add empty string to prevent Warning: `value` prop on `input` should not be null.*/
                    onChange={handleFormFieldsChange}
                />
            </FieldContainer>
            <FieldContainer className="lastName">
                <Label className="header_cabin_sm" htmlFor="lastName">
                    Last Name
                </Label>
                <Input
                    className="body_cabin_sm"
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    required
                    value={
                        lastName || ''
                    } /* add empty string to prevent Warning: `value` prop on `input` should not be null.*/
                    onChange={handleFormFieldsChange}
                />
            </FieldContainer>
            <FieldContainer className="email">
                <Label className="header_cabin_sm">Email</Label>
                <Email className="body_cabin_sm">{email}</Email>
            </FieldContainer>
            <FieldContainer className="password">
                <Label className="header_cabin_sm">Password</Label>
                <SecondaryButton
                    content="Reset Password"
                    onClick={() => history.push('/request-new-password')}
                    width={`19rem`}
                />
            </FieldContainer>
            <PrimaryButton
                className="saveChanges"
                content="Save Changes"
                type="submit"
                disabled={!isUserInfoEdited}
            />
        </UserInfoContainer>
    );
};

const UserInfoContainer = styled.form`
    width: 100%;
    padding-top: 5.6rem;
    padding-bottom: 7.2rem;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;

    .firstName {
        margin-bottom: 3.2rem;
    }
    .lastName {
        margin-bottom: 4rem;
    }
    .email {
        margin-bottom: 2.8rem;
    }
    .password {
        margin-bottom: 6.8rem;
    }
    .resetPassword {
        text-decoration: underline;
    }

    @media only screen and (max-width: 767px) {
        label {
            text-align: left;
            margin-bottom: 0rem;
        }
        input {
            margin-bottom: 0.8rem;
        }
    }
`;

const FieldContainer = styled.div`
    margin: 0 auto;
    width: 50%;
    height: 4rem;
    color: #000000;
    display: grid;
    grid-template-columns: 25% 60%;
    gap: 3rem;

    @media only screen and (max-width: 767px) {
        display: flex;
        width: 60%;
        height: 8rem;
        flex-direction: column;
        gap: 0.8rem;
    } ;
`;

const Label = styled.label`
    text-align: right;
    line-height: 4rem;
    white-space: nowrap;
`;

const Input = styled.input`
    border: ${(props) => `0.1rem solid ${props.theme.colors.dark_grey}`};
    border-radius: 0.5rem;
    padding: 0.8rem 1.6rem;
    height: 4rem;
`;

const Email = styled.p`
    line-height: 4rem;
`;

export default UserInfoSection;
