import React from 'react';
import styled from '@emotion/styled';
import { connectHierarchicalMenu } from 'react-instantsearch-dom';
import formatCategoryLabel from 'functions/formatCategoryLabel';

const HierarchicalMenuList = ({ items, refine }) => {
    return (
        <Container className="body_cabin_xs">
            {items.map((item) => {
                if (item.isRefined && !item.items) {
                    return;
                }

                if (item.items) {
                    return (
                        <HierarchicalMenuList
                            key={item.label}
                            items={item.items}
                            refine={refine}
                        />
                    );
                }

                return (
                    <MenuItem
                        className="category-item"
                        key={item.label}
                        onClick={(event) => {
                            event.preventDefault();
                            refine(item.value);
                        }}
                    >
                        {formatCategoryLabel(item.label)}
                    </MenuItem>
                );
            })}
        </Container>
    );
};

const CustomHierarchicalMenu = connectHierarchicalMenu(HierarchicalMenuList);

export default CustomHierarchicalMenu;

const Container = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.6rem;

    @media screen and (max-width: 767px) {
        display: inline;
        overflow-x: scroll;
        /* hide scrollbar */
        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        white-space: nowrap;
        align-items: center;
        /* ignore parent div's padding */
        width: 100vw;
        margin-left: -2.4rem;
        margin-right: 2.4rem;
        padding-left: 2.4rem;
    }
`;

const MenuItem = styled.button`
    all: unset;
    cursor: pointer;
    color: ${(props) => props.theme.colors.secondary};
    background-color: ${(props) => props.theme.colors.light_grey};
    width: auto;
    height: 3.2rem;
    padding: 0.4rem 1.6rem;
    border-radius: 5rem;
    white-space: nowrap;
    margin-right: 0.8rem;

    &:hover {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.secondary};
    }
`;
