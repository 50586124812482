import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import SavedSearchCard from './SavedSearchCard';
import { useStaticDataPagination } from '../../../hooks/useStaticDataPagination';

const SavedSearchesContent = ({
    savedSearchesArray,
    deleteSavedSearch,
    openRenameModal,
    setOpenRenameModal,
    setCurrentSavedSearchTitle,
    setNewSavedSearchTitle,
    setOpenDeleteModal,
}) => {
    const { displayData, sentinel } = useStaticDataPagination(
        savedSearchesArray,
        12
    );

    return (
        <Fragment>
            <ContentContainer>
                {displayData &&
                    displayData.map(([title, content]) => (
                        <SavedSearchCard
                            key={title}
                            title={title}
                            content={content}
                            deleteSavedSearch={deleteSavedSearch}
                            openRenameModal={openRenameModal}
                            setOpenRenameModal={setOpenRenameModal}
                            setCurrentSavedSearchTitle={
                                setCurrentSavedSearchTitle
                            }
                            setNewSavedSearchTitle={setNewSavedSearchTitle}
                            setOpenDeleteModal={setOpenDeleteModal}
                        />
                    ))}
            </ContentContainer>
            <Ref ref={sentinel} />
        </Fragment>
    );
};

const ContentContainer = styled.div`
    padding: 3.2rem 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
`;

const Ref = styled.div`
    visibility: hidden;
    height: 1rem;
`;

export default SavedSearchesContent;
