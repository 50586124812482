import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useMutation, useQuery } from '@apollo/client';
import { useGlobalContext } from '../globalStore/globalContext';
import { UPDATE_SAVED_SEARCHES } from '../graphql/mutation';
import { updateSavedSearches } from '../globalStore/globalReducer';
import RenameSavedSearchModal from '../components/DashBoardPage/SavedSearchesPage/RenameSavedSearchModal';
import { GET_SAVED_SEARCHES } from '../graphql/queries';
import PageContainer from '../assets/styledComponents/PageContainer';
import EmptySavedSearchesContent from '../components/DashBoardPage/SavedSearchesPage/EmptySavedSearchesContent';
import SavedSearchesContent from '../components/DashBoardPage/SavedSearchesPage/SavedSearchesContent';
import DeleteSavedSearchModal from '../components/DashBoardPage/SavedSearchesPage/DeleteSavedSearchModal';
import {
    capitalizeEachWord,
    deleteExtraSpaces,
} from '../functions/formatString';

const SavedSearchesPage = () => {
    const { globalState, dispatch } = useGlobalContext();
    const { registeredUserId } = globalState;
    const { data: savedSearchesData } = useQuery(GET_SAVED_SEARCHES, {
        variables: { id: registeredUserId },
    });
    const [openRenameModal, setOpenRenameModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [savedSearches, setSavedSearches] = useState(null);
    const [currentSavedSearchTitle, setCurrentSavedSearchTitle] =
        useState(null);
    const [newSavedSearchTitle, setNewSavedSearchTitle] = useState(null);
    const [savedSearchesArray, setSavedSearchesArray] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (
            savedSearchesData &&
            savedSearchesData.registeredUser &&
            savedSearchesData.registeredUser.savedSearches
        ) {
            const { savedSearches } = savedSearchesData.registeredUser;
            setSavedSearches(savedSearches);
        }
        // eslint-disable-next-line
    }, [savedSearchesData]);

    useEffect(() => {
        if (savedSearches) {
            const savedSearchesArray = Object.entries(savedSearches);
            setSavedSearchesArray(savedSearchesArray);
        }
        // eslint-disable-next-line
    }, [savedSearches]);

    const handleSavedSearchTitleChange = (event) => {
        if (errorMessage) {
            setErrorMessage(null);
        }
        setNewSavedSearchTitle(event.target.value);
    };

    const [updateSavedSearch] = useMutation(UPDATE_SAVED_SEARCHES, {
        onCompleted: () => {
            setErrorMessage(null);
            dispatch({
                type: updateSavedSearches,
                payload: savedSearches,
            });
        },
        onError: (error) => console.error('update saved search error', error),
    });

    const deleteSavedSearch = () => {
        setOpenDeleteModal((prev) => !prev);
        const newSavedSearches = { ...savedSearches };
        delete newSavedSearches[currentSavedSearchTitle];
        setCurrentSavedSearchTitle(null);

        updateSavedSearch({
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: registeredUserId,
                        savedSearches: newSavedSearches,
                        __typename: 'updateRegisteredUserPayload',
                    },
                },
            },
            variables: {
                registeredUserId: registeredUserId,
                savedSearches: newSavedSearches,
            },
        });

        setSavedSearches(newSavedSearches);
        dispatch({
            type: updateSavedSearches,
            payload: newSavedSearches,
        });
    };

    const submitSavedSearch = (event) => {
        event.preventDefault();

        const trimmedTitle = deleteExtraSpaces(newSavedSearchTitle);
        if (!trimmedTitle) {
            return setErrorMessage('Please enter a valid name...');
        }
        const formattedTitle = capitalizeEachWord(trimmedTitle);

        if (savedSearches[formattedTitle]) {
            return setErrorMessage('This name is already existed...');
        }
        const newSavedSearches = { ...savedSearches };
        // create new key name and assign old value to it
        newSavedSearches[formattedTitle] =
            newSavedSearches[currentSavedSearchTitle];
        // delete object with old key name
        delete newSavedSearches[currentSavedSearchTitle];
        setOpenRenameModal((prev) => !prev);

        updateSavedSearch({
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: registeredUserId,
                        savedSearches: newSavedSearches,
                        __typename: 'updateRegisteredUserPayload',
                    },
                },
            },
            variables: {
                registeredUserId: registeredUserId,
                savedSearches: newSavedSearches,
            },
        });
        setSavedSearches(newSavedSearches);
        dispatch({
            type: updateSavedSearches,
            payload: newSavedSearches,
        });
    };

    const handleCancelDeleteSavedSearchesModal = () => {
        setOpenDeleteModal((prev) => !prev);
        setCurrentSavedSearchTitle(null);
    };

    return (
        <SavedSearchPageContainer isMobile={globalState.isMobile}>
            <HeaderContainer>
                <Header className="header_sm">Saved Searches</Header>
            </HeaderContainer>
            {openRenameModal && (
                <RenameSavedSearchModal
                    openRenameModal={openRenameModal}
                    currentSavedSearchTitle={currentSavedSearchTitle}
                    newSavedSearchTitle={newSavedSearchTitle}
                    setNewSavedSearchTitle={setNewSavedSearchTitle}
                    setOpenRenameModal={setOpenRenameModal}
                    handleSavedSearchTitleChange={handleSavedSearchTitleChange}
                    submitSavedSearch={submitSavedSearch}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}
            <DeleteSavedSearchModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                deleteSavedSearch={deleteSavedSearch}
                handleCancel={handleCancelDeleteSavedSearchesModal}
                savedSearchTitle={currentSavedSearchTitle}
            />
            {savedSearchesArray == null || !savedSearchesArray.length ? (
                <EmptySavedSearchesContent />
            ) : (
                <SavedSearchesContent
                    savedSearchesArray={savedSearchesArray}
                    deleteSavedSearch={deleteSavedSearch}
                    openRenameModal={openRenameModal}
                    setOpenRenameModal={setOpenRenameModal}
                    setCurrentSavedSearchTitle={setCurrentSavedSearchTitle}
                    setNewSavedSearchTitle={setNewSavedSearchTitle}
                    setOpenDeleteModal={setOpenDeleteModal}
                />
            )}
        </SavedSearchPageContainer>
    );
};

const SavedSearchPageContainer = styled(PageContainer)`
    width: 100%;
    min-height: 100vh;
    height: auto;
    margin-top: 0;
    padding: 0 3.2rem;
    padding-top: ${({ isMobile }) => (isMobile ? `0rem` : `4rem`)};
    background-color: ${(props) => props.theme.colors.light_grey};
    overflow: auto;
    /* Hide scroll bar for Safari and Chrome */
    ::-webkit-scrollbar {
        display: none;
    }
    /* Hide scroll bar for Firefox */
    scrollbar-width: none; /* Firefox */
`;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};
`;

const Header = styled.h1`
    padding: 2.4rem 0;
    color: ${(props) => props.theme.colors.primary};
    margin-right: 1.6rem;
`;

export default SavedSearchesPage;
