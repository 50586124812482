import styled from '@emotion/styled';

const SquareButton = styled.button`
    height: 2rem;
    padding: 0 1rem;
    margin: 0 1rem;
    color: #23263b;
    background-color: ${(props) =>
        props.disabled ? props.theme.colors.white : props.theme.colors.grey};
    font: inherit;
    font-size: 0.875rem;
    font-weight: 400;
    white-space: nowrap;
    place-items: center;
    text-align: center;
    text-decoration: none;
    border: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};
    border-radius: 0.3rem;
    box-sizing: border-box;
    box-shadow: 0 0.1rem 0 0 rgb(35 38 59 / 5%);
    cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
    disabled: ${(props) => (props.disabled ? props.disabled : false)};

    :hover {
        color: ${(props) =>
            props.disabled ? `inherit` : props.theme.colors.white};
    }
`;

export default SquareButton;
