import React from 'react';
// import styled from '@emotion/styled';
// import { usePdf } from '@mikecousins/react-pdf';
// import NextIcon from 'assets/images/arrow-right-icon.svg';
// import PrevIcon from 'assets/images/arrow-left-icon.svg';
// import SvgIcon from '../../../assets/styledComponents/SvgIcon';
import BladeContentContainer from '../../../assets/styledComponents/BladeContentContainer';
import ViewSourceButton from './ViewSourceButton';
// import { useGlobalContext } from '../../../globalStore/globalContext';

/**
 * Removed preview functionality of PDFs after partner requests
 * on March 23rd, 2023
 */

const PDF = ({ pdf }) => {
    // const { globalState } = useGlobalContext();
    // const { isMobile } = globalState;
    const pdfUrl = pdf.pdf_file.url;
    // const [page, setPage] = useState(1);
    // const canvasRef = useRef(null);

    // const { pdfDocument } = usePdf({
    //     file: pdfUrl,
    //     page,
    //     canvasRef,
    // });

    return (
        <BladeContentContainer>
            {/* {!pdfDocument && <span>Loading...</span>}
            <PdfContainer>
                {Boolean(pdfDocument && pdfDocument.numPages && !isMobile) && (
                    <nav>
                        <ul className="pager">
                            <li className="previous">
                                <SvgIcon
                                    disabled={page === 1}
                                    onClick={() => setPage(page - 1)}
                                    width={`2.4rem`}
                                    height={`2.4rem`}
                                    marginRight={`4rem`}
                                    icon={PrevIcon}
                                />
                            </li>
                        </ul>
                    </nav>
                )}
                <canvas ref={canvasRef} className="pdf-canvas" />
                {Boolean(pdfDocument && pdfDocument.numPages && !isMobile) && (
                    <nav>
                        <ul className="pager">
                            <li className="next">
                                <SvgIcon
                                    disabled={page === pdfDocument.numPages}
                                    onClick={() => setPage(page + 1)}
                                    width={`2.4rem`}
                                    height={`2.4rem`}
                                    marginLeft={`4rem`}
                                    icon={NextIcon}
                                />
                            </li>
                        </ul>
                    </nav>
                )}
            </PdfContainer>
            {Boolean(pdfDocument && pdfDocument.numPages && isMobile) && (
                <nav>
                    <MobileUL className="pager">
                        <li className="previous">
                            <SvgIcon
                                disabled={page === 1}
                                onClick={() => setPage(page - 1)}
                                width={`2.4rem`}
                                height={`2.4rem`}
                                marginRight={`4rem`}
                                icon={PrevIcon}
                            />
                        </li>
                        <li className="next">
                            <SvgIcon
                                disabled={page === pdfDocument.numPages}
                                onClick={() => setPage(page + 1)}
                                width={`2.4rem`}
                                height={`2.4rem`}
                                marginLeft={`4rem`}
                                icon={NextIcon}
                            />
                        </li>
                    </MobileUL>
                </nav>
            )} */}
            <ViewSourceButton url={pdfUrl} />
        </BladeContentContainer>
    );
};

// const PdfContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
// `;

// const MobileUL = styled.ul`
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-top: 2.4rem;
// `;

export default PDF;
