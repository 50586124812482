import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { connectSortBy } from 'react-instantsearch-dom';
import DropdownArrow from 'assets/images/search-chevron-icon.svg';

const SortBy = ({ items, refine, createURL }) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [selectedValue, setSelectedValue] = useState('Sort by Most Popular');
    const sortByViewDropdown = document.getElementById('sort-by-view-dropdown');
    const modalRef = useRef();

    // Close modal when click outside of the modal
    useEffect(() => {
        const closeModalClickOutside = (event) => {
            if (
                openDropdown &&
                !modalRef.current.contains(event.target) &&
                !sortByViewDropdown.contains(event.target)
            ) {
                setOpenDropdown(!openDropdown);
            }
        };
        document.addEventListener('mousedown', closeModalClickOutside);
        return () => {
            document.removeEventListener('mousedown', closeModalClickOutside);
        };
        // eslint-disable-next-line
    }, [openDropdown]);

    const Dropdown = () => {
        return (
            <DropdownList openDropdown={openDropdown} ref={modalRef}>
                {items.map((item) => (
                    <DropdownItem
                        key={item.value}
                        onClick={(event) => {
                            event.preventDefault();
                            refine(item.value);
                            setSelectedValue(item.label);
                        }}
                        selected={Boolean(selectedValue === item.label)}
                    >
                        <a href={createURL(item.value)}>{item.label}</a>
                    </DropdownItem>
                ))}
            </DropdownList>
        );
    };

    return (
        <ViewDropdown
            id="sort-by-view-dropdown"
            className="body_cabin_sm"
            isActive={openDropdown}
            onClick={() => setOpenDropdown(!openDropdown)}
        >
            <p className="body_cabin_sm">{selectedValue}</p>
            <img
                className="dropdown-image"
                src={DropdownArrow}
                alt="dropdown arrow"
            />
            <Dropdown />
        </ViewDropdown>
    );
};

const CustomSortBy = connectSortBy(SortBy);
export default CustomSortBy;

const ViewDropdown = styled.div`
    padding: 0.4rem 1.6rem;
    min-width: 210px;
    white-space: nowrap;
    width: 22rem;
    height: 3.2rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border: ${(props) =>
        props.isActive
            ? `1px solid ${props.theme.colors.secondary}`
            : `1px solid ${props.theme.colors.dark_grey}`};
    border-radius: 0.5rem;
    background-color: transparent;
    color: ${(props) => props.theme.colors.black};
    box-shadow: 0 0 1rem 0.1rem rgba(149, 149, 149, 0.1);

    :hover {
        box-shadow: 0 0 0.3rem 0.1rem #4574d0cc};
    }

    .dropdown-image {
        margin-left: 1.5rem;
        width: 1.6rem;
    }

    @media only screen and (max-width: 767px) {
        padding: 0 0.8rem;
        width: 100%;
        height: 3.2rem;
    }
`;

const DropdownList = styled.ul`
    position: absolute;
    display: ${({ openDropdown }) => (openDropdown ? `flex` : `none`)};
    flex-direction: column;
    list-style: none;
    background-color: ${(props) => props.theme.colors.white};
    width: fit-content;
    color: ${(props) => props.theme.colors.black};
    margin: 0;
    box-sizing: border-box;
    box-shadow: 0 0 1rem 0.1rem rgba(149, 149, 149, 0.1);
    border: ${(props) => `1px solid ${props.theme.colors.light_grey}`};
    border-radius: 0.5rem;
    padding: 1rem 0;
    transform: translate(-1.6rem, 3.4rem);
    z-index: 10;

    @media only screen and (max-width: 767px) {
        transform: translate(-3.2rem, 3.4rem);
    }
`;

const DropdownItem = styled.button`
    width: 100%;
    text-align: left;
    background-color: ${(props) => props.theme.colors.white};
    padding: 0.4rem 2.4rem;
    color: ${(props) =>
        props.selected
            ? props.theme.colors.secondary
            : props.theme.colors.black};
    :hover {
        background-color: ${(props) => props.theme.colors.light_grey};
    }
    > a,
    a:visited {
        color: inherit;
        text-decoration: none;
    }
`;
