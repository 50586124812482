import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import Explore from '../../../assets/images/explore-icon.svg';
import WithIconButton from '../../../assets/styledComponents/Buttons/WithIconButton';

const EmptySavedSearchesContent = () => {
    const history = useHistory();
    return (
        <EmptyContentContainer>
            <FirstLine className="header_xs">
                You have not saved any searches yet.
            </FirstLine>
            <SecondLine className="header_extra_medium">
                Explore CDR to get started!
            </SecondLine>
            <WithIconButton
                buttonText="Explore CDR"
                icon={Explore}
                onClick={() => history.push('/search')}
            />
        </EmptyContentContainer>
    );
};

const EmptyContentContainer = styled.div`
    padding-top: 12rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FirstLine = styled.p`
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 0.8rem;
`;
const SecondLine = styled.p`
    color: ${(props) => props.theme.colors.dark_grey};
    margin-bottom: 3.2rem;
`;

export default EmptySavedSearchesContent;
