import { initialState } from './globalContext';

const globalReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_USER_ID': {
            return { ...state, userId: action.payload };
        }
        case 'UPDATE_REGISTERED_USER_ID': {
            return { ...state, registeredUserId: action.payload };
        }
        case 'UPDATE_USER_INFO': {
            return { ...state, userInfo: action.payload };
        }
        case 'UPDATE_SAVED_SEARCHES': {
            return {
                ...state,
                savedSearches: action.payload,
            };
        }
        case 'UPDATE_BOARDS': {
            return {
                ...state,
                boards: action.payload,
            };
        }
        case 'CLEAR_GLOBAL_STATE': {
            return { ...state, ...initialState, isMobile: state.isMobile };
        }
        case 'UPDATE_IS_MOBILE': {
            return { ...state, isMobile: action.payload };
        }
        default: {
            throw new Error(`Invalid action type: ${action.type}`);
        }
    }
};

// action variables
export const updateUserId = 'UPDATE_USER_ID';
export const updateRegisteredUserId = 'UPDATE_REGISTERED_USER_ID';
export const updateUserInfo = 'UPDATE_USER_INFO';
export const updateSavedSearches = 'UPDATE_SAVED_SEARCHES';
export const updateBoards = 'UPDATE_BOARDS';
export const clearGlobalState = 'CLEAR_GLOBAL_STATE';
export const updateIsMobile = 'UPDATE_IS_MOBILE';

export default globalReducer;
