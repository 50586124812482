import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import CancelButton from '../../../assets/styledComponents/Buttons/CancelButton';
import DeleteOrRemoveButton from '../../../assets/styledComponents/Buttons/DeleteOrRemoveButton';
import CenteredModal from '../../../assets/styledComponents/CenteredModal';
import CloseModalButton from '../../../assets/styledComponents/CloseModalButton';
import ModalOverlay from '../../../assets/styledComponents/ModalOverlay';

const DeleteSavedSearchModal = ({
    openDeleteModal,
    setOpenDeleteModal,
    deleteSavedSearch,
    handleCancel,
    savedSearchTitle,
}) => {
    return (
        openDeleteModal && (
            <Fragment>
                <ModalOverlay
                    openModal={openDeleteModal}
                    setOpenModal={setOpenDeleteModal}
                />
                <CenteredModal width={`37.7vw`}>
                    <CloseModalButton
                        openModal={openDeleteModal}
                        setOpenModal={setOpenDeleteModal}
                    />
                    <Header className="header_xs">Delete Saved Search?</Header>
                    <Content>
                        <p>
                            Are you sure you want to delete{' '}
                            <span className="header_cabin_sm">
                                {savedSearchTitle}
                            </span>{' '}
                            ?
                        </p>
                        <ButtonsContainer>
                            <CancelButton onClick={handleCancel} />
                            <DeleteOrRemoveButton
                                content="Delete"
                                onClick={deleteSavedSearch}
                            />
                        </ButtonsContainer>
                    </Content>
                </CenteredModal>
            </Fragment>
        )
    );
};

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
        font-style: italic;
    }
`;

const Header = styled.h3`
    font-size: 2.2rem;
    margin-bottom: 1.6rem;
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;

    @media only screen and (max-width: 767px) {
        justify-content: center;
    }

    button {
        margin: 0;

        :first-of-type {
            margin-right: 0.8rem;
        }
    }
`;

export default DeleteSavedSearchModal;
