import React from 'react';
import PageHeader from '../assets/styledComponents/PageHeader';
import theme from '../assets/styles/theme';
import TermsText from '../components/TermsOfUse/TermsText';

const TermsOfUsePage = () => {
    return (
        <>
            <PageHeader color={theme.colors.primary} title="Privacy Policy" />
            <TermsText />
        </>
    );
};

export default TermsOfUsePage;
