import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import StyledLink from '../../assets/styledComponents/StyledLink';
import Body_Cabin_Xs from '../../assets/styledComponents/Typography/Body_Cabin_Xs';
import Error from '../../assets/images/error-icon.svg';
import PrimaryButton from '../../assets/styledComponents/Buttons/PrimaryButton';

const SignInForm = ({ signIn, closeModal, loadUserData }) => {
    const [signInForm, setSignInForm] = useState({ email: '', password: '' });
    const [signInErrorMessage, setSignInErrorMessage] = useState('');

    useEffect(() => {
        document.getElementById('form') &&
            document.getElementById('form').reset();
        setSignInErrorMessage('');
    }, [signIn]);

    const close = () => {
        closeModal();
    };

    const updateSignInForm = (e) => {
        setSignInErrorMessage('');
        setSignInForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const signInUser = async (url, data) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    };

    const submitSignInForm = (e) => {
        e.preventDefault();

        const { email, password } = signInForm;
        const signInData = {
            identifier: email,
            password: password,
        };
        const signInURL = `${process.env.REACT_APP_API_URL}/auth/local`;
        signInUser(signInURL, signInData).then((res) => {
            if (res.error) {
                console.error(
                    'Sign In Error: ',
                    res.message[0].messages[0].message
                );
                setSignInErrorMessage('Email or password is incorrect');
            } else {
                const token = res.jwt;
                localStorage.setItem('token', token);
                loadUserData(res.user._id);
                close();
            }
        });
    };

    return (
        <Form onSubmit={submitSignInForm}>
            <Label htmlFor="email">Email</Label>
            <Input
                id="email"
                name="email"
                type="email"
                onChange={updateSignInForm}
                required
                error={signInErrorMessage}
            />
            <Label htmlFor="password">Password</Label>
            <Input
                id="password"
                name="password"
                type="password"
                onChange={updateSignInForm}
                required
                error={signInErrorMessage}
            />
            {signInErrorMessage && (
                <ErrorMsg>
                    <ErrorIcon src={Error} alt="login error" />
                    <Body_Cabin_Xs>{signInErrorMessage}</Body_Cabin_Xs>
                </ErrorMsg>
            )}
            <ForgetPasswordLink to="/request-new-password" target="_blank">
                <Body_Cabin_Xs>Forgot password?</Body_Cabin_Xs>
            </ForgetPasswordLink>
            <PrimaryButton
                type="submit"
                large={true}
                content="Login"
                width={`100%`}
            />
        </Form>
    );
};

const Form = styled.form`
    padding: 0;
    margin: 0;
    text-align: center;
`;

const Label = styled.label`
    float: left;
    margin-bottom: 0.8rem;
`;

const Input = styled.input`
    padding: 0.8rem 1.6rem;
    margin-bottom: 3.2rem;
    width: 100%;
    height: 4rem;
    border: ${(props) =>
        props.error
            ? `0.1rem solid ${props.theme.colors.extra}`
            : `0.1rem solid ${props.theme.colors.grey}`};
    border-radius: 0.5rem;
    /* input text */
    font-family: 'CabinRegular400';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;

    &:focus {
        border-color: ${(props) => props.theme.colors.secondary};
    }

    &:last-of-type {
        margin-bottom: 0.8rem;
    }
`;

const ForgetPasswordLink = styled(StyledLink)`
    width: 100%;
    color: ${(props) => props.theme.colors.secondary};
    text-align: right;

    p {
        margin-bottom: 4.8rem;
    }
`;

const ErrorMsg = styled.div`
    width: 100%;
    color: ${(props) => props.theme.colors.extra};
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;
`;

const ErrorIcon = styled.img`
    width: 2rem;
    height: 2rem;
    margin-right: 0.6rem;
`;

export default SignInForm;
