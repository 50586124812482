import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import PageContainer from '../assets/styledComponents/PageContainer';
import PrimaryButton from 'assets/styledComponents/Buttons/PrimaryButton';

const NotFoundPage = () => {
    const history = useHistory();

    return (
        <PageWrapper>
            <Header className="header_large">404</Header>
            <SubHeader className="header_large">Oops. Page Not Found</SubHeader>
            <PrimaryButton
                content="Return to Home"
                onClick={() => history.push('/')}
            />
        </PageWrapper>
    );
};

const PageWrapper = styled(PageContainer)`
    margin-top: 24.8rem;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        margin-bottom: 30.4rem;
    }
`;

const Header = styled.h1`
    font-size: 14rem;
    line-height: 17.6rem;
    margin-bottom: 0.8rem;
    color: ${(props) => props.theme.colors.grey};
`;

const SubHeader = styled.h2`
    margin-bottom: 8rem;
    color: ${(props) => props.theme.colors.primary};
    text-align: center;
`;

export default NotFoundPage;
