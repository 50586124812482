import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_POPULAR_RESOURCES } from 'graphql/queries';
import { useGlobalContext } from 'globalStore/globalContext';
import styled from '@emotion/styled';
import Section from 'assets/styledComponents/Section';
import ResourceBlade from '../ResourceBlade';
import ResourceList from '../ResourceList';
import Header_Md from 'assets/styledComponents/Typography/Header_Md';
import OvalButton from 'assets/styledComponents/OvalButton';
import ResourceCard from 'components/ResourceCard';
import Oval from 'assets/images/oval.svg';

const PopularResources = ({
    setOpenResourceBlade,
    openResourceBlade,
    setIsHoverOnModal,
    slugFromURL,
    setSlugFromURL,
    resourceSlug,
    setResourceSlug,
    setCheckBoxState,
    boards,
    setCurrentResource,
    setOpenAddToBoardModal,
}) => {
    const history = useHistory();
    const [start, setStart] = useState(0);
    const [displayedResources, setDisplayedResources] = useState([]);
    const {
        data: resourcesData,
        loading: resourcesLoading,
        error: resourcesError,
    } = useQuery(GET_POPULAR_RESOURCES, {
        variables: { start },
    });
    const { globalState } = useGlobalContext();

    useEffect(() => {
        if (resourcesData?.resources.length > 0) {
            setDisplayedResources([
                ...displayedResources,
                ...resourcesData.resources,
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourcesData]);

    const handleLoadMore = useCallback(() => {
        const amountOfResourcesToLoad = 3;
        setStart(displayedResources.length + amountOfResourcesToLoad);
    }, [displayedResources]);

    const handleClick = (event, slug) => {
        event.preventDefault();

        if (slugFromURL) {
            setSlugFromURL(null);
        }
        setResourceSlug(slug);

        const currentPath = history.location.pathname;

        if (openResourceBlade) {
            return history.push({
                pathname: `${currentPath}`,
                search: `resource=${slug}`,
            });
        }
        if (!openResourceBlade) {
            setOpenResourceBlade(true);

            // concat resource param to the current URL
            const currentSearchParam = history.location.search;

            return history.push({
                pathname: `${currentPath}`,
                search: currentSearchParam
                    ? `${currentSearchParam}&resource=${slug}`
                    : `resource=${slug}`,
            });
        }
    };

    //felt personally that the cards looked better on mobile, so went with that here.
    return (
        <OuterContainer>
            <ShapeContainer>
                <Shape src={Oval} alt="decorative shape" />
            </ShapeContainer>
            <Container>
                <Header_Md>Popular Resources</Header_Md>

                {displayedResources.map((resource, index) =>
                    globalState.isMobile ? (
                        <ResourceCard
                            key={`${resource.id}-${index}`}
                            resource={resource}
                            handleClick={handleClick}
                            setCurrentResource={setCurrentResource}
                            setOpenAddToBoardModal={setOpenAddToBoardModal}
                            setCheckBoxState={setCheckBoxState}
                            boards={boards}
                        />
                    ) : (
                        <div key={`${resource.id}-${index}`}>
                            <ResourceList
                                resource={resource}
                                handleClick={handleClick}
                                setCurrentResource={setCurrentResource}
                                setOpenAddToBoardModal={setOpenAddToBoardModal}
                                setCheckBoxState={setCheckBoxState}
                                boards={boards}
                            />
                            {index !== displayedResources.length - 1 && (
                                <Separator />
                            )}
                        </div>
                    )
                )}
                {resourcesLoading && <pre>Loading...</pre>}
                {resourcesError && <pre>Error...</pre>}
                <LoadMoreButton onClick={handleLoadMore}>
                    <p>Load More</p>
                </LoadMoreButton>
                {openResourceBlade && (
                    <ResourceBlade
                        openResourceBlade={openResourceBlade}
                        setOpenResourceBlade={setOpenResourceBlade}
                        setIsHoverOnModal={setIsHoverOnModal}
                        slugFromURL={slugFromURL}
                        setSlugFromURL={setSlugFromURL}
                        resourceSlug={resourceSlug}
                        setResourceSlug={setResourceSlug}
                        handleClick={handleClick}
                    />
                )}
            </Container>
        </OuterContainer>
    );
};

const ShapeContainer = styled.div`
    position: absolute;
    width: 137.6rem;
    height: 76.8rem;
    @media only screen and (max-width: 1439px) {
        max-width: 95%;
        height: 76.8rem;
    }
`;

const Separator = styled.hr`
    margin: 2.4rem 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey};
`;

const OuterContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Shape = styled.img`
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
`;

const LoadMoreButton = styled(OvalButton)`
    border: none;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.tertiary};
    width: min-content;
    padding: 1.2rem 4rem;
    align-self: center;
    height: 4.8rem;
    margin-top: 4rem;

    :hover {
        background-color: ${(props) => props.theme.colors.tertiary_dark};
    }

    p {
        font-family: 'CabinSemibold600', sans-serif;
        margin: 0;
    }
    @media only screen and (max-width: 767px) {
        margin-top: 1.2rem;
    }
`;

const Container = styled(Section)`
    position: relative;
    max-width: 120rem;
    margin: 0 12rem 8rem 12rem;
    padding: 7.2rem 10.4rem 5.6rem 10.4rem;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 2rem;
    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
    z-index: 2;
    h2 {
        margin-bottom: 4rem;
        color: ${(props) => props.theme.colors.primary};
    }
    @media only screen and (max-width: 1439px) {
        margin: 0 6.4rem 8rem 6.4rem;
        padding: 5.6rem 8rem 5.6rem 8rem;
    }
    @media only screen and (max-width: 767px) {
        margin: 0;
        padding: 0 2.4rem 4.8rem 2.4rem;
        box-shadow: none;
        background-color: ${(props) => props.theme.colors.light_grey};
        row-gap: 1.6rem;
        h2 {
            margin-bottom: 1.6rem;
        }
    }
`;

export default PopularResources;
