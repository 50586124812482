import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useGlobalContext } from '../globalStore/globalContext';
import { updateBoards } from '../globalStore/globalReducer';
import { GET_BOARDS } from '../graphql/queries';
import { UPDATE_BOARDS } from '../graphql/mutation';
import {
    capitalizeEachWord,
    deleteExtraSpaces,
} from '../functions/formatString';
import CreateBoardModal from '../components/BoardModals/CreateBoardModal';
import BoardsContent from '../components/DashBoardPage/MyBoardsPage/BoardsContent';
import RenameBoardModal from '../components/BoardModals/RenameBoardModal';
import DeleteBoardModal from '../components/BoardModals/DeleteBoardModal';
import useCreateBoard from '../hooks/useCreateBoard';
import PageContainer from '../assets/styledComponents/PageContainer';
import EmptyBoardsContent from '../components/DashBoardPage/MyBoardsPage/EmptyBoardsContent';
import PrimaryCreateBoardButton from '../components/BoardModals/PrimaryCreateBoardButton';

const MyBoardsPage = () => {
    const { globalState, dispatch } = useGlobalContext();
    const { registeredUserId } = globalState;
    const { data: boardsData } = useQuery(GET_BOARDS, {
        variables: { id: registeredUserId },
    });
    const [openCreateBoardModal, setOpenCreateBoardModal] = useState(false);
    const [openRenameBoardModal, setOpenRenameBoardModal] = useState(false);
    const [openDeleteBoardModal, setOpenDeleteBoardModal] = useState(false);
    const [boardName, setBoardName] = useState(null);
    const [newBoardName, setNewBoardName] = useState(null);
    const [boards, setBoards] = useState(null);
    const [boardNamesArray, setBoardNamesArray] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const { createBoard } = useCreateBoard(
        setBoardName,
        boardName,
        setErrorMessage,
        boards,
        setBoards,
        setOpenCreateBoardModal,
        registeredUserId
    );

    useEffect(() => {
        if (
            boardsData &&
            boardsData.registeredUser &&
            boardsData.registeredUser.boards
        ) {
            const { boards } = boardsData.registeredUser;
            const boardNames = Object.keys(boards);
            setBoards(boards);
            setBoardNamesArray(boardNames);
        }
        // eslint-disable-next-line
    }, [boardsData]);

    const [editBoard] = useMutation(UPDATE_BOARDS, {
        onCompleted: () => {
            setBoardName(null);
            setNewBoardName(null);
        },
        onError: (error) => console.error('Rename board error: ', error),
    });

    const [removeBoard] = useMutation(UPDATE_BOARDS, {
        onCompleted: () => {
            setBoardName(null);
        },
        onError: (error) => console.error('Delete board error: ', error),
    });

    const renameBoard = (event) => {
        event.preventDefault();

        if (!newBoardName) {
            return setErrorMessage('Please enter a valid name...');
        }
        const trimmedBoardName = deleteExtraSpaces(newBoardName);
        const formattedBoardName = capitalizeEachWord(trimmedBoardName);
        if (boards && boards[formattedBoardName]) {
            return setErrorMessage('Board name already existed...');
        }
        // get the value from the old board name
        const oldBoardContent = boards[boardName];
        const newBoards = {
            ...boards,
            // reassign value to the new board name
            [formattedBoardName]: oldBoardContent,
        };
        // delete the old board name key value
        delete newBoards[boardName];

        setBoards(newBoards);
        setOpenRenameBoardModal(false);
        editBoard({
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: registeredUserId,
                        boards: newBoards,
                        __typename: 'RegisteredUser',
                    },
                    __typename: 'updateRegisteredUserPayload',
                },
            },
            variables: {
                registeredUserId: registeredUserId,
                boards: newBoards,
            },
        });
        dispatch({
            type: updateBoards,
            payload: newBoards,
        });
    };

    const deleteBoard = (event) => {
        event.preventDefault();
        let newBoards = {
            ...boards,
        };
        delete newBoards[boardName];

        setBoards(newBoards);
        setOpenDeleteBoardModal(false);
        removeBoard({
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: registeredUserId,
                        boards: newBoards,
                        __typename: 'RegisteredUser',
                    },
                    __typename: 'updateRegisteredUserPayload',
                },
            },
            variables: {
                registeredUserId: registeredUserId,
                boards: newBoards,
            },
        });
        dispatch({
            type: updateBoards,
            payload: newBoards,
        });
    };
    const handleBoardNameChange = (event) => {
        if (errorMessage) {
            setErrorMessage(null);
        }
        setBoardName(event.target.value);
    };

    const handleNewBoardNameChange = (event) => {
        if (errorMessage) {
            setErrorMessage(null);
        }
        setNewBoardName(event.target.value);
    };

    const handleCancelCreateBoard = () => {
        setOpenCreateBoardModal((prev) => !prev);
        if (errorMessage) {
            setErrorMessage(null);
        }
        setBoardName(null);
    };

    const handleCancelRenameBoard = () => {
        setOpenRenameBoardModal((prev) => !prev);
        if (errorMessage) {
            setErrorMessage(null);
        }
        setBoardName(null);
        setNewBoardName(null);
    };

    const handleCancelDeleteBoard = () => {
        setOpenDeleteBoardModal((prev) => !prev);
        setBoardName(null);
    };

    const handleCreateBoard = (event) => {
        event.preventDefault();
        setOpenCreateBoardModal((prev) => !prev);
    };

    return (
        <MyBoardsPageContainer isMobile={globalState.isMobile}>
            <HeaderContainer>
                <Header className="header_sm">My Boards</Header>
                {boardNamesArray != null && boardNamesArray?.length ? (
                    <PrimaryCreateBoardButton
                        content={'Create'}
                        onClick={handleCreateBoard}
                    />
                ) : null}
            </HeaderContainer>
            {!boardNamesArray?.length ? (
                <EmptyBoardsContent
                    setOpenCreateBoardModal={setOpenCreateBoardModal}
                />
            ) : (
                <BoardsContent
                    boardNamesArray={boardNamesArray}
                    setOpenCreateBoardModal={setOpenCreateBoardModal}
                    setBoardName={setBoardName}
                    setNewBoardName={setNewBoardName}
                    openRenameBoardModal={openRenameBoardModal}
                    setOpenRenameBoardModal={setOpenRenameBoardModal}
                    openDeleteBoardModal={openDeleteBoardModal}
                    setOpenDeleteBoardModal={setOpenDeleteBoardModal}
                    boards={boards}
                />
            )}
            <CreateBoardModal
                openCreateBoardModal={openCreateBoardModal}
                setOpenCreateBoardModal={setOpenCreateBoardModal}
                createBoard={createBoard}
                handleBoardNameChange={handleBoardNameChange}
                handleCancel={handleCancelCreateBoard}
                firstLayer={true}
                errorMessage={errorMessage}
            />
            <RenameBoardModal
                openRenameBoardModal={openRenameBoardModal}
                setOpenRenameBoardModal={setOpenRenameBoardModal}
                renameBoard={renameBoard}
                newBoardName={newBoardName}
                handleNewBoardNameChange={handleNewBoardNameChange}
                handleCancel={handleCancelRenameBoard}
                firstLayer={true}
                errorMessage={errorMessage}
            />
            <DeleteBoardModal
                openDeleteBoardModal={openDeleteBoardModal}
                setOpenDeleteBoardModal={setOpenDeleteBoardModal}
                deleteBoard={deleteBoard}
                handleCancel={handleCancelDeleteBoard}
                firstLayer={true}
                boardName={boardName}
            />
        </MyBoardsPageContainer>
    );
};

const MyBoardsPageContainer = styled(PageContainer)`
    margin-top: 0;
    padding: 0 3.2rem;
    padding-top: ${({ isMobile }) => (isMobile ? `0rem` : `4rem`)};
    background-color: ${(props) => props.theme.colors.light_grey};
    width: 100%;
    min-height: 100vh;
    height: auto;
    overflow: auto;
    max-height: 100vh;
    /* Hide scroll bar for Safari and Chrome */
    ::-webkit-scrollbar {
        display: none;
    }
    /* Hide scroll bar for Firefox */
    scrollbar-width: none; /* Firefox */
`;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};

    button {
        margin: 0;
        padding: 0.8rem 1.6rem;
    }
`;

const Header = styled.h1`
    padding: 2.4rem 0;
    color: ${(props) => props.theme.colors.primary};
    margin-right: 1.6rem;
`;

export default MyBoardsPage;
