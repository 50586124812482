import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { GlobalProvider } from './globalStore/globalContext';
import { ThemeProvider } from '@emotion/react';
import { client } from './apolloClient';
import theme from './assets/styles/theme';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';

function App() {
    return (
        <ApolloProvider client={client}>
            <GlobalProvider>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Routes />
                    </Router>
                </ThemeProvider>
            </GlobalProvider>
        </ApolloProvider>
    );
}

export default App;
