import React from 'react';
import styled from '@emotion/styled';
import {
    REFINEMENTS_LIST_ATTRIBUTE_TA,
    REFINEMENTS_LIST_ATTRIBUTE_HC,
    REFINEMENTS_LIST_ATTRIBUTE_AOF,
    REFINEMENTS_LIST_ATTRIBUTE_AGE,
    INITIAL_FACETS_TA,
    INITIAL_FACETS_HC,
    INITIAL_FACETS_AOF,
    INITIAL_FACETS_AGE,
    LIMIT_TA,
    LIMIT_HC,
    LIMIT_AOF,
    LIMIT_AGE,
} from '../../../algoliaConfigs';
import TargetAudienceCustomRefinementList from './TargetAudienceCustomRefinementList';
import HealthConditionCustomRefinementList from './HealthConditionCustomRefinementList';
import AgeCustomRefinementList from './AgeCustomRefinementList';
import AreaOfFocusCustomRefinementList from './AreaOfFocusCustomRefinementList';
import CloseModalButton from '../../../assets/styledComponents/CloseModalButton.js';

const RefinementListFilters = ({
    searchState,
    setShowDropdown,
    showDropdown,
}) => {
    return (
        <RefinementListContainer>
            <CloseModalButton
                setOpenModal={setShowDropdown}
                openModal={showDropdown}
            />
            <RefinementListWrapper>
                <p className="header_cabin_sm">
                    {REFINEMENTS_LIST_ATTRIBUTE_TA}
                </p>
                <TargetAudienceCustomRefinementList
                    attribute={REFINEMENTS_LIST_ATTRIBUTE_TA}
                    initialFacets={INITIAL_FACETS_TA}
                    searchState={searchState}
                    limit={LIMIT_TA}
                    operator="and"
                />
            </RefinementListWrapper>
            <RefinementListWrapper>
                <p className="header_cabin_sm">
                    {REFINEMENTS_LIST_ATTRIBUTE_HC}
                </p>
                <HealthConditionCustomRefinementList
                    attribute={REFINEMENTS_LIST_ATTRIBUTE_HC}
                    initialFacets={INITIAL_FACETS_HC}
                    searchState={searchState}
                    limit={LIMIT_HC}
                    operator="and"
                />
            </RefinementListWrapper>
            <RefinementListWrapper>
                <p className="header_cabin_sm">
                    {REFINEMENTS_LIST_ATTRIBUTE_AOF}
                </p>
                <AreaOfFocusCustomRefinementList
                    attribute={REFINEMENTS_LIST_ATTRIBUTE_AOF}
                    initialFacets={INITIAL_FACETS_AOF}
                    searchState={searchState}
                    limit={LIMIT_AOF}
                    operator="and"
                />
            </RefinementListWrapper>
            <RefinementListWrapper>
                <p className="header_cabin_sm">
                    {REFINEMENTS_LIST_ATTRIBUTE_AGE}
                </p>
                <AgeCustomRefinementList
                    attribute={REFINEMENTS_LIST_ATTRIBUTE_AGE}
                    initialFacets={INITIAL_FACETS_AGE}
                    searchState={searchState}
                    limit={LIMIT_AGE}
                    operator="and"
                />
            </RefinementListWrapper>
        </RefinementListContainer>
    );
};

const RefinementListContainer = styled.div`
    padding: 3.2rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 3.8rem;

    @media only screen and (max-width: 1439px) {
        grid-template-columns: repeat(3, auto);
        gap: 2.5rem;
    }
    @media only screen and (max-width: 1055px) {
        grid-template-columns: repeat(2, auto);
        gap: 2.5rem;
        height: auto;
    }
    @media only screen and (max-width: 767px) {
        grid-template-columns: repeat(1, auto);
        gap: 2.5rem;
        padding: 2.4rem;
    }
`;

const RefinementListWrapper = styled.div`
    display: flex;
    flex-direction: column;

    > p {
        margin-bottom: 1.6rem;
    }

    @media only screen and (max-width: 767px) {
        padding-bottom: 2.4rem;
        border-bottom: 0.1rem solid ${(props) => props.theme.colors.light_grey};
    }
`;

export default RefinementListFilters;
