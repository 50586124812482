import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { useGlobalContext } from '../globalStore/globalContext';
import { updateUserInfo } from '../globalStore/globalReducer';
import {
    UPDATE_REGISTERED_USER_NAME,
    UPDATE_USER_AVATAR,
    DELETE_AVATAR,
} from '../graphql/mutation';
import PageContainer from '../assets/styledComponents/PageContainer';
import NotificationModal from '../components/ProfilePage/NotificationModal';
import RemoveAvatarModal from '../components/ProfilePage/RemoveAvatarModal';
import AvatarSection from '../components/ProfilePage/AvatarSection';
import UserInfoSection from '../components/ProfilePage/UserInfoSection';

const ProfilePage = () => {
    const { globalState, dispatch } = useGlobalContext();
    const { firstName: oldFirstName, lastName: oldLastName } =
        globalState.userInfo;
    const { userInfo, registeredUserId, isMobile } = globalState;
    const [formFields, setFormFields] = useState(userInfo);
    const { firstName, lastName } = formFields;
    const hiddenFileInput = useRef(null);
    const [newAvatar, setNewAvatar] = useState(null);
    const [loadingAvatar, setLoadingAvatar] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationType, setNotificationType] = useState(null);
    const [openRemoveAvatarModal, setOpenRemoveAvatarModal] = useState(false);
    const [isUserInfoEdited, setIsUserInfoEdited] = useState(false);
    // prevent userInfo(in global state) disappear after refresh the page
    useEffect(() => {
        setFormFields(userInfo);
    }, [userInfo]);

    useEffect(() => {
        if (firstName !== oldFirstName || lastName !== oldLastName) {
            setIsUserInfoEdited(true);
        }
        // eslint-disable-next-line
    }, [firstName, lastName]);

    useEffect(() => {
        if (newAvatar) {
            uploadFile(newAvatar, userInfo.avatarId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newAvatar]);
    // Upload Avatar functions
    const [uploadUserAvatar] = useMutation(UPDATE_USER_AVATAR, {
        onCompleted: () => {
            setLoadingAvatar(false);
            setNotificationType('avatar');

            setOpenNotification(true),
                setTimeout(() => {
                    setOpenNotification(false);
                    setNotificationType(null);
                }, 2000);
        },
        onError: (error) => console.error('Upload avatar error: ', error),
    });

    const [updateUserName] = useMutation(UPDATE_REGISTERED_USER_NAME, {
        onCompleted: () => {
            dispatch({
                type: updateUserInfo,
                payload: formFields,
            });
            setNotificationType('username');

            setOpenNotification(true),
                setTimeout(() => {
                    setOpenNotification(false);
                    setNotificationType(null);
                }, 2000);
        },
        onError: (error) => console.error('Update user name error: ', error),
    });

    const uploadFile = (newAvatar, oldAvatarID) => {
        const formData = new FormData();
        formData.append('files', newAvatar);
        const request = new XMLHttpRequest();
        request.open('POST', `${process.env.REACT_APP_API_URL}/upload`);
        // called when an XMLHttpRequest transaction starts transferring data.
        request.onloadstart = () => {
            setLoadingAvatar(true);

            if (oldAvatarID) {
                deleteAvatar({
                    variables: {
                        registeredUserId: registeredUserId,
                        avatarId: oldAvatarID,
                    },
                });
            }
        };
        // called when an XMLHttpRequest transaction completes successfully.
        request.onload = () => {
            const response = JSON.parse(request.response);
            const avatarId = response[0]?.id;
            const avatarUrl = response[0]?.url;

            uploadUserAvatar({
                variables: {
                    registeredUserId: registeredUserId,
                    avatarId: avatarId,
                },
            });

            dispatch({
                type: updateUserInfo,
                payload: {
                    ...userInfo,
                    avatarId: avatarId,
                    avatarUrl: avatarUrl,
                },
            });
        };

        request.send(formData);
    };

    const handleFileChange = (event) => {
        const avatar = event.target.files[0];
        setNewAvatar(avatar);
    };

    const openFileSelect = () => {
        // clear value of target input so that it will be triggered by the onchange event even if the same path is selected
        hiddenFileInput.current.value = null;
        hiddenFileInput.current.click();
    };

    const [deleteAvatar] = useMutation(DELETE_AVATAR);

    const handleRemoveAvatar = async () => {
        const { avatarId } = userInfo;

        setOpenRemoveAvatarModal(false);

        // delete avatar relationship with user and the file from media library
        await deleteAvatar({
            variables: {
                registeredUserId: registeredUserId,
                avatarId: avatarId,
            },
        });

        dispatch({
            type: updateUserInfo,
            payload: {
                ...userInfo,
                avatarId: null,
                avatarUrl: null,
            },
        });
    };

    // Edit user name functions
    const handleFormFieldsChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value.trim(),
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsUserInfoEdited(false);
        updateUserName({
            optimisticResponse: {
                updateRegisteredUser: {
                    registeredUser: {
                        id: registeredUserId,
                        firstName: firstName,
                        lastName: lastName,
                        __typename: 'RegisteredUser',
                    },
                    __typename: 'updateRegisteredUserPayload',
                },
            },
            variables: {
                registeredUserId: registeredUserId,
                firstName: firstName,
                lastName: lastName,
            },
        });
    };

    return (
        <ProfileSection>
            {openNotification && (
                <NotificationModal
                    openNotification={openNotification}
                    setOpenNotification={setOpenNotification}
                    notificationType={notificationType}
                    isMobile={isMobile}
                />
            )}
            {openRemoveAvatarModal && (
                <RemoveAvatarModal
                    openModal={openRemoveAvatarModal}
                    setOpenModal={setOpenRemoveAvatarModal}
                    handleRemoveAvatar={handleRemoveAvatar}
                />
            )}

            <AvatarSection
                userInfo={userInfo}
                formFields={formFields}
                openFileSelect={openFileSelect}
                handleFileChange={handleFileChange}
                hiddenFileInput={hiddenFileInput}
                setOpenRemoveAvatarModal={setOpenRemoveAvatarModal}
                loadingAvatar={loadingAvatar}
            />
            <UserInfoSection
                handleSubmit={handleSubmit}
                handleFormFieldsChange={handleFormFieldsChange}
                formFields={formFields}
                isUserInfoEdited={isUserInfoEdited}
            />
        </ProfileSection>
    );
};

const ProfileSection = styled(PageContainer)`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .saveChanges {
        margin: 0;
        display: block;
        float: right;
    }
`;

export default ProfilePage;
