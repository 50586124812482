import { useEffect, useState, useRef } from 'react';

export const useStaticDataPagination = (data, numberPerPage) => {
    const dataLength = data?.length;
    const [displayData, setDisplayData] = useState(null);
    const [numberToDisplay, setNumberToDisplay] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    // Set display 12 entries initially
    useEffect(() => {
        const initialData = data?.slice(0, numberPerPage);
        setDisplayData(initialData);
        setNumberToDisplay(numberPerPage);
        // eslint-disable-next-line
    }, []);

    // Update current displayed data if data changes(deleted or renamed)
    useEffect(() => {
        const currentData = data?.slice(0, numberToDisplay);
        setDisplayData(currentData);
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        setHasMore(dataLength > displayData?.length);
        // eslint-disable-next-line
    }, [displayData]);

    const fetchMore = () => {
        const displayDataLength = displayData.length;
        setNumberToDisplay((prev) => prev + 12);
        // const numberToDisplay = displayDataLength + numberPerPage;
        const resourceToShow = data.slice(
            displayDataLength,
            displayDataLength + numberToDisplay
        );

        if (hasMore) {
            setDisplayData((prevData) => [...prevData, ...resourceToShow]);
            setHasMore(dataLength > displayData.length);
        }
    };

    // Track the scroll position
    const sentinel = useRef(null);
    // Create the Intersection Observer instance to observe when the element enters the page
    const onSentinelIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && hasMore) {
                // Retrieve more results
                fetchMore();
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(onSentinelIntersection, {});
        observer.observe(sentinel.current);
        // Clear the observer
        return () => observer.disconnect();

        // eslint-disable-next-line
    }, [IntersectionObserver, onSentinelIntersection]);

    return { displayData, sentinel };
};
