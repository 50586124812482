import styled from '@emotion/styled';
import React from 'react';
import { useEffect, useState } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import {
    uniqBy,
    LIMIT_HC,
    REFINEMENTS_LIST_ATTRIBUTE_HC,
} from '../../../algoliaConfigs';

/**
 * The code for display facets with no resources are
 * refer to https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/react/#display-facets-with-no-matches
 */

const RefinementList = ({
    attribute,
    items,
    refine,
    initialFacets,
    searchState,
}) => {
    const [combinedItems, setCombinedItems] = useState([]);
    let currentRefinement;

    if (
        searchState.refinementList &&
        searchState.refinementList['Health Condition'] &&
        searchState.refinementList['Health Condition'].length
    ) {
        currentRefinement = new Set();
        searchState.refinementList['Health Condition'].forEach((item) =>
            currentRefinement.add(item)
        );
    } else {
        currentRefinement = new Set();
    }
    // reset CurrentRefinements when select other refinementList filters that change resources
    useEffect(() => {
        const VALUE = [];
        const validItems = items.filter((item) => item.count > 0);
        if (currentRefinement.size > validItems.length) {
            currentRefinement.clear();
            items.forEach((item) => {
                if (item.count !== 0) {
                    currentRefinement.add(item.label);
                    VALUE.push(item.label);
                } else {
                    item.isRefined = false;
                }
                refine(VALUE);
            });
        }
        // eslint-disable-next-line
    }, [items]);

    // clear currentRefinement and checked filters when click on clear all button
    useEffect(() => {
        if (
            !Object.keys(searchState)?.length ||
            (searchState.refinementList &&
                searchState.refinementList[REFINEMENTS_LIST_ATTRIBUTE_HC] &&
                searchState.refinementList[REFINEMENTS_LIST_ATTRIBUTE_HC]
                    .length === 0)
        ) {
            currentRefinement.clear();
            document
                .querySelectorAll('.ais-RefinementList-item')
                .forEach((item) => {
                    item.classList.remove('ais-RefinementList-item--selected');
                    item.querySelector('input').checked = false;
                });
        }
        // eslint-disable-next-line
    }, [searchState]);

    // display facets with no resources
    useEffect(() => {
        setCombinedItems(
            uniqBy([...items, ...initialFacets], 'label')
                .slice(0, LIMIT_HC)
                .map((item) => {
                    // Here we re-calculate `isRefined` and `value` for each item
                    // based on `currentRefinement` which we cached at `toggle`.
                    const isRefined = currentRefinement.has(item.label);
                    const value =
                        isRefined === false
                            ? [...currentRefinement, item.label]
                            : [...currentRefinement].filter(
                                  (label) => label !== item.label
                              );

                    return {
                        ...item,
                        isRefined,
                        value,
                    };
                })
        );
        // eslint-disable-next-line
    }, [items, initialFacets]);

    const toggle = (item) => {
        // update `currentRefinement` on toggle
        currentRefinement.clear();
        item.value.forEach((value) => currentRefinement.add(value));
        // didn't use new Set([...]) because IE 11 doesn't support it.
        // ref: http://kangax.github.io/compat-table/es6/#test-Set
        refine(item.value);
    };

    return (
        <ul className="ais-RefinementList-list">
            {combinedItems.map((item) => (
                <StyledLi
                    key={`${attribute}-${item.label}`}
                    id={`${attribute}-${item.label}`}
                    className={`ais-RefinementList-item ${
                        item.isRefined
                            ? 'ais-RefinementList-item--selected'
                            : ''
                    }`}
                    disabled={Boolean(item.count === 0)}
                >
                    <label className="ais-RefinementList-label">
                        <input
                            className="ais-RefinementList-checkbox"
                            type="checkbox"
                            checked={item.isRefined}
                            onChange={() => toggle(item)}
                            disabled={Boolean(item.count === 0)}
                        />
                        <span className="ais-RefinementList-labelText">
                            {item.label}
                        </span>
                        <span className="ais-RefinementList-count">
                            {item.count}
                        </span>
                    </label>
                </StyledLi>
            ))}
        </ul>
    );
};

const HealthConditionCustomRefinementList =
    connectRefinementList(RefinementList);
export default HealthConditionCustomRefinementList;

const StyledLi = styled.li`
    color: ${({ disabled, theme }) =>
        disabled ? theme.colors.grey : theme.colors.primary};
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};

    label,
    input,
    span {
        cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
    }
`;
