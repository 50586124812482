import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import Body_Cabin_Xs from '../../assets/styledComponents/Typography/Body_Cabin_Xs';
import Error from '../../assets/images/error-icon.svg';
import PrimaryButton from '../../assets/styledComponents/Buttons/PrimaryButton';

const SignUpForm = ({ signIn, closeModal, loadUserData }) => {
    const [signUpForm, setSignUpForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        targetGroups: '',
    });
    const [errorMessage, setErrorMessage] = useState(null);
    const history = useHistory();

    useEffect(() => {
        document.getElementById('form') &&
            document.getElementById('form').reset();
        setErrorMessage(null);
    }, [signIn]);

    /* functions for the signup form section of the modal */
    const updateSignUpForm = (e) => {
        setErrorMessage(null);
        setSignUpForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const signUpUser = async (url, data) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    };

    const submitSignUpForm = (e) => {
        e.preventDefault();
        if (errorMessage) {
            setErrorMessage(null);
        }

        const {
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            targetGroups,
        } = signUpForm;

        const username = email;

        const signUpData = {
            username,
            email,
            password,
            firstName,
            lastName,
            targetGroups,
        };

        const signUpURL = `${process.env.REACT_APP_API_URL}/auth/local/register`;
        if (password?.length < 6) {
            setErrorMessage((prev) => ({
                ...prev,
                password: 'Your password must be at least 6 characters long',
            }));
        }
        if (password !== confirmPassword) {
            setErrorMessage((prev) => ({
                ...prev,
                password: 'The passwords do not match',
            }));
            return console.error(errorMessage);
        }

        signUpUser(signUpURL, signUpData).then((res) => {
            if (res.error) {
                const { id, message } = res.message[0].messages[0];
                setErrorMessage((prev) => ({ ...prev, [id]: message }));
            } else {
                const token = res.jwt;
                localStorage.setItem('token', token);
                loadUserData(res.user.id);
                history.push('/');
                closeModal();
            }
        });
    };

    return (
        <Form onSubmit={submitSignUpForm} id="form">
            <Grid2Columns>
                <div>
                    <Label htmlFor="firstName">First Name </Label>
                    <Input
                        id="firstName"
                        name="firstName"
                        type="firstName"
                        onChange={updateSignUpForm}
                        required
                    />
                </div>
                <div>
                    <Label htmlFor="lastName">Last Name </Label>
                    <Input
                        id="lastName"
                        name="lastName"
                        type="lastName"
                        onChange={updateSignUpForm}
                        required
                    />
                </div>
            </Grid2Columns>
            <Label htmlFor="email">Email</Label>
            <Input
                id="email"
                name="email"
                type="email"
                onChange={updateSignUpForm}
                required
                error={
                    errorMessage && errorMessage['Auth.form.error.email.taken']
                }
            />
            {errorMessage && errorMessage['Auth.form.error.email.taken'] && (
                <ErrorMsg>
                    <ErrorIcon src={Error} alt="signup error" />
                    <Body_Cabin_Xs>
                        {errorMessage['Auth.form.error.email.taken']}
                    </Body_Cabin_Xs>
                </ErrorMsg>
            )}
            <Label htmlFor="password">Password</Label>
            <Input
                id="password"
                name="password"
                type="password"
                pattern=".{6,}"
                title="Must be at least 6 characters long"
                required
                onChange={updateSignUpForm}
                error={errorMessage && errorMessage.password}
            />
            <Label htmlFor="confirmPassword">Confirm Password</Label>
            <Input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                required
                onChange={updateSignUpForm}
                error={errorMessage && errorMessage.password}
            />
            {errorMessage && errorMessage.password && (
                <ErrorMsg>
                    <ErrorIcon src={Error} alt="signup error" />
                    <Body_Cabin_Xs>{errorMessage.password}</Body_Cabin_Xs>
                </ErrorMsg>
            )}
            <Label>I&#39;m a... (Optional)</Label>
            <SelectWrapper>
                <Select
                    id="targetGroups"
                    onChange={(e) => {
                        setSignUpForm((prev) => ({
                            ...prev,
                            ['targetGroups']: e.target.value,
                        }));
                    }}
                >
                    <option value=""></option>
                    <option value="clinicians">Clinicians</option>
                    <option value="familiesCaregivers">
                        Families / Caregivers
                    </option>
                    <option value="decisionMakers">Decision makers</option>
                    <option value="childrenYouth">Children / Youth</option>
                    <option value="educators">Educators</option>
                    <option value="researchers">Researchers</option>
                    <option value="healthProfessionalStudents">
                        Health professional students
                    </option>
                    <option value="technicianEngineer">
                        Technician / Engineer
                    </option>
                    <option value="other">Other</option>
                </Select>
            </SelectWrapper>
            <PrimaryButton
                type="submit"
                large={true}
                content="Create Account"
                width={`100%`}
            />
        </Form>
    );
};

const Form = styled.form`
    padding: 0;
    margin: 0;

    a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.black};

        :hover {
            color: rgb(176, 176, 176);
        }
    }
`;

const Grid2Columns = styled.div`
    width: 100%;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.6rem;
    margin-bottom: 3.2rem;

    #lastName,
    #firstName {
        margin: 0;
    }
    @media only screen and (max-width: 76.7rem) {
        display: flex;
        flex-direction: column;

        #firstName {
            margin-bottom: 3.2rem;
        }
    }
    &:last-of-type {
        margin-bottom: 4.8rem;
    }
`;

const Label = styled.label`
    float: left;
    margin-bottom: 0.8rem;
`;

const InputStyle = `
    padding: 0.8rem 1.6rem;
    margin-bottom: 3.2rem;
    width: 100%;
    height: 4rem;
    border-radius: 0.5rem;
    /* input text */
    font-family: 'CabinRegular400';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;
`;

const Input = styled.input`
    ${InputStyle}
    border: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};

    :focus {
        border-color: ${(props) => props.theme.colors.secondary};
    }

    /* Change password input border color if passwords not matching */
    &[type='password'] {
        border-color: ${(props) =>
            props.error ? props.theme.colors.extra : props.theme.colors.grey};
        :last-of-type {
            margin-bottom: ${(props) => (props.error ? `0` : `3.2rem`)};
        }
    }
    /* Change email input border color if email is taken */
    &[type='email'] {
        border-color: ${(props) =>
            props.error ? props.theme.colors.extra : props.theme.colors.grey};
        margin-bottom: ${(props) => (props.error ? `0` : `3.2rem`)};
    }
`;

const SelectWrapper = styled.div`
    position: relative;
    &::before {
        border-style: solid;
        border-width: 0.15rem 0.15rem 0 0;
        border-color: ${(props) =>
            props.error ? props.theme.colors.extra : props.theme.colors.grey};
        content: '';
        display: inline-block;
        height: 1rem;
        position: absolute;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 1rem;
        top: 4.5rem;
        right: 2rem;
        transform: rotate(135deg);
    }
`;

const Select = styled.select`
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    ${InputStyle}
    border: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};
`;

const ErrorMsg = styled.div`
    width: 100%;
    color: ${(props) => props.theme.colors.extra};
    display: flex;
    align-items: center;
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;
`;

const ErrorIcon = styled.img`
    width: 2rem;
    height: 2rem;
    margin-right: 0.6rem;
`;

export default SignUpForm;
