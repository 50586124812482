import styled from '@emotion/styled';

const Body_Cabin_Xs = styled.p`
    font-family: 'CabinRegular400';
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
    color: ${(props) => props.color};
`;

export default Body_Cabin_Xs;
