import React from 'react';
import styled from '@emotion/styled';
import displayUserAvatar from '../../functions/displayUserAvatar';
import LoadingIcon from '../../assets/images/loading-spinner.svg';
import PrimaryButton from '../../assets/styledComponents/Buttons/PrimaryButton';
import DeleteOrRemoveButton from '../../assets/styledComponents/Buttons/DeleteOrRemoveButton';

const AvatarSection = ({
    userInfo,
    openFileSelect,
    handleFileChange,
    hiddenFileInput,
    setOpenRemoveAvatarModal,
    formFields,
    loadingAvatar,
}) => {
    const { avatarUrl } = formFields;
    const { firstName, lastName } = formFields;

    return (
        <AvatarContainer>
            {loadingAvatar ? (
                <LoadingAnimation
                    data={LoadingIcon}
                    aria-label="loading animation"
                />
            ) : (
                <Avatar src={displayUserAvatar(userInfo)} alt="user avatar" />
            )}
            <ButtonWrapper>
                <label htmlFor="avatar">
                    <PrimaryButton onClick={openFileSelect} content="Upload" />
                    <input
                        type="file"
                        name="avatar"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={handleFileChange}
                        ref={hiddenFileInput}
                    />
                </label>
                <DeleteOrRemoveButton
                    disabled={avatarUrl ? false : true}
                    onClick={() => setOpenRemoveAvatarModal(true)}
                    content="Remove"
                />
            </ButtonWrapper>
            <Header className="header_md">{`${firstName} ${lastName}`}</Header>
        </AvatarContainer>
    );
};

const AvatarCss = `
    height: 17.6rem;
    width: 17.6rem;
    object-fit: cover;
    border-radius: 50%;
`;

const AvatarContainer = styled.div`
    width: 100%;
    padding-top: 4.8rem;
    background-color: ${({ theme }) => theme.colors.light_grey};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1023px) {
        padding-top: 8rem;
    }
`;

const Avatar = styled.img`
    ${AvatarCss};
    margin-bottom: 2.4rem;
`;

const LoadingAnimation = styled.object`
    ${AvatarCss};
    margin-bottom: 2.4rem;
`;

const ButtonWrapper = styled.div`
    display: flex;
    margin-bottom: 4rem;

    label {
        margin-right: 1.6rem;
    }

    input {
        display: none;
    }
`;

const Header = styled.h1`
    margin-bottom: 4rem;
    color: ${(props) => props.theme.colors.black};
`;
export default AvatarSection;
