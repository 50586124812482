import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import CustomMenu from './CustomMenu';
import { SEARCH_CLIENT } from '../../../algoliaConfigs';

const UserSpecificModal = ({ attribute, openModal, setOpenModal }) => {
    const [initialFacets, setInitialFacets] = useState([]);
    const modalRef = useRef();

    useEffect(() => {
        SEARCH_CLIENT.searchForFacetValues([
            {
                indexName: 'cdr_resources',
                params: {
                    facetName: 'User Specific',
                    facetQuery: '',
                    maxFacetHits: 3,
                },
            },
        ]).then(([{ facetHits }]) => {
            setInitialFacets([...facetHits]);
        });
    }, []);

    // Close modal when click outside of the modal
    useEffect(() => {
        const closeModalClickOutside = (event) => {
            if (openModal && !modalRef.current.contains(event.target)) {
                setOpenModal(!openModal);
            }
        };
        document.addEventListener('mousedown', closeModalClickOutside);
        return () => {
            document.removeEventListener('mousedown', closeModalClickOutside);
        };
        // eslint-disable-next-line
    }, [openModal]);

    return (
        <ModalContent
            openModal={openModal}
            className="header_cabin_sm"
            ref={modalRef}
        >
            <CustomMenu
                categoriesLabelFromStrapi={initialFacets}
                attribute={attribute}
                defaultRefinement="Everyone"
                setOpenModal={setOpenModal}
                openModal={openModal}
            />
        </ModalContent>
    );
};

const ModalContent = styled.div`
    width: 16rem;
    padding: 1.2rem 0;
    position: absolute;
    top: 10;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.white};
    z-index: ${(props) => props.theme.zIndex.userSpecificDropdown};

    border-radius: 1rem;
    box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.25);

    /* use css to show and hide dropdown instead of mount and unmount component to keep searchState */
    display: ${({ openModal }) => (openModal ? 'block' : 'none')};
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;

export default UserSpecificModal;
