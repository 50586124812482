import React from 'react';
import styled from '@emotion/styled';
import BladeContentContainer from '../../../assets/styledComponents/BladeContentContainer';
import ViewSourceButton from './ViewSourceButton';

const Images = ({ images }) => {
    if (images == null) return null;
    const ImageWithLinkList = () =>
        images.map(({ id, url }) => (
            <ContentWrapper key={id}>
                <Image src={url} />
                <ViewSourceButton url={url} />
            </ContentWrapper>
        ));

    return (
        <BladeContentContainer>
            <ImageWithLinkList />
        </BladeContentContainer>
    );
};

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6.4rem;

    :last-of-type {
        margin-bottom: 0;
    }
`;

const Image = styled.img`
    max-width: 100%;
    object-fit: scale-down;
`;

export default Images;
