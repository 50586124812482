import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import ResourceCard from '../../ResourceCard';
import ResourceList from '../../ResourceList';
import { useStaticDataPagination } from 'hooks/useStaticDataPagination';

const IndividualBoardContent = ({
    resources,
    handleClick,
    layout,
    setCheckBoxState,
    boards,
    setCurrentResource,
    setOpenAddToBoardModal,
    setOpenDeleteModal,
}) => {
    const { displayData, sentinel } = useStaticDataPagination(resources, 12);

    if (layout === 'list') {
        return (
            <Fragment>
                <ResourceListContainer>
                    {displayData?.map((resource) => (
                        <ResourceList
                            key={resource.id}
                            resource={resource}
                            handleClick={handleClick}
                            setCurrentResource={setCurrentResource}
                            setOpenAddToBoardModal={setOpenAddToBoardModal}
                            setCheckBoxState={setCheckBoxState}
                            boards={boards}
                            setOpenDeleteModal={setOpenDeleteModal}
                        />
                    ))}
                </ResourceListContainer>
                <div ref={sentinel} />
            </Fragment>
        );
    }

    if (layout === 'grid') {
        return (
            <Fragment>
                <ResourceCardContainer>
                    {displayData?.map((resource) => (
                        <ResourceCard
                            key={resource.id}
                            resource={resource}
                            handleClick={handleClick}
                            setCurrentResource={setCurrentResource}
                            setOpenAddToBoardModal={setOpenAddToBoardModal}
                            setCheckBoxState={setCheckBoxState}
                            boards={boards}
                            setOpenDeleteModal={setOpenDeleteModal}
                        />
                    ))}
                </ResourceCardContainer>
                <div ref={sentinel} />
            </Fragment>
        );
    }
};

const ResourceCardContainer = styled.div`
    overflow-y: scroll;
    width: 100%;
    height: auto;
    margin-top: 3.2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1;
    gap: 3.2rem;
    margin-bottom: 5rem;

    & a {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
`;

const ResourceListContainer = styled.div`
    overflow-y: scroll;
    margin-right: 1.6rem;
    width: 100%;
    margin-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;

    @media only screen and (max-width: 767px) {
    }
`;

export default IndividualBoardContent;
