import styled from '@emotion/styled';
import React from 'react';

const PrimaryButton = ({ onClick, content, large, width, disabled }) => {
    return (
        <StyledPrimary
            onClick={onClick}
            large={large}
            width={width}
            disabled={disabled}
        >
            {content}
        </StyledPrimary>
    );
};

const StyledPrimary = styled.button`
    border: none;
    border-radius: 5rem;
    width: ${({ width }) => (width ? width : `auto`)};
    height: ${({ large }) => (large ? `4.8rem` : `4rem`)};
    color: ${(props) => props.theme.colors.primary};
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: ${({ large }) => (large ? `1.1rem` : `0.7rem`)};
    padding-bottom: ${({ large }) => (large ? `1.3rem` : `0.9rem`)};
    background-color: ${(props) => props.theme.colors.tertiary};
    :hover {
        background-color: ${(props) => props.theme.colors.tertiary_dark};
    }

    &:disabled {
        color: ${(props) => props.theme.colors.darker_grey};
        background-color: ${(props) => props.theme.colors.light_grey};
    }
`;

export default PrimaryButton;
