import styled from '@emotion/styled';

const Header_Sm = styled.h3`
    font-family: 'MontserratSemibold600';
    font-size: 2.4rem;
    line-height: 3.2rem;
    letter-spacing: 0;
    margin: 0;
    color: ${(props) => props.color};

    ::before {
        content: '';
        display: block;
        height: 150px;
        margin-top: -150px;
        visibility: hidden;
    }
`;

export default Header_Sm;
