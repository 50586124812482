import React from 'react';
import styled from '@emotion/styled';
import ModalOverlay from '../../assets/styledComponents/ModalOverlay';
import CloseModalButton from '../../assets/styledComponents/CloseModalButton';
import CenteredModal from '../../assets/styledComponents/CenteredModal';
import CancelButton from '../../assets/styledComponents/Buttons/CancelButton';
import PrimaryButton from '../../assets/styledComponents/Buttons/PrimaryButton';

const NameSavedSearchModal = ({
    openNameSavedSearchModal,
    setOpenNameSavedSearchModal,
    submitSavedSearch,
    savedSearchTitle,
    setSavedSearchTitle,
    handleSavedSearchTitleChange,
    errorMessage,
    setErrorMessage,
}) => {
    const handleCancel = () => {
        setSavedSearchTitle(null);
        setOpenNameSavedSearchModal(!openNameSavedSearchModal);
        setErrorMessage(null);
    };

    return (
        <div>
            <ModalOverlay
                openModal={openNameSavedSearchModal}
                setOpenModal={setOpenNameSavedSearchModal}
            />
            <CenteredModal width={`37.7vw`} firstLayer={false}>
                <CloseModalButton
                    openModal={openNameSavedSearchModal}
                    setOpenModal={setOpenNameSavedSearchModal}
                />
                <Header className="header_xs">Save Search</Header>
                <StyledForm onSubmit={submitSavedSearch}>
                    <p className="body_cabin_sm">Name your search</p>
                    <Input
                        type="text"
                        value={savedSearchTitle}
                        onChange={handleSavedSearchTitleChange}
                        className="body_cabin_sm"
                        onKeyPress={(event) =>
                            event.key === 'Enter' && submitSavedSearch(event)
                        }
                    />

                    <ErrorMessage
                        className="body_cabin_sm"
                        error={errorMessage}
                    >
                        {errorMessage}
                    </ErrorMessage>
                    <ButtonsContainer>
                        <CancelButton onClick={handleCancel} />
                        <PrimaryButton type="submit" content="Save" />
                    </ButtonsContainer>
                </StyledForm>
            </CenteredModal>
        </div>
    );
};

const StyledForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
        margin-top: 0.8rem;
    }
`;
const Header = styled.h3`
    margin-bottom: 2.4rem;
`;

const Input = styled.input`
    width: 100%;
    height: 4rem;
    margin-top: 1.5rem;
    border: ${(props) => `1px solid ${props.theme.colors.grey}`};
    padding: 0.8rem 1.6rem;
`;

const ErrorMessage = styled.p`
    display: ${({ error }) => (error ? `block` : `none`)};
    color: red;
    margin: 1rem 0;
    height: 2rem;
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;

    @media only screen and (max-width: 767px) {
        justify-content: center;
    }

    button {
        margin: 0;

        :first-of-type {
            margin-right: 0.8rem;
        }
    }
`;

export default NameSavedSearchModal;
