import React from 'react';
import ReactPlayer from 'react-player';
import BladeContentContainer from '../../../assets/styledComponents/BladeContentContainer';
import ViewSourceButton from './ViewSourceButton';

const Video = ({ video }) => {
    if (video == null) return null;

    const { videoLink } = video;

    return (
        <BladeContentContainer>
            <ReactPlayer url={videoLink} className="react-player" />
            <ViewSourceButton url={videoLink} />
        </BladeContentContainer>
    );
};

export default Video;
