import React from 'react';
import RecommendedResourceCard from './RecommendedResourceCard';

const RecommendedResourcesList = ({
    recommendedResources,
    handleClick,
    openResourceBlade,
    openRecommendedResourceBlade,
}) => {
    if (!recommendedResources.length) return null;
    return recommendedResources.map((recommendedResource) => (
        <RecommendedResourceCard
            key={recommendedResource.id}
            handleClick={handleClick}
            recommendedResource={recommendedResource}
            openResourceBlade={openResourceBlade}
            openRecommendedResourceBlade={openRecommendedResourceBlade}
        />
    ));
};

export default RecommendedResourcesList;
