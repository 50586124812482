import React from 'react';
import CreateBoardIcon from '../../assets/images/create-board-icon.svg';
import styled from '@emotion/styled';

const CreateBoardButton = ({
    title,
    handleClick,
    width,
    justifyContent,
    isMobile,
}) => {
    return (
        <CreateBoardWrapper
            onClick={handleClick}
            width={width}
            justifyContent={justifyContent}
            isMobile={isMobile}
        >
            <img src={CreateBoardIcon} alt="create board icon" />
            <CreateBoardText>{title}</CreateBoardText>
        </CreateBoardWrapper>
    );
};

const CreateBoardWrapper = styled.button`
    width: ${(props) => props.width};
    display: flex;
    align-items: center;
    border: none;
    line-height: 2.5rem;
    padding: 1.2rem 2.4rem;
    background-color: ${(props) => props.theme.colors.light_grey};
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-bottom: 4rem;
    cursor: pointer;
    img {
        width: 1.4rem;
        line-height: 2.4rem;
        margin-right: 1rem;
    }
    ${(props) =>
        props.isMobile &&
        `
    margin-bottom: 2rem;
    `}
`;

const CreateBoardText = styled.p`
    margin: 0;
    color: ${(props) => props.theme.colors.primary};
    font-family: 'CabinSemibold600', sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;
`;

export default CreateBoardButton;
