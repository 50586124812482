import React from 'react';
import styled from '@emotion/styled';

export default function HamburgerMenu({
    isMobileNavOpen,
    handleClick,
    isNavBarWhite,
}) {
    return (
        <IconWrapper
            id="mobile-navbar"
            className={isMobileNavOpen ? 'change' : ' '}
            isNavBarWhite={isNavBarWhite}
            isMobileNavOpen={isMobileNavOpen}
            data-cy="menuIcon"
            onClick={handleClick}
        >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </IconWrapper>
    );
}

const IconWrapper = styled.div`
    display: inline-block;
    cursor: pointer;
    position: relative;
    z-index: 2;
    height: 1.2rem;
    width: 1.6rem;
    .bar1,
    .bar2,
    .bar3 {
        display: block;
        position: absolute;
        width: 1.6rem;
        height: 2px;
        background-color: ${(props) =>
            props.isMobileNavOpen
                ? 'white'
                : props.isNavBarWhite
                ? `${props.theme.colors.primary}`
                : 'white'};
        transition: 0.4s;
    }
    .bar1 {
        top: 0rem;
    }
    .bar2 {
        top: 0.5rem;
    }
    .bar3 {
        top: 1rem;
    }
    &.change .bar1 {
        -moz-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 0.5rem;
    }
    &.change .bar2 {
        opacity: 0;
    }
    &.change .bar3 {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0.5rem;
    }
`;
