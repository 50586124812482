import styled from '@emotion/styled';

const Header_Md = styled.h2`
    font-family: 'MontserratBold700';
    font-size: 3.2rem;
    line-height: 4rem;
    letter-spacing: 0;
    margin: 0;
    color: ${(props) => props.color};
`;

export default Header_Md;
