import React from 'react';
import Images from './Images';
import PDF from './PDF';
import Video from './Video';
import Text from './Text';
import ExternalLinks from './ExternalLinks';
import BladeContentContainer from '../../../assets/styledComponents/BladeContentContainer';

const MediaContentSection = ({ media_type }) => {
    if (!media_type) return null;
    let [images, pdf, externalLinks, text, video] = new Array(5).fill(null);

    externalLinks = [];

    media_type?.forEach((obj) => {
        let type = obj.__typename;
        switch (type) {
            case 'ComponentMediaZoneImage':
                images = obj.images;
                break;
            case 'ComponentMediaZonePdf':
                pdf = obj;
                break;
            case 'ComponentMediaZoneExternalLink':
                externalLinks.push(obj);
                break;
            case 'ComponentMediaZoneText':
                text = obj;
                break;
            case 'ComponentMediaZoneVideo':
                video = obj;
                break;
            default:
                break;
        }
    });

    return (
        <BladeContentContainer>
            {pdf != null ? <PDF pdf={pdf} /> : null}
            <Images images={images} />
            <ExternalLinks externalLinks={externalLinks} />
            <Text text={text} />
            <Video video={video} />
        </BladeContentContainer>
    );
};

export default MediaContentSection;
