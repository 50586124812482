import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { GET_RESOURCE_BY_SLUG } from 'graphql/queries';
import { UPDATE_BOARDS, UPDATE_VIEW_COUNTS } from 'graphql/mutation';
import { useGlobalContext } from 'globalStore/globalContext';
import { updateBoards } from 'globalStore/globalReducer';
import getCheckState from 'functions/getCheckState';
import getBoardsFromCheckbox from 'functions/getBoardsFromCheckbox';
import resourceTypeIconPath from './resourceTypeIconPath';
import Add from 'assets/images/add-from-blade-icon.svg';
import Author from 'assets/images/author-icon.svg';
import Location from 'assets/images/location-icon.svg';

import ViewCount from 'assets/images/view-counts.svg';
import ModalOverlay from 'assets/styledComponents/ModalOverlay';
import IconButton from 'assets/styledComponents/IconButton';
import CloseModalButton from 'assets/styledComponents/CloseModalButton';
import KeywordsSection from './KeywordsSection';
import MediaContentSection from './MediaContentSection/MediaContentSection';
import RecommendedForYouSection from './RecommendedForYouSection/RecommendedForYouSection';
import AddToBoardModal from '../BoardModals/AddToBoardModal';
import CreateBoardModal from '../BoardModals/CreateBoardModal';
import useCreateBoard from 'hooks/useCreateBoard';
import BlueAdd from 'assets/images/blue-add-to-board-icon.svg';
import resourcesSet from 'functions/resourcesSet';

const ResourceBlade = ({
    handleClick,
    openResourceBlade,
    setOpenResourceBlade,
    setIsHoverOnModal,
    resourceSlug,
    setResourceSlug,
    slugFromURL,
    setSlugFromURL,
    myBoardName,
}) => {
    const scrollTopRef = useRef();
    const client = useApolloClient();
    const history = useHistory();
    const timer = useRef();
    const loginButton = document.getElementById('login-button');
    const { globalState, dispatch } = useGlobalContext();
    const { registeredUserId, boards: oldBoards, isMobile } = globalState;
    const [increaseViewCounts] = useMutation(UPDATE_VIEW_COUNTS);
    const { data: resourceData } = useQuery(GET_RESOURCE_BY_SLUG, {
        variables: { slug: slugFromURL || resourceSlug },
        skip: slugFromURL || resourceSlug ? false : true,
    });
    const [oldResourceSlug, setOldResourceSlug] = useState(null);
    const [openAddToBoardModal, setOpenAddToBoardModal] = useState(false);
    const [openCreateBoardModal, setOpenCreateBoardModal] = useState(false);
    const [boards, setBoards] = useState(oldBoards);
    const [boardName, setBoardName] = useState(null);
    const [checkBoxState, setCheckBoxState] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [addedToBoard, setAddedToBoard] = useState(false);
    const { createBoard } = useCreateBoard(
        setBoardName,
        boardName,
        setErrorMessage,
        boards,
        setBoards,
        setOpenCreateBoardModal,
        registeredUserId
    );
    const [addResource] = useMutation(UPDATE_BOARDS);
    const numberFormat = new Intl.NumberFormat();

    const scrollBladeToTop = () => {
        const topAnchor = scrollTopRef.current;
        topAnchor.scrollIntoView();
    };

    useEffect(() => {
        // Get current resource viewCounts from Apollo cache
        const cachedResource = client.readQuery({
            query: GET_RESOURCE_BY_SLUG,
            variables: { slug: slugFromURL || resourceSlug },
        });

        // Add 1 to cached viewCounts when resource blade is opened
        if (openResourceBlade && cachedResource) {
            const cachedViewCounts = cachedResource.resources[0].viewCounts;
            const updatedViewCounts = Number(cachedViewCounts) + 1;
            increaseViewCounts({
                variables: {
                    id: id,
                    viewCounts: updatedViewCounts,
                },
                optimisticResponse: {
                    updateResource: {
                        resource: {
                            id: id,
                            viewCounts: updatedViewCounts,
                            __typename: 'Resource',
                        },
                        __typename: 'updateResourcePayload',
                    },
                },
            });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (resourceData && resourceData.resources[0]) {
            const id = resourceData.resources[0].id;
            const checkState = getCheckState(id, globalState.boards);
            setCheckBoxState(checkState);
            setAddedToBoard(resourcesSet(globalState.boards)?.has(Number(id)));
        }
        // eslint-disable-next-line
    }, [resourceData]);

    const resource = resourceData?.resources[0];

    useEffect(() => {
        if (resourceData && resourceData.resources[0]) {
            const id = resourceData.resources[0].id;
            setAddedToBoard(resourcesSet(globalState.boards)?.has(Number(id)));
        }
    }, [globalState.boards, resourceData]);

    if (!resource) return null;

    const {
        id,
        title,
        author,
        location,
        viewCounts,
        keywords,
        resource_type,
        date_of_last_update,
        media_type,
        recommended_for_you,
        description,
        image,
        slug,
        user_specifics,
        audience_levels,
    } = resource;

    const handleBoardNameChange = (event) => {
        if (errorMessage) {
            setErrorMessage(null);
        }
        setBoardName(event.target.value);
    };

    const closeAddToBoardModal = () => {
        setOpenAddToBoardModal((prev) => !prev);
        const checkState = getCheckState(id, boards);
        setCheckBoxState(checkState);
    };

    const currentResource = {
        id,
        title,
        author,
        description,
        image,
        slug,
        date_of_last_update,
        resource_type,
        audience_levels,
        view: user_specifics?.map((user) => user.user),
    };

    const addResourcesToBoards = (event) => {
        event.preventDefault();

        setOpenAddToBoardModal(false);
        const newBoards = getBoardsFromCheckbox(
            checkBoxState,
            boards,
            id,
            currentResource
        );
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            addResource({
                optimisticResponse: {
                    updateRegisteredUser: {
                        registeredUser: {
                            id: registeredUserId,
                            boards: newBoards,
                            __typename: 'RegisteredUser',
                        },
                        __typename: 'updateRegisteredUserPayload',
                    },
                },
                variables: {
                    registeredUserId: registeredUserId,
                    boards: newBoards,
                },
            });
        }, 500);
        dispatch({
            type: updateBoards,
            payload: newBoards,
        });
    };

    const openRecommendedResourceBlade = (event, newSlug) => {
        setOldResourceSlug(resourceSlug);
        scrollBladeToTop();
        handleClick(event, newSlug);
    };

    const deleteResourceParam = () => {
        const currentPath = history.location.pathname;
        const searchParam = history.location.search;
        const params = new URLSearchParams(searchParam);
        params.delete('resource');
        const newParam = params.toString();

        history.push({
            pathname: `${currentPath}`,
            search: `${newParam}`,
            state: {
                // passing name as location state through react router
                name: myBoardName ? myBoardName : '',
            },
        });
    };

    const closeModalForButton = () => {
        if (oldResourceSlug && resourceSlug !== oldResourceSlug) {
            // go back to old resource blade if old resource id exists
            history.goBack();
            setResourceSlug(oldResourceSlug);
        } else {
            setOpenResourceBlade(false);
            setResourceSlug(null);
            setOldResourceSlug(null);
            setSlugFromURL(null);
            deleteResourceParam();
        }
    };

    const closeModalForBackground = () => {
        setOpenResourceBlade(false);
        setResourceSlug(null);
        setOldResourceSlug(null);
        setSlugFromURL(null);
        deleteResourceParam();
    };

    const cancelCreateBoard = (event) => {
        event.preventDefault();
        if (errorMessage) {
            setErrorMessage(null);
        }
        setOpenCreateBoardModal((prev) => !prev);
        setOpenAddToBoardModal((prev) => !prev);
    };

    const handleCreateBoard = (event) => {
        const newBoard = createBoard(event);
        if (newBoard) {
            setOpenAddToBoardModal((prev) => !prev);
        }
    };

    const handleClickAddToBoard = async (event) => {
        event.preventDefault();

        if (registeredUserId) {
            setOpenAddToBoardModal(true);
        } else {
            // redirect to sign in modal if user's not logged in
            const isMobile = globalState.isMobile;

            if (isMobile) {
                await document.getElementById('mobile-navbar').click();
                document.getElementById('login-button').click();
            } else {
                loginButton.click();
            }
        }
    };

    return (
        <Fragment>
            {!isMobile && (
                <ModalOverlay
                    openModal={openResourceBlade}
                    setOpenModal={setOpenResourceBlade}
                    closeModal={closeModalForBackground}
                />
            )}
            <ModalContent
                onMouseEnter={() => setIsHoverOnModal(true)}
                onMouseLeave={() => setIsHoverOnModal(false)}
                isMobile={isMobile}
            >
                <ScrollAnchor ref={scrollTopRef} />
                <CloseModalButton
                    id="close-button"
                    openModal={openResourceBlade}
                    setOpenModal={setOpenResourceBlade}
                    closeModal={closeModalForButton}
                />
                <Header className="header_sm">{title}</Header>
                <MetaDataContainer className="body_cabin_xs">
                    <FirstLineWrapper>
                        <MetaDataWrapper>
                            <DataWithIcon>
                                <img src={Author} alt="author" />
                                <span>{author}</span>
                            </DataWithIcon>
                            <DataWithIcon>
                                <img src={Location} alt="location" />
                                <span>{location}</span>
                            </DataWithIcon>
                            <DataWithIcon>
                                <img
                                    src={
                                        resourceTypeIconPath[resource_type].path
                                    }
                                    alt={
                                        resourceTypeIconPath[resource_type]
                                            .title
                                    }
                                />
                                <span>
                                    {resourceTypeIconPath[resource_type].title}
                                </span>
                            </DataWithIcon>
                            <DataWithIcon>
                                <img src={ViewCount} alt="view counts" />
                                <span>{numberFormat.format(viewCounts)}</span>
                            </DataWithIcon>
                        </MetaDataWrapper>
                        <Button
                            icon={addedToBoard ? BlueAdd : Add}
                            onClick={handleClickAddToBoard}
                        />
                    </FirstLineWrapper>
                </MetaDataContainer>
                <Description
                    className="body_cabin_sm"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                <MediaContentSection media_type={media_type} />
                <KeywordsSection keywords={keywords} />
                <RecommendedForYouSection
                    handleClick={handleClick}
                    recommendedResources={recommended_for_you}
                    openResourceBlade={openResourceBlade}
                    openRecommendedResourceBlade={openRecommendedResourceBlade}
                    setResourceSlug={setResourceSlug}
                />
            </ModalContent>
            <AddToBoardModal
                openAddToBoardModal={openAddToBoardModal}
                setOpenAddToBoardModal={setOpenAddToBoardModal}
                setOpenCreateBoardModal={setOpenCreateBoardModal}
                addResourcesToBoards={addResourcesToBoards}
                checkBoxState={checkBoxState}
                setCheckBoxState={setCheckBoxState}
                createBoard={createBoard}
                closeAddToBoardModal={closeAddToBoardModal}
            />
            <CreateBoardModal
                openCreateBoardModal={openCreateBoardModal}
                setOpenCreateBoardModal={setOpenCreateBoardModal}
                handleBoardNameChange={handleBoardNameChange}
                createBoard={handleCreateBoard}
                firstLayer={false}
                errorMessage={errorMessage}
                handleCancel={cancelCreateBoard}
            />
        </Fragment>
    );
};

const ScrollAnchor = styled.div`
    width: 100%;
    margin-bottom: 4rem;
`;

const ModalContent = styled.div`
    width: 50vw;
    max-width: 146.4rem;
    height: calc(80vh - 9.6rem);
    padding: 0 7.2rem 7.4rem 7.2rem;
    border-radius: 5rem 0 0 5rem;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.35) 0 0.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: ${(props) => props.theme.zIndex.resourceBlade};
    position: fixed;
    top: ${({ isMobile }) => (isMobile ? `1.6rem` : `3.2rem`)};
    right: 0;
    bottom: 0;

    @media only screen and (max-width: 1439px) {
        width: 80vw;
        padding: 0 4.8rem 7.4rem 4.8rem;
    }

    @media only screen and (max-width: 767px) {
        width: 100vw;
        height: 100vh;
        border-radius: 0;
        padding: 0 2.4rem 7.4rem 2.4rem;
    }
`;

const Header = styled.h2`
    margin-bottom: 1.6rem;
    margin-top: ${({ isMobile }) => (isMobile ? `1.5rem` : `3rem`)};
`;

const MetaDataContainer = styled.div`
    margin-bottom: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.darker_grey};
`;

const FirstLineWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;
`;

const MetaDataWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 90%;
    margin-bottom: 0.8rem;
    gap: 0.8rem 2.4rem;

    p:last-of-type {
        margin-left: 2.4rem;
        font-style: italic;
    }

    @media only screen and (max-width: 767px) {
        p:last-of-type {
            margin-left: 0;
        }

        > div,
        p {
            margin-bottom: 1rem;
        }
    }
`;

const DataWithIcon = styled.div`
    display: flex;
    align-items: center;

    img {
        margin-right: 0.4rem;
    }
    span {
        width: fit-content;
    }
`;

const Description = styled.p`
    margin-bottom: 6.4rem;
`;

const Button = styled(IconButton)`
    width: 2.2rem;
    height: 2.2rem;

    svg {
        width: 100%;
        height: 100%;
    }

    &:hover {
        background-image: url(${BlueAdd});
        transition: background-image 0.2s ease-in-out;
        transform: scale(1.1);
    }
`;

export default ResourceBlade;
