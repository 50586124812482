import theme from '../assets/styles/theme';

/** following function is referred to dcode tutorial: https://www.youtube.com/watch?v=bUlK9yNJ3js */

// generate avatar from users' initial
const generateAvatarPlaceholder = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : '';
    const lastInitial = lastName ? lastName[0].toUpperCase() : '';
    const initial = firstInitial + lastInitial;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = 200;
    canvas.height = 200;

    // Draw background
    context.fillStyle = theme.colors.darker_grey;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.font = 'bold 8rem MontserratSemibold600';
    context.fillStyle = theme.colors.white;
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(initial, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL('image/png');
};

export default generateAvatarPlaceholder;
