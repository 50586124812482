import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import PageContainer from '../assets/styledComponents/PageContainer';
import PrimaryButton from '../assets/styledComponents/Buttons/PrimaryButton';

const ResetPasswordPage = () => {
    const [form, setForm] = useState({ password: '', confirmPassword: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();

    const updateForm = (e) => {
        setErrorMessage('');
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleResetPassword = (e) => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const code = params.get('code');

        e.preventDefault();
        const resetPasswordFormData = {
            code: code,
            password: form.password,
            passwordConfirmation: form.confirmPassword,
        };

        const resetPasswordURL = `${process.env.REACT_APP_API_URL}/auth/reset-password`;

        const resetPassword = async (url, data) => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            return response.json();
        };

        if (form.password.length < 6) {
            setErrorMessage('Password must be at least 6 characters long');
        } else if (form.password !== form.confirmPassword) {
            setErrorMessage('Passwords do not match');
        } else {
            resetPassword(resetPasswordURL, resetPasswordFormData).then(
                (res) => {
                    if (!res.statusCode) {
                        history.push({
                            pathname: '/',
                            state: {
                                resetPassword: true,
                            },
                        });
                    } else {
                        res.message[0].messages[0].message ===
                        'Incorrect code provided.'
                            ? setErrorMessage(
                                  'This password reset link is no longer valid.'
                              )
                            : setErrorMessage(
                                  res.message[0].messages[0].message
                              );
                    }
                }
            );
        }
    };

    return (
        <PageWrapper>
            <Header className="header_xs">Reset Password</Header>
            <Form className="body_cabin_sm">
                <Label htmlFor="password">New Password</Label>
                <Input
                    id="password"
                    name="password"
                    type="password"
                    pattern=".{6,}"
                    title="Must be at least 6 characters long"
                    onChange={updateForm}
                    required
                />
                <Label htmlFor="confirm password">Confirm New Password</Label>
                <Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    onChange={updateForm}
                    errorMessage={errorMessage}
                    required
                />
                <ErrorMsg className="body_cabin_xs" errorMessage={errorMessage}>
                    {errorMessage}
                </ErrorMsg>
                <PrimaryButton
                    width={`18.7rem`}
                    content="Reset Password"
                    onClick={handleResetPassword}
                />
            </Form>
        </PageWrapper>
    );
};

const PageWrapper = styled(PageContainer)`
    padding-top: 6.4rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.colors.white};
`;

const Header = styled.h1`
    text-align: center;
    margin-bottom: 8rem;
`;

const Form = styled.form`
    width: 32vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1055px) {
        width: 50vw;
    }
    @media only screen and (max-width: 767px) {
        width: 80vw;
    }
`;

const Label = styled.label`
    width: 100%;
    margin-bottom: 0.8rem;
`;

const Input = styled.input`
    width: 100%;
    height: 4rem;
    border: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};
    border-radius: 0.5rem;
    padding: 0.8rem 1.6rem;
    margin-bottom: 3.2rem;

    :last-of-type {
        margin-bottom: ${({ errorMessage }) => (errorMessage ? `0` : `6.4rem`)};
    }
`;

const ErrorMsg = styled.div`
    width: 100%;
    color: ${(props) => props.theme.colors.extra};
    display: ${({ errorMessage }) => (errorMessage ? `inline-block` : `none`)};
    align-items: center;
    margin-top: 0.8rem;
    margin-bottom: 3.2rem;
`;

export default ResetPasswordPage;
