const resourcesSet = (boards) => {
    if (!boards) return;
    const set = new Set();
    Object.values(boards).map((value) =>
        value.forEach((resource) => set.add(Number(resource.id)))
    );

    return set;
};

export default resourcesSet;
