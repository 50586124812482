import React, { useEffect, useRef } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { useGlobalContext } from '../../../globalStore/globalContext';
import ResourceCard from '../../ResourceCard';
import ResourceList from '../../ResourceList';
import noResultImage from '../../../assets/images/no-results.svg';
import styled from '@emotion/styled';
import Section from '../../../assets/styledComponents/Section';

/** Infinite Scroll with React InstantSearch (https://www.algolia.com/doc/guides/building-search-ui/ui-and-ux-patterns/infinite-scroll/react/#retrieve-more-results) */

const InfiniteScrollSearchResult = ({
    hits,
    cardView,
    handleClick,
    hasMore,
    refineNext,
    setCurrentResource,
    setOpenAddToBoardModal,
    setCheckBoxState,
    boards,
    resourceSet,
    searchState,
}) => {
    const { globalState } = useGlobalContext();
    const { isMobile } = globalState;
    const { query } = searchState;
    // Track the scroll position
    const sentinel = useRef(null);
    // Create the Intersection Observer instance to observe when the element enters the page
    const onSentinelIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && hasMore) {
                // Retrieve more results
                refineNext();
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(onSentinelIntersection, {});
        observer.observe(sentinel.current);
        // Clear the observer
        return () => observer.disconnect();

        // eslint-disable-next-line
    }, [IntersectionObserver, onSentinelIntersection]);

    const Result = () => {
        if (cardView || isMobile) {
            return (
                <div className="ais-InfiniteHits cards-view">
                    <ul className="ais-InfiniteHits-list cards-view">
                        {hits.map((hit) => (
                            <ResourceCard
                                key={hit.id}
                                className="ais-InfiniteHits-item cards-view"
                                hit={hit}
                                handleClick={handleClick}
                                setCurrentResource={setCurrentResource}
                                setOpenAddToBoardModal={setOpenAddToBoardModal}
                                setCheckBoxState={setCheckBoxState}
                                boards={boards}
                                resourceSet={resourceSet}
                                searchState={searchState}
                            />
                        ))}
                        <div ref={sentinel} />
                    </ul>
                </div>
            );
        }
        return (
            <div className="ais-InfiniteHits list-view">
                <ul className="ais-InfiniteHits-list list-view">
                    {hits.map((hit) => (
                        <ResourceList
                            key={hit.id}
                            hit={hit}
                            handleClick={handleClick}
                            className="ais-InfiniteHits-item list-view"
                            setCurrentResource={setCurrentResource}
                            setOpenAddToBoardModal={setOpenAddToBoardModal}
                            setCheckBoxState={setCheckBoxState}
                            boards={boards}
                            resourceSet={resourceSet}
                            searchState={searchState}
                        />
                    ))}
                    <div ref={sentinel} />
                </ul>
            </div>
        );
    };
    return (
        <Container>
            {!hits?.length ? (
                <NoResultWrapper ref={sentinel}>
                    <img src={noResultImage} alt="no results" />
                    <NoResultLine1 className="header_xs">
                        No results found.
                    </NoResultLine1>
                    <NoResultLine2 className="header_extra_medium">
                        We couldn’t find any matching results for “
                        <span className="header_extra_medium">{query}</span>”.
                    </NoResultLine2>
                </NoResultWrapper>
            ) : (
                <Result />
            )}
        </Container>
    );
};

export default connectInfiniteHits(InfiniteScrollSearchResult);

const Container = styled(Section)`
    margin: 0;
    border-radius: 10rem 10rem 0 0;
    padding: 11.2rem 12rem;
    background-color: ${(props) => props.theme.colors.light_grey};

    @media only screen and (max-width: 105.4rem) {
        padding: 8.8rem 6.4rem;
    }
    @media only screen and (max-width: 76.7rem) {
        padding: 4rem 2.4rem;
        border-radius: 3rem 3rem 0 0;
    }
`;

const NoResultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.lighterGrey};
    padding-top: 10.4rem;

    img {
        margin-bottom: 2.6rem;
    }
`;

const NoResultLine1 = styled.p`
    font-family: MontserratSemibold600;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 0.8rem;
`;

const NoResultLine2 = styled.p`
    color: ${(props) => props.theme.colors.dark_grey};
    margin-bottom: 18.4rem;
`;
