import styled from '@emotion/styled';
import React from 'react';

const SecondaryButton = ({
    onClick,
    content,
    large,
    width,
    marginLeft,
    marginRight,
}) => {
    return (
        <StyledSecondary
            onClick={onClick}
            large={large}
            width={width}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            {content}
        </StyledSecondary>
    );
};

const StyledSecondary = styled.button`
    width: ${({ width }) => width ?? `auto`};
    height: ${({ large }) => (large ? `4.8rem` : `4rem`)};
    border: ${(props) => `0.15rem solid ${props.theme.colors.primary}`};
    border-radius: 5rem;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.white};
    margin-left: ${({ marginLeft }) => marginLeft ?? `0`};
    margin-right: ${({ marginRight }) => marginRight ?? `0`};
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: ${({ large }) => (large ? `1.1rem` : `0.7rem`)};
    padding-bottom: ${({ large }) => (large ? `1.3rem` : `0.9rem`)};

    :hover {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.primary};
    }
`;

export default SecondaryButton;
