import Template from '../../assets/images/resource-blade/Template.svg';
import Handout from '../../assets/images/resource-blade/Handout.svg';
import Website from '../../assets/images/resource-blade/Website.svg';
import Form from '../../assets/images/resource-blade/Form.svg';
import Saved_Search from '../../assets/images/resource-blade/Saved_Search.svg';
import Learning_Event from '../../assets/images/resource-blade/Learning_Event.svg';
import Article_or_Guideline from '../../assets/images/resource-blade/Article_or_Guideline.svg';
import Video_or_Audio from '../../assets/images/resource-blade/Video_or_Audio.svg';
import Program_or_Service from '../../assets/images/resource-blade/Program_or_Service.svg';
import Book from '../../assets/images/resource-blade/Book.svg';
import Assessment_Info_Card from '../../assets/images/resource-blade/Assessment_Info_Card.svg';
import Other from '../../assets/images/resource-blade/Other.svg';

const resourceTypeIconPath = {
    ['Template']: {
        title: 'Template',
        path: Template,
    },
    ['Handout']: {
        title: 'Handout',
        path: Handout,
    },
    ['Website']: {
        title: 'Website',
        path: Website,
    },
    ['Form']: {
        title: 'Form',
        path: Form,
    },
    ['Saved_Search']: {
        title: 'Saved Search',
        path: Saved_Search,
    },
    ['Learning_Event']: {
        title: 'Learning Event',
        path: Learning_Event,
    },
    ['Article_or_Guideline']: {
        title: 'Article or Guideline',
        path: Article_or_Guideline,
    },
    ['Video_or_Audio']: {
        title: 'Video or Audio',
        path: Video_or_Audio,
    },
    ['Program_or_Service']: {
        title: 'Program or Service',
        path: Program_or_Service,
    },
    ['Book']: {
        title: 'Book',
        path: Book,
    },
    ['Assessment_Info_Card']: {
        title: 'Assessment Info Card',
        path: Assessment_Info_Card,
    },
    ['Other']: {
        title: 'Other',
        path: Other,
    },
};

export default resourceTypeIconPath;
