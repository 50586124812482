import React from 'react';
import styled from '@emotion/styled';
import xIcon from '../images/close-modal-icon.svg';

const CloseModalButton = ({ openModal, setOpenModal, closeModal }) => {
    const handleClick = (event) => {
        event.preventDefault();
        closeModal ? closeModal() : setOpenModal(!openModal);
    };

    return (
        <CloseButton onClick={handleClick}>
            <Icon src={xIcon} alt="close modal" />
        </CloseButton>
    );
};

const CloseButton = styled.button`
    border: none;
    background: none;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    padding: 2rem;
`;

const Icon = styled.img`
    width: 1.4rem;
    height: 1.4rem;
`;

export default CloseModalButton;
