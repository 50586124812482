import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import SvgIcon from '../../../assets/styledComponents/SvgIcon';
import SaveSearch from 'assets/images/saved-searches-icon.svg';
import RefinementListFilters from './RefinementListFilters';

const AdvancedFiltersModal = ({
    showDropdown,
    setShowDropdown,
    openNameSavedSearchModal,
    setOpenNameSavedSearchModal,
    advancedButton,
    searchState,
}) => {
    const token = localStorage.getItem('token');
    const modalRef = useRef();

    // Close modal when click outside of the modal
    useEffect(() => {
        const closeModalClickOutside = (event) => {
            if (
                showDropdown &&
                !modalRef.current.contains(event.target) &&
                !advancedButton.contains(event.target)
            ) {
                setShowDropdown(!showDropdown);
            }
        };
        document.addEventListener('mousedown', closeModalClickOutside);
        return () => {
            document.removeEventListener('mousedown', closeModalClickOutside);
        };
        // eslint-disable-next-line
    }, [showDropdown]);

    return (
        <Container showDropdown={showDropdown} ref={modalRef}>
            {token && (
                <SaveSearchButton
                    className="header_cabin_sm"
                    onClick={() =>
                        setOpenNameSavedSearchModal(!openNameSavedSearchModal)
                    }
                >
                    <SvgIcon
                        width={`1.8rem`}
                        height={`1.8rem`}
                        marginRight={`0.8rem`}
                        icon={SaveSearch}
                        alt="save search"
                    />
                    Save Search
                </SaveSearchButton>
            )}
            <RefinementListFilters
                searchState={searchState}
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
            />
        </Container>
    );
};

const Container = styled.div`
    position: absolute;
    margin-top: 1.6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.white};
    border: ${(props) => `0.1rem solid ${props.theme.colors.light_grey}`};
    border-radius: 1rem;
    box-shadow: 0 0.2rem 1.8rem 0 rgba(0, 0, 0, 0.1);
    z-index: ${(props) => props.theme.zIndex.advancedFiltersDropdown};
    /* use css to show and hide dropdown instead of mount and unmount component to keep searchState */
    display: ${(props) => (props.showDropdown ? 'block' : 'none')};
`;

const SaveSearchButton = styled.button`
    width: inherit;
    color: inherit;
    display: flex;
    align-items: center;
    padding: 2.4rem 3.2rem 1.6rem 3.2rem;
    background-color: transparent;
    border-bottom: ${(props) =>
        `0.1rem solid ${props.theme.colors.light_grey}`};
`;

export default AdvancedFiltersModal;
