import styled from '@emotion/styled';
import React from 'react';

const PageHeader = ({ title, color }) => {
    return (
        <Container>
            <Header color={color}>{title}</Header>
        </Container>
    );
};

const Container = styled.div`
    margin-top: 9.6rem;
    margin-bottom: 5rem;
    text-align: center;
`;

const Header = styled.h2`
    font-family: 'MontserratSemibold600';
    font-size: 3.2rem;
    line-height: 3.2rem;
    padding: 5rem 0 0;

    color: ${(props) => props.color};
`;

export default PageHeader;
