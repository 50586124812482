import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.getItem('token');

    if (!token) {
        return <Redirect to={{ pathname: '/' }} />;
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                return <Component {...rest} {...props} />;
            }}
        />
    );
};

export default ProtectedRoute;
