import React from 'react';
import styled from '@emotion/styled';
import NavLinks from '../NavLinks';

export default function MobileNavbar({
    isMobileNavOpen,
    token,
    loadUserData,
    isNavBarWhite,
}) {
    if (isMobileNavOpen) {
        return (
            <OpenMenuWrapper isNavBarWhite={isNavBarWhite}>
                <NavLinks
                    token={token}
                    loadUserData={loadUserData}
                    isNavBarWhite={isNavBarWhite}
                />
            </OpenMenuWrapper>
        );
    } else {
        return null;
    }
}

const OpenMenuWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 60vw;
    height: 100vh;
    background-color: ${(props) => props.theme.colors.primary};
`;
