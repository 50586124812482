import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import PageContainer from '../assets/styledComponents/PageContainer';
import ProtectedRoute from '../routes/ProtectedRoute';
import SideBar from '../components/DashBoardPage/SideBar';
import MyBoardsPage from './MyBoardsPage';
import SavedSearchesPage from './SavedSearchesPage';
import IndividualBoardPage from './IndividualBoardPage';

const DashBoardRouter = () => {
    return (
        <DashboardSideBarContainer>
            <SideBar />
            <Switch>
                <ProtectedRoute
                    exact
                    path="/myboard/boards"
                    component={MyBoardsPage}
                />
                <ProtectedRoute
                    exact
                    path="/myboard/boards/:encodedName"
                    component={IndividualBoardPage}
                />
                <ProtectedRoute
                    path="/myboard/saved-searches"
                    exact
                    component={SavedSearchesPage}
                />
                {/* Set default NavLink */}
                <Redirect to="/myboard/boards" />
            </Switch>
        </DashboardSideBarContainer>
    );
};

const DashboardSideBarContainer = styled(PageContainer)`
    display: flex;
    height: auto;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        height: auto;
    }
`;

export default DashBoardRouter;
