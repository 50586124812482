import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import speakerIcon from '../../../src/assets/images/speaker-icon.svg';

export default function BetaBadge() {
    const [scroll, setScroll] = useState(false);
    let location = useLocation();

    // add event listener for scrolling on landing page
    useEffect(() => {
        if (location.pathname === '/') {
            window.addEventListener('scroll', showBanner);
        }
        return () => {
            window.removeEventListener('scroll', showBanner);
        };
    }, [location]);

    // function to show banner (will show as soon as window is scrolled down)
    const showBanner = () => {
        if (window.scrollY > 0) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    };

    // For non-landing pages setScroll to true (beta badge will appear)
    useEffect(() => {
        if (location.pathname !== '/') {
            setScroll(true);
        } else {
            setScroll(false);
        }
    }, [location]);

    return (
        scroll && (
            <BannerContainerWrapper
                isPrivacyPage={location.pathname === '/privacypolicy'}
                isLandingPage={location.pathname === '/'}
                href="https://ubc.ca1.qualtrics.com/jfe/form/SV_0izYfpibBvzFrcq"
                target="_blank"
            >
                <BannerSize>
                    <BannerFlex>
                        <BannerIcon>
                            <IconSpan>
                                <Icon src={speakerIcon} alt="speakerIcon" />
                            </IconSpan>
                            <BannerMessage>
                                <BannerMessageText>
                                    Just Launched! Click to Share Feedback!
                                </BannerMessageText>
                            </BannerMessage>
                        </BannerIcon>
                    </BannerFlex>
                </BannerSize>
            </BannerContainerWrapper>
        )
    );
}

const BannerContainerWrapper = styled.a`
    display: block;
    text-decoration: none;
    background-color: rgb(79 70 229);
    position: fixed;
    z-index: ${(props) => props.theme.zIndex.navbar - 1};
    margin-top: ${(props) => (props.isPrivacyPage ? '-6.5rem' : '0rem')};
    margin-top: ${(props) => (props.isLandingPage ? '9.5rem' : '')};
    width: 430px;
    border-bottom-right-radius: 14px;
    visibility: ${({ scroll }) => (scroll ? `hidden` : `visible`)};

    @media screen and (max-width: 394px) {
        width: 100%;
        border-bottom-right-radius: 0px;
    }
`;

const BannerSize = styled.div`
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
`;

const BannerFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const BannerIcon = styled.div`
    width: 0px;
    flex: 1 1 0%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 440px) {
        display: block;
    }
`;

const IconSpan = styled.span`
    display: flex;
    padding: 8px;
    border-radius: 8px;
    background-color: rgb(55 48 163);
    @media screen and (max-width: 440px) {
        display: none;
    }
`;

const Icon = styled.img`
    height: 24px;
    width: 24px;
`;

const BannerMessage = styled.p`
    margin-left: 12px;
    font-weight: 500;
    color: rgb(255 255 255);
    overflow: visible;
    white-space: nowrap;
    @media screen and (max-width: 440px) {
        margin-left: 0;
        text-align: center;
    }
`;

const BannerMessageText = styled.span`
    font-size: 16px;
    @media screen and (max-width: 440px) {
        font-size: 13px;
    }
`;
