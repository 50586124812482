import React, { createContext, useReducer, useContext } from 'react';
import globalReducer from './globalReducer';

export const initialState = {
    userId: null,
    registeredUserId: null,
    userInfo: {
        firstName: null,
        lastName: null,
        email: null,
        avatarId: null,
        avatarUrl: null,
    },
    boards: {},
    savedSearches: {},
    isMobile: false,
};

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
    const [globalState, dispatch] = useReducer(globalReducer, initialState);

    const value = { globalState, dispatch };
    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    );
};

const useGlobalContext = () => {
    const context = useContext(GlobalContext);

    if (context === undefined)
        throw new Error(
            'useGlobalContext must be used within the GlobalProvider'
        );

    return context;
};

export { GlobalProvider, useGlobalContext };
