import React from 'react';
import styled from '@emotion/styled';
import ModalOverlay from '../../../assets/styledComponents/ModalOverlay';
import CloseModalButton from '../../../assets/styledComponents/CloseModalButton';
import CenteredModal from '../../../assets/styledComponents/CenteredModal';
import CancelButton from '../../../assets/styledComponents/Buttons/CancelButton';
import PrimaryButton from '../../../assets/styledComponents/Buttons/PrimaryButton';

const RenameSavedSearchModal = ({
    openRenameModal,
    setOpenRenameModal,
    submitSavedSearch,
    currentSavedSearchTitle,
    newSavedSearchTitle,
    setNewSavedSearchTitle,
    handleSavedSearchTitleChange,
    errorMessage,
    setErrorMessage,
}) => {
    const handleCancel = () => {
        setOpenRenameModal(!openRenameModal);
        setNewSavedSearchTitle(currentSavedSearchTitle);
        setErrorMessage(null);
    };

    return (
        <div>
            <ModalOverlay
                openModal={openRenameModal}
                setOpenModal={setOpenRenameModal}
            />
            <CenteredModal width={`37.7vw`}>
                <CloseModalButton
                    openModal={openRenameModal}
                    setOpenModal={setOpenRenameModal}
                />
                <Header className="header_xs">Rename Saved Search</Header>
                <StyledForm onSubmit={submitSavedSearch}>
                    <Input
                        type="text"
                        value={newSavedSearchTitle}
                        onChange={handleSavedSearchTitleChange}
                        lassName="body_cabin_sm"
                        onKeyPress={(event) =>
                            event.key === 'Enter' && submitSavedSearch(event)
                        }
                    />
                    <ErrorMessage
                        className="body_cabin_sm"
                        error={errorMessage}
                    >
                        {errorMessage}
                    </ErrorMessage>
                    <ButtonsContainer>
                        <CancelButton onClick={handleCancel} />
                        <PrimaryButton type="submit" content="Apply" />
                    </ButtonsContainer>
                </StyledForm>
            </CenteredModal>
        </div>
    );
};

const StyledForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Header = styled.h3`
    font-size: 2.2rem;
`;

const Input = styled.input`
    width: 100%;
    height: 4rem;
    margin-top: 1.5rem;
    border: ${(props) => `1px solid ${props.theme.colors.grey}`};
    padding: 0.8rem 1.6rem;
`;

const ErrorMessage = styled.p`
    display: ${({ error }) => (error ? `block` : `none`)};
    color: red;
    margin: 1rem 0;
    height: 2rem;
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;

    @media only screen and (max-width: 767px) {
        justify-content: center;
    }

    button {
        margin: 0;

        :first-of-type {
            margin-right: 0.8rem;
        }
    }
`;

export default RenameSavedSearchModal;
