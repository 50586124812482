import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: inherit;
`;

export default StyledLink;
