import styled from '@emotion/styled';

const Header_Xs = styled.h3`
    font-family: 'MontserratSemibold600';
    font-size: 2.2rem;
    line-height: 3.2rem;
    letter-spacing: 0;
    margin: 0;
    color: ${(props) => props.color};
`;

export default Header_Xs;
