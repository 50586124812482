import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    color: inherit;
    font-family: 'MontserratMedium500';
    font-size: 2rem;
`;

export default StyledNavLink;
