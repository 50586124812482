import React from 'react';
import styled from '@emotion/styled';
import BladeContentContainer from '../../assets/styledComponents/BladeContentContainer';
import { capitalizeEachWord } from '../../functions/formatString';

const KeywordsSection = ({ keywords }) => {
    if (keywords == null || !keywords.length) return null;

    const keywordsArr = keywords.split(',');

    const Keywords = () =>
        keywordsArr.map((keyword) => (
            <Keyword className="body_cabin_xs" key={keyword}>
                {capitalizeEachWord(keyword)}
            </Keyword>
        ));

    return (
        <KeywordsContainer>
            <Keywords />
        </KeywordsContainer>
    );
};

const KeywordsContainer = styled(BladeContentContainer)`
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2.4rem;
`;

const Keyword = styled.span`
    display: inline-block;
    padding: 0.4rem 1.6rem;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
    color: ${(props) => props.theme.colors.secondary};
    background-color: ${(props) => props.theme.colors.light_grey};
`;

export default KeywordsSection;
