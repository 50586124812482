import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import exploreIcon from 'assets/images/explore-icon.svg';
import WithIconButton from '../../../assets/styledComponents/Buttons/WithIconButton';

const EmptyIndividualBoardContent = () => {
    const history = useHistory();

    return (
        <ContentContainer>
            <UpperText className="header_xs">
                You have not added any resources to your board
            </UpperText>
            <LowerText className="header_extra_medium">
                Explore CDR and start adding resources into it!
            </LowerText>

            <WithIconButton
                buttonText="Explore CDR"
                icon={exploreIcon}
                onClick={() => history.push('/')}
            />
        </ContentContainer>
    );
};

const ContentContainer = styled.div`
    padding-top: 12rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 767px) {
        margin-top: 1.6rem;
        text-align: center;
    }
`;

const UpperText = styled.p`
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 0.8rem;
`;

const LowerText = styled.p`
    color: ${(props) => props.theme.colors.dark_grey};
    margin-bottom: 3.2rem;
`;

export default EmptyIndividualBoardContent;
