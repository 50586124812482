import React, { Fragment, useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import CloseModalButton from 'assets/styledComponents/CloseModalButton';
import ModalOverlay from 'assets/styledComponents/ModalOverlay';
import Header_Cabin_Sm from 'assets/styledComponents/Typography/Header_Cabin_Sm';
import { useHistory } from 'react-router-dom';
import Header_Xs from 'assets/styledComponents/Typography/Header_Xs';

const Modal = ({
    token,
    openModal,
    signInTab,
    onChange,
    loadUserData,
    setOpenModal,
}) => {
    const history = useHistory();
    const searchParam = new URLSearchParams(history.location.search);
    const isResourceBladeOpen = searchParam.get('resource');
    const [open, setOpen] = useState(false);
    const [signIn, setSignIn] = useState(false);
    const modalRef = useRef();

    /* functions for opening/closing the modal */
    useEffect(() => {
        setOpen(openModal);
        setSignIn(signInTab);
    }, [openModal, signInTab]);

    //called when modal is closed to set parent's openModal and signInTab states back

    const closeModal = () => {
        onChange(open, signIn);
        history.push({ search: history.location.search, state: null });
    };

    //detects clicks outside modal contents (if click is outside modal contents then close the modal, if click is inside modal then do nothing)
    useEffect(() => {
        const handleClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                closeModal();
            }
        };
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    });

    return (
        open && (
            <Fragment>
                <ModalOverlay
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
                <ModalContent ref={modalRef} signInTab={signInTab}>
                    {/* Show prompt when non logged in user add resource to board */}
                    {!token &&
                        (isResourceBladeOpen ||
                            history.location.state?.requireLogin) && (
                            <SignInPrompt>
                                Please create an account or login to add
                                resources to your board
                            </SignInPrompt>
                        )}
                    <Header>
                        <HeaderButton
                            onClick={() => setSignIn(true)}
                            clicked={signIn}
                        >
                            <Header_Cabin_Sm>Login</Header_Cabin_Sm>
                            <MiddleBorder />
                        </HeaderButton>

                        <HeaderButton
                            onClick={() => setSignIn(false)}
                            clicked={!signIn}
                        >
                            <Header_Cabin_Sm>Sign Up</Header_Cabin_Sm>
                        </HeaderButton>
                    </Header>
                    <CloseModalButton closeModal={closeModal} />
                    {signIn ? (
                        <SignInForm
                            signIn={signIn}
                            closeModal={closeModal}
                            loadUserData={loadUserData}
                        />
                    ) : (
                        <SignUpForm
                            signIn={signIn}
                            closeModal={closeModal}
                            loadUserData={loadUserData}
                        />
                    )}
                    <TermsLinkContainer>
                        Please click here to review our{' '}
                        <TermsLink
                            href="https://docs.google.com/document/d/19MNmrHAkPeLlNYkofN4ScR1e12leF-Fy7F8jaf0L6JQ"
                            target="_blank"
                            rel="noreferrer noopener"
                            onClick={() => setOpenModal(false)}
                        >
                            Terms of Use
                        </TermsLink>{' '}
                        prior to creating an account
                    </TermsLinkContainer>
                </ModalContent>
            </Fragment>
        )
    );
};

const ModalContent = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    padding: 4rem 5.6rem;
    width: 40vw;
    max-height: 52vw;
    border-radius: 1rem;
    overflow-y: auto;
    z-index: 1000;
    color: ${(props) => props.theme.colors.primary};
    /*  center modal with fixed position: */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    .error {
        text-align: center;
        color: ${(props) => props.theme.colors.red};
    }

    @media screen and (max-width: 76.7rem) {
        padding: 4rem;
        width: 80vw;
        max-height: 90vh;
    }
`;

const SignInPrompt = styled(Header_Xs)`
    text-align: center;
    margin-bottom: 4rem;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 2.4rem;
`;

const HeaderButton = styled.button`
    width: 40.28%;
    font-size: 1.8rem;
    color: ${(props) =>
        props.clicked
            ? props.theme.colors.secondary
            : props.theme.colors.dark_grey};
    border: none;
    border-bottom: ${(props) =>
        props.clicked
            ? `0.2rem solid ${props.theme.colors.secondary}`
            : `0.2rem solid ${props.theme.colors.grey}`};
    background: none;
    width: 50%;
    padding-bottom: 1.6rem;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-family: 'MontserratMedium500';
`;

const MiddleBorder = styled.div`
    height: 60%;
    width: 47.1%;
    border-right: ${(props) => `0.1rem solid ${props.theme.colors.grey}`};
    position: absolute;
    top: 0;
    right: 50%;
`;

const TermsLinkContainer = styled.div`
    width: 100%;
    margin: 2rem 0 0;
    text-align: center;
    font-size: 1.2rem;
`;

const TermsLink = styled.a`
    color: ${(props) => props.theme.colors.secondary};
    text-align: right;
    margin-left: 0 !important;
    font-size: 1.2rem;
    text-decoration: none;
    cursor: pointer;

    p {
        margin-bottom: 4.8rem;
    }
`;

export default Modal;
