import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import StyledNavLink from 'assets/styledComponents/StyledNavLink';
import DropdownArrow from 'assets/images/dropdown-arrow.svg';
import SvgIcon from 'assets/styledComponents/SvgIcon';
import ArrowDown from 'assets/images/arrow-down-icon.svg';

const MenuItem = ({
    path,
    icon,
    title,
    subMenu,
    showBoardSubMenu,
    showSubMenuItem,
}) => {
    const theme = useTheme();
    const isSubMenu = subMenu && subMenu.length > 0;
    const location = useLocation();
    const isSubmenuActive = location.state;

    return (
        <MenuItemContainer
            className={isSubmenuActive ? 'active_children' : ''}
            to={path}
            onClick={showBoardSubMenu}
            isActive={(match) => match?.isExact}
        >
            <IconAndTitle>
                <SvgIcon
                    icon={icon}
                    width={`2.4rem`}
                    height={`2.4rem`}
                    bgColor={theme.colors.darker_grey}
                    marginRight={`0.8rem`}
                    alt={`${title}`}
                />
                <h2>{title}</h2>
            </IconAndTitle>
            {isSubMenu && (
                <SvgIcon
                    icon={showSubMenuItem ? ArrowDown : DropdownArrow}
                    width={`2.4rem`}
                    height={`2.4rem`}
                    bgColor={theme.colors.darker_grey}
                    alt="dropdown menu"
                />
            )}
        </MenuItemContainer>
    );
};

const MenuItemContainer = styled(StyledNavLink)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 1.6rem;
    color: ${(props) => props.theme.colors.darker_grey};

    &:hover,
    &:first-of-type.active_children {
        color: ${(props) => props.theme.colors.secondary};
        svg {
            background-color: ${(props) => props.theme.colors.secondary};
        }
    }

    &.active {
        background: ${(props) => props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.white};
        svg {
            background: ${(props) => props.theme.colors.white};
        }
    }
`;

const IconAndTitle = styled.div`
    display: flex;
    align-items: center;
`;

export default MenuItem;
