import React from 'react';
import styled from '@emotion/styled';
import StyledNavLink from '../../../assets/styledComponents/StyledNavLink';

const BoardsSubMenuList = ({ showSubMenuItem, subMenu }) => {
    const SubMenuItems = () => {
        if (subMenu) {
            return subMenu.map((name) => {
                const encodeName = encodeURIComponent(name);
                return (
                    <SubMenu
                        className="body_montserrat_xs"
                        to={{
                            pathname: `/myboard/boards/${encodeName}`,
                            state: {
                                name: name,
                            } /** passing name as state through react router */,
                        }}
                        key={name}
                    >
                        <BoardTitle>{name}</BoardTitle>
                    </SubMenu>
                );
            });
        }
        return null;
    };

    return (
        showSubMenuItem && (
            <SubMenuList>
                <SubMenuItems />
            </SubMenuList>
        )
    );
};

const SubMenuList = styled.div`
    display: block;
    width: 100%;
    height: auto;
`;

const SubMenu = styled(StyledNavLink)`
    display: flex;
    width: 100%;
    height: 4rem;
    padding: 0.8rem 1.6rem 0.8rem 6.3rem;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.darker_grey};

    &:hover {
        color: ${(props) => props.theme.colors.secondary};
    }

    &.active {
        background: ${(props) => props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.white};
    }
`;

const BoardTitle = styled.h3`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export default BoardsSubMenuList;
