import styled from '@emotion/styled';
import React from 'react';
import BladeContentContainer from '../../../assets/styledComponents/BladeContentContainer';

const Text = ({ text }) => {
    if (text == null) return null;

    const content = text.text;

    return (
        <BladeContentContainer>
            <TextContent
                className="body_cabin_sm"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </BladeContentContainer>
    );
};

const TextContent = styled.div`
    color: ${(props) => props.theme.colors.black};

    p {
        all: unset;
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
`;

export default Text;
