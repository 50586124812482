import styled from '@emotion/styled';
import React from 'react';
import bcchFoundationLogo from '../assets/images/logos/bcch-foundation-logo.png';
import bcchLogo from '../assets/images/logos/bcch-logo.png';
import bcLogo from '../assets/images/logos/bc_logo.svg';
import phsaLogo from '../assets/images/logos/phsa_logo.png';
import digitalLabLogo from '../assets/images/logos/digital-lab-logo.svg';
// import gitHubLogo from '../assets/images/git-hub-icon.svg';
// import producthuntLogo from '../assets/images/producthunt-icon.svg';
// import twitterLogo from '../assets/images/twitter-icon.svg';
// import ubcPedLogo from '../assets/images/logos/dop-logo-white.png';
import FooterSectionHeader from '../assets/styledComponents/FooterSectionHeader';
// import PetitTitle2 from '../assets/styledComponents/Typography/PetitTitle2';
import theme from '../assets/styles/theme';

const Footer = () => {
    const token = localStorage.getItem('token');
    const openSignUp = () => {
        const SignUpButton = document.getElementById('signup-button');
        SignUpButton.click();
    };

    const contactData = [{ title: 'Email', data: 'CDRInfoSource@cw.bc.ca' }];

    // const SocialMediaIcons = [
    //     {
    //         href: '/',
    //         src: twitterLogo,
    //         alt: 'twitter logo',
    //     },
    //     {
    //         href: '/',
    //         src: producthuntLogo,
    //         alt: 'p logo',
    //     },
    //     {
    //         href: '/',
    //         src: gitHubLogo,
    //         alt: 'git hub logo',
    //     },
    // ];

    return (
        <FooterContainer>
            <Content>
                <ContentLeft>
                    <QuickLinkContainer>
                        <FooterSectionHeader title="Quick Link" />
                        <List>
                            <ListItem>
                                <NavLink href="/">Home</NavLink>
                            </ListItem>
                            <ListItem>
                                <NavLink
                                    href="https://docs.google.com/document/d/19MNmrHAkPeLlNYkofN4ScR1e12leF-Fy7F8jaf0L6JQ/edit"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="body_cabin_sm"
                                >
                                    Terms of Use
                                </NavLink>
                            </ListItem>
                            <ListItem>
                                <NavLink
                                    href="https://docs.google.com/document/d/1hCOliZz07X5pnl2vGGcG4DLAqQ5epkJGCKV2jTR3YaA/edit"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="body_cabin_sm"
                                >
                                    Help Guide
                                </NavLink>
                            </ListItem>
                            {!token && (
                                <ListItem onClick={openSignUp}>
                                    Sign Up
                                </ListItem>
                            )}
                        </List>
                    </QuickLinkContainer>
                    <ContactContainer>
                        <FooterSectionHeader title="Contact" />
                        <List>
                            {contactData.map((item) => (
                                <ListItem key={item.title}>
                                    <ContactLink
                                        color={theme.colors.white}
                                        href={`mailto:${item.data}`}
                                    >
                                        {item.data}
                                    </ContactLink>
                                </ListItem>
                            ))}
                        </List>
                        {/* <SocialMediaList>
                            {SocialMediaIcons.map((item) => (
                                <SocialMediaLink
                                    href={item.href}
                                    key={item.alt}
                                >
                                    <img src={item.src} alt={item.alt} />
                                </SocialMediaLink>
                            ))}
                        </SocialMediaList> */}
                    </ContactContainer>
                    <DisclaimerText color={theme.colors.white}>
                        Disclaimer: We acknowledge that inclusive language is an
                        evolving practice. Some of the resources and services
                        referenced in this website may use language that is
                        inconsistent with current inclusive language practices
                        and preferences.
                    </DisclaimerText>
                </ContentLeft>

                <LogoSection>
                    {/* <StyledPetitTitle2 color={theme.colors.white}>
                        Created in partnership with the BCCH digital lab
                    </StyledPetitTitle2> */}
                    <LogosContainer>
                        <a
                            href="https://www.bcchdigital.ca/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="logo1"
                                src={digitalLabLogo}
                                alt={'digitalLab logo'}
                            />
                        </a>
                        <a
                            href="https://www2.gov.bc.ca/gov/content/governments/organizational-structure/ministries-organizations/ministries/children-and-family-development"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="logo2"
                                src={bcLogo}
                                alt={'BC logo'}
                            />
                        </a>
                        <a
                            href="http://www.bcchildrens.ca/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="logo3"
                                src={bcchLogo}
                                alt={'BC Childrens Hospital logo'}
                            />
                        </a>
                        <a
                            href="https://www.bcchf.ca/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="logo4"
                                src={bcchFoundationLogo}
                                alt={'BC Childrens Hospital Foundation logo'}
                            />
                        </a>
                        <a
                            href="http://www.phsa.ca/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="logo5"
                                src={phsaLogo}
                                alt={'PHSA logo'}
                            />
                        </a>
                    </LogosContainer>
                </LogoSection>
            </Content>
        </FooterContainer>
    );
};

const FooterContainer = styled.div`
    height: auto;
    background-color: ${(props) => props.theme.colors.primary};
    padding-top: 10.4rem;
    padding-bottom: 10.4rem;

    @media screen and (max-width: 767px) {
        height: auto;
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
    }
`;

const Content = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    row-gap: 2rem;
    width: 70%;
    margin: 0 auto;

    @media screen and (max-width: 767px) {
        padding-top: 0rem;
        padding-bottom: 4.1rem;
        row-gap: 4rem;
    }
`;
const ContentLeft = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    row-gap: 2rem;

    @media only screen and (max-width: 1055px) {
        margin-right: 0;
    }
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
const List = styled.ul`
    list-style: none;
    padding-left: 0;
    color: ${(props) => props.theme.colors.white};

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        margin-bottom: 0;
    }
`;

const QuickLinkContainer = styled.div`
    @media screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 1.9rem;
    }
`;

const ContactContainer = styled.div`
    width: 17.5rem;
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1.9rem
        padding-bottom: 4rem;
    }
`;

const NavLink = styled.a`
    text-decoration: none;
    margin-top: 1.2rem;
    color: ${(props) => props.theme.colors.white};

    @media screen and (max-width: 767px) {
        margin-bottom: 1.2rem;
    }
`;

const ListItem = styled.li`
    margin-top: 1.5rem;
    @media screen and (max-width: 767px) {
        margin-top: 0;
        padding-bottom: 1.2rem;
    }
`;

// const SocialMediaList = styled.div`
//     display: flex;
//     margin-top: 3.3rem;

//     @media screen and (max-width: 767px) {
//         margin-top: 1.2rem;
//         flex-direction: row;
//         padding-left: 1rem;
//     }
// `;

// const SocialMediaLink = styled.a`
//     margin-right: 1rem;
// `;

const ContactLink = styled.a`
    font-family: 'CabinRegular400';
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    margin: 0;
    color: ${(props) => props.color};
    text-decoration: none;
`;

const LogoSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
        align-self: center;
        width: 70%;
    }
`;

const LogosContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2rem;

    a {
        > .logo1 {
            margin-right: 5rem;
            margin-bottom: 2rem;
            height: 50px;
        }
        > .logo2 {
            margin-right: 5rem;
            margin-bottom: 2rem;
            height: 50px;
        }
        > .logo3 {
            margin-right: 5rem;
            margin-bottom: 2rem;
            height: 75px;
        }
        > .logo4 {
            margin-right: 5rem;
            margin-bottom: 2rem;
            height: 75px;
        }
        > .logo5 {
            margin-right: 5rem;
            margin-bottom: 2rem;
            height: 75px;
        }
    }

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 220px;
        align-self: center;
    }
`;

const DisclaimerText = styled.p`
    font-family: 'MontserratMedium500';
    color: ${(props) => props.color};
    font-size: 1.1rem;
    line-height: 1.5rem;
    letter-spacing: ${(props) => props.theme.letterSpacing.sm};
    margin: 0 0 1rem 0;
    width: 30vw;
    max-width: 500px;
    @media screen and (max-width: 767px) {
        width: auto;
        text-align: center;
    }
`;

// const StyledPetitTitle2 = styled(PetitTitle2)`
//     text-transform: uppercase;
//     @media screen and (max-width: 767px) {
//         text-align: center;
//     }
// `;

export default Footer;
